import { useState } from 'react';
import { useValueInput } from '@/shared/hooks';
import { WebLinkType } from '../../model/types';

interface AddWebLinkFields {
  urlInputProps: ReturnType<typeof useValueInput>;
  productIdProps: {
    value: string;
    onChange: (v: string) => void;
  };
  webLinkTypeProps: {
    value: WebLinkType;
    onChange: (v: WebLinkType) => void;
  };
}

export const useAddWebLinkFields = (): AddWebLinkFields => {
  const urlInputProps = useValueInput({
    hasError: urlHasErrorValidator,
  });
  const [productId, setProductId] = useState('');
  const [webLinkType, setWebLinkType] = useState<WebLinkType>(
    WebLinkType.webApplication,
  );

  return {
    urlInputProps,
    productIdProps: {
      value: productId,
      onChange: setProductId,
    },
    webLinkTypeProps: {
      value: webLinkType,
      onChange: setWebLinkType,
    },
  };
};

export const urlHasErrorValidator = (v: string) => {
  const error = getError(v);

  return !!error;
};

export enum URLValidatonErrorCode {
  includesProtocol = 'includes-protocol',
  invalidURL = 'invalid-url',
}

interface URLValidatonError {
  code: URLValidatonErrorCode;
}

export const getError = (value: string): URLValidatonError | undefined => {
  const protocolRegex = /^(?:https?|ftp|file|ws|wss):\/\//i;

  if (protocolRegex.test(value)) {
    return {
      code: URLValidatonErrorCode.includesProtocol,
    };
  }

  try {
    new URL(`http://${value}`);
  } catch (error) {
    return {
      code: URLValidatonErrorCode.invalidURL,
    };
  }
};
