import { styled } from '@mui/material/styles';
import type { ComponentProps, ReactNode } from 'react';

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  height: '100vh',
}));
const MainWrapper = styled('main')(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));
const Content = styled('div')(() => ({
  flexGrow: 1,
  display: 'flex',
}));

interface Props {
  navigationSlot?: ReactNode;
  headerSlot?: ReactNode;
  children?: ReactNode;
  slotProps?: {
    content?: ComponentProps<typeof Content>;
  };
}

export function LayoutV2({
  navigationSlot,
  headerSlot,
  children,
  slotProps,
}: Props) {
  return (
    <Wrapper
      sx={{
        padding: 1,
        background:
          'linear-gradient(111.34deg, #D4DBEB 0%, #DAE3FE 49.9%, #D4DBEB 100%)',
      }}
    >
      {navigationSlot}
      <MainWrapper>
        {headerSlot}
        <Content {...(slotProps?.content || {})}>{children}</Content>
      </MainWrapper>
    </Wrapper>
  );
}
