import {
  Box,
  FormControlLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  ToggleButton as MUIToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ChangeEvent, MouseEvent, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import type { DateRange } from '@/shared/types';
import { getRange } from './getRange';
import type { RangeTypeKeys, State, TimeUnitKeys } from './types';
import { RangeType, TimeUnit } from './types';

const ToggleButton = styled(MUIToggleButton)(({ theme }) => ({
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  textTransform: 'none',

  '&.Mui-selected': {
    backgroundColor: 'transparent',
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
}));

interface Props {
  onChange: (v: DateRange) => void;
}

const UNITS: { label: string; value: TimeUnitKeys }[] = [
  {
    label: 'Years',
    value: TimeUnit.year,
  },
  {
    label: 'Quaters',
    value: TimeUnit.quater,
  },
  {
    label: 'Months',
    value: TimeUnit.month,
  },
  {
    label: 'Weeks',
    value: TimeUnit.week,
  },
  {
    label: 'Days',
    value: TimeUnit.day,
  },
];
const RANGES: { label: string; value: RangeTypeKeys }[] = [
  {
    label: 'This',
    value: RangeType.current,
  },
  {
    label: 'Previous',
    value: RangeType.previous,
  },
  {
    label: 'Last',
    value: RangeType.lastN,
  },
];

export function RelativePicker({ onChange }: Props) {
  const [state, setState] = useState<State>({
    unit: TimeUnit.week,
    range: RangeType.current,
    lastNCount: 3,
  });

  useEffect(
    () => {
      onChange(getRange(state));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleUnitChange = (_event: MouseEvent, newUnit: TimeUnitKeys) => {
    if (!newUnit) {
      return;
    }

    handleChange({
      unit: newUnit,
    });
  };
  const handleRangeChange = (event: any) => {
    handleChange({
      range: event.target.value,
    });
  };
  const handleLastNChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleChange({
      lastNCount: +event.target.value || 1,
    });
  };
  const handleChange = (update: Partial<State>) => {
    const newState = {
      ...state,
      ...update,
    };

    setState(newState);
    onChange(getRange(newState));
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <ToggleButtonGroup
        value={state.unit}
        exclusive
        onChange={handleUnitChange}
      >
        {UNITS.map((unit) => {
          return (
            <ToggleButton key={unit.value} value={unit.value}>
              {unit.label}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
      <RadioGroup />
      <RadioGroup value={state.range} onChange={handleRangeChange}>
        {RANGES.map((range) => {
          let optionUI: ReactNode = state.unit;

          if (range.value === RangeType.lastN) {
            optionUI = (
              <>
                <OutlinedInput
                  sx={{ width: 80, mx: 0.5 }}
                  inputProps={{
                    min: 1,
                    type: 'number',
                  }}
                  size="small"
                  value={state.lastNCount}
                  onChange={handleLastNChange}
                />{' '}
                {state.unit}s
              </>
            );
          }
          return (
            <FormControlLabel
              key={range.value}
              sx={{
                fontSize: 14,
              }}
              disableTypography
              control={<Radio size="small" />}
              value={range.value}
              label={
                <>
                  {range.label} {optionUI}
                </>
              }
            />
          );
        })}
      </RadioGroup>
    </Box>
  );
}
