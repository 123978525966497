import {
  Box,
  Chip,
  LinearProgress,
  ListItemButton,
  Typography,
} from '@mui/material';
import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from '@/shared/lib';
import { getSavedViewURL } from '../../libs/getSavedViewURL';
import type { SavedViewRecord } from '../../model/types';

interface Props {
  view: SavedViewRecord;
  viewURL?: string;
  rightSlot?: ReactNode;
  onClick?: (view: SavedViewRecord) => void;
}

export function SavedViewItem({ view, viewURL, rightSlot, onClick }: Props) {
  return (
    <Box key={view.uuid} component="li">
      <ListItemButton
        sx={{
          py: 1,
          px: 1.5,
          display: 'flex',
          alignItems: 'flex-start',
          gap: 1,
          width: '100%',
          position: 'relative',

          '&:hover': {
            backgroundColor: '#E7ECFA',

            '& .MuiChip-root': {
              backgroundColor: 'white',
            },
          },
        }}
        onClick={() => {
          onClick?.(view);
        }}
      >
        <Box
          component={Link}
          to={getSavedViewURL({ baseURL: viewURL, viewId: view.uuid })}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            textDecoration: 'none',
            width: '100%',
            minWidth: 0,
          }}
        >
          <Typography
            sx={{
              color: '#444444',
              fontWeight: 400,
              fontSize: '0.875rem',
              lineHeight: '1.375rem',
              letterSpacing: 0,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: '100%',
            }}
            title={view.savedViewName}
          >
            {view.savedViewName}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '0.75rem',
              lineHeight: '1.375rem',
              letterSpacing: 0,
              color: '#817E7E',
            }}
          >
            Last modified:{' '}
            <span title={new Date(view.updatedAt).toLocaleString()}>
              {formatDate(view.updatedAt)}
            </span>
          </Typography>
        </Box>
        {view.savedView.isDefault && (
          <Chip
            label="Default"
            sx={{
              fontWeight: 500,
              fontSize: '0.75rem',
              lineHeight: '0.875rem',
              letterSpacing: 0,
              height: 22,
              backgroundColor: '#E7ECFA',

              '& .MuiChip-label': {
                px: 1,
              },
            }}
          />
        )}
        {rightSlot}
        {view.updating && (
          <LinearProgress
            color="primary"
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: '#DDDFE7',
              height: 2,
            }}
          />
        )}
      </ListItemButton>
    </Box>
  );
}
