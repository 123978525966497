import { AppBar as MUIAppBar, Box, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';
import { Panel } from '../Panel/Panel';

const IconWrapper = styled('div')(({ theme }) => ({
  height: 40,
  width: 40,
  marginRight: theme.spacing(1.25),
  borderRadius: theme.shape.borderRadius * 2,
  color: 'white',
  background: 'linear-gradient(135deg, #063FFE 0%, #9857FF 100%)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

interface Props {
  icon: ReactNode;
  leftContentSlot: ReactNode;
  rightContentSlot?: ReactNode;
}

export function AppBar({ icon, leftContentSlot, rightContentSlot }: Props) {
  return (
    <Panel component={MUIAppBar} sx={{ zIndex: 0 }} position="static">
      <Toolbar sx={{ px: 2 }} disableGutters>
        <IconWrapper>{icon}</IconWrapper>
        {leftContentSlot}
        <Box sx={{ flexGrow: 1 }} />
        {rightContentSlot}
      </Toolbar>
    </Panel>
  );
}
