import { Box } from '@mui/material';
import { useEffect, useMemo } from 'react';
import type { MetricColoring } from '@/features/DataGridColoring';
import {
  getMetricConfigMap,
  MetricColoringEditorNext,
} from '@/features/DataGridColoring';
import { getDefaultMetricColoring } from '@/features/DataGridColoring';
import { useTableHeaderPropsContext } from '../TableHeaderPropsContext/TableHeaderPropsContext';

interface Props {
  id: string;
}

export function AddTreshold({ id }: Props) {
  const { columnsConfig, minMax, metricsColoring, updateMetricsColoring } =
    useTableHeaderPropsContext();
  const metricConfigMap = useMemo(() => {
    return getMetricConfigMap(columnsConfig, metricsColoring);
  }, [metricsColoring, columnsConfig]);
  const metricColoring = useMemo(
    () => metricsColoring.find((coloring) => coloring.metric === id),
    [metricsColoring, id],
  );

  useEffect(() => {
    if (metricColoring) {
      return;
    }

    updateMetricsColoring([
      ...metricsColoring,
      getDefaultMetricColoring(id, minMax),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!metricColoring) {
    return null;
  }

  const handleChange = (metricColoring: MetricColoring) => {
    updateMetricsColoring(
      metricsColoring.map((coloring) => {
        if (coloring.metric === id) {
          return metricColoring;
        }

        return coloring;
      }),
    );
  };

  return (
    <Box sx={{ width: 304 }}>
      <MetricColoringEditorNext
        metricConfig={metricConfigMap[metricColoring.metric]}
        min={minMax?.[metricColoring.metric]?.min}
        max={minMax?.[metricColoring.metric]?.max}
        value={metricColoring}
        onChange={handleChange}
      />
    </Box>
  );
}
