import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { TrashIcon } from '@/shared/ui';

interface Props {
  id: string;
  name: string;
  description: string;
  onDelete: (id: string) => void;
}

export function ColumnItem({ id, name, description, onDelete }: Props) {
  const handleDelete = () => {
    onDelete(id);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          border: '1px solid #BBBEC8',
          borderRadius: 2,
          p: 1,
          color: '#7E828F',
        }}
      >
        <DragIndicatorIcon sx={{ color: 'currentColor' }} />
        <Typography
          sx={{ flex: 1, fontSize: '0.875rem', lineHeight: '1.375rem' }}
        >
          {name}
        </Typography>
        <Tooltip arrow title={description}>
          <InfoOutlined sx={{ width: 16, height: 16, color: '#817E7E' }} />
        </Tooltip>
      </Box>
      <IconButton size="small" onClick={handleDelete}>
        <TrashIcon />
      </IconButton>
    </Box>
  );
}
