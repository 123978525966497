import UndoIcon from '@mui/icons-material/ArrowBackRounded';
import RedoIcon from '@mui/icons-material/ArrowForwardRounded';
import { Box, Divider, SvgIcon } from '@mui/material';
import type { ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Analytics } from '@/shared/lib';
import { Button as SharedButton } from '@/shared/ui';
import { Direction } from '../../model/types';
import { useTimeMachine } from '../../model/useTimeMachine';

const Button = styled((props: ButtonProps) => {
  return <SharedButton variant="text" color="secondary" {...props} />;
})(({ theme }) => ({
  width: 48,
  minWidth: 48,
  padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
  color: '#7E828F',
  borderRadius: 0,
}));

interface Props {
  snapshot: unknown;
  onReset: () => void;
  onTravel: (snapshot: unknown) => void;
}

export function TimeMachineNext({ snapshot, onReset, onTravel }: Props) {
  const { hasNext, hasPrevious, travel } = useTimeMachine({
    snapshot,
    onTravel,
  });

  const handleUndo = () => {
    travel(Direction.previous);
    Analytics.sendFeatureUsage('time_machine', 'undo');
  };
  const handleRedo = () => {
    travel(Direction.next);
    Analytics.sendFeatureUsage('time_machine', 'redo');
  };
  const handleReset = () => {
    onReset();
    Analytics.sendFeatureUsage('time_machine', 'reset');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #BBBEC8',
        borderRadius: '8px',
        overflow: 'hidden',
      }}
    >
      <Button
        title="Undo latest view change"
        disabled={!hasPrevious}
        onClick={handleUndo}
      >
        <UndoIcon />
      </Button>
      <Divider orientation="vertical" flexItem />
      <Button
        title="Redo latest view change"
        disabled={!hasNext}
        onClick={handleRedo}
      >
        <RedoIcon />
      </Button>
      <Divider orientation="vertical" flexItem />
      <Button title="Reset to vendor default view" onClick={handleReset}>
        <SvgIcon>
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 19V5M13 6L7 12M7 12L13 18M7 12H21"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </SvgIcon>
      </Button>
    </Box>
  );
}
