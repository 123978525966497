import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  color: '#343844',
  borderRadius: theme.shape.borderRadius * 4,
  boxShadow: '0px 2px 6px 0px rgba(50, 50, 71, 0.16)',
}));

export function Panel(props: BoxProps) {
  return <Wrapper {...props} />;
}
