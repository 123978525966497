import { useMemo } from 'react';
import type { Column } from '../../model/types';
import { ColumnsListNext } from '../ColumnsListNext/ColumnsListNext';

interface Props {
  dimensionsColumns: Column[];
  metricsColumns: Column[];
  selectedDimensions: string[];
  selectedMetrics: string[];
  onSelectedDimensionsChange: (dimensions: string[]) => void;
  onSelectedMetricsChange: (metrics: string[]) => void;
}

export function AllColumnsList({
  dimensionsColumns,
  metricsColumns,
  selectedDimensions,
  selectedMetrics,
  onSelectedDimensionsChange,
  onSelectedMetricsChange,
}: Props) {
  const dimensionsSet = useMemo(() => {
    return new Set(dimensionsColumns.map((column) => column.value));
  }, [dimensionsColumns]);
  const metricsSet = useMemo(() => {
    return new Set(metricsColumns.map((column) => column.value));
  }, [metricsColumns]);
  const allColumns = useMemo(() => {
    return [...dimensionsColumns, ...metricsColumns];
  }, [dimensionsColumns, metricsColumns]);
  const selectedColumns = useMemo(() => {
    return [...selectedDimensions, ...selectedMetrics];
  }, [selectedDimensions, selectedMetrics]);

  const handleSelectedColumnsChange = (columns: string[]) => {
    const newSelectedDimensions = columns.filter((column) =>
      dimensionsSet.has(column),
    );
    const newSelectedMetrics = columns.filter((column) =>
      metricsSet.has(column),
    );

    onSelectedDimensionsChange(newSelectedDimensions);
    onSelectedMetricsChange(newSelectedMetrics);
  };

  return (
    <ColumnsListNext
      columns={allColumns}
      selectedColumns={selectedColumns}
      onSelectedColumnsChange={handleSelectedColumnsChange}
    />
  );
}
