import { Alert } from '@mui/material';
import { useState } from 'react';

const LOCAL_STORAGE_KEY = 'cw-min-max-filling-alert-hidden';

export function MinMaxFillingAlert() {
  const [hidden, setHidden] = useState(
    localStorage.getItem(LOCAL_STORAGE_KEY) === 'true',
  );

  const handleClose = () => {
    localStorage.setItem(LOCAL_STORAGE_KEY, 'true');
    setHidden(true);
  };

  if (hidden) {
    return null;
  }

  return (
    <Alert
      variant="outlined"
      severity="info"
      sx={{
        color: '#816CEF',
        borderColor: '#816CEF',
        borderRadius: 2,

        '& .MuiSvgIcon-root': {
          color: '#816CEF',
        },
      }}
      onClose={handleClose}
    >
      Adding threshold will remove min/max filling from the cell.
    </Alert>
  );
}
