import type { Integration } from '@/entities/datasources';
import {
  APP_STORE_CONNECT_REPORTING_INTEGRATION_ID,
  APPLE_SEARCH_ADS_REPORTING_INTEGRATION_ID,
  APPLOVIN_ADS_REPORTING_INTEGRATION_ID,
  GOOGLE_ADS_REPORTING_INTEGRATION_ID,
  GOOGLE_PLAY_CONSOLE_REPORTING_INTEGRATION_ID,
  META_ADS_REPORTING_INTEGRATION_ID,
  TIK_TOK_FOR_BUSINESS_REPORTING_INTEGRATION_ID,
  DatasourceStatus,
  SNAPCHAT_ADS_REPORTING_INTEGRATION_ID,
  STRIPE_REPORTING_INTEGRATION_ID,
  PAY_PAL_REPORTING_INTEGRATION_ID,
  UNITY_ADS_REPORTING_INTEGRATION_ID,
  MOLOCO_ADS_REPORTING_INTEGRATION_ID,
  IRON_SOURCE_REPORTING_INTEGRATION_ID,
  X_ADS_REPORTING_INTEGRATION_ID,
  LIFTOFF_REPORTING_INTEGRATION_ID,
  APPSFLYER_REPORTING_INTEGRATION_ID,
  GOOGLE_ANALYTICS_REPORTING_INTEGRATION_ID,
  MINTEGRAL_REPORTING_INTEGRATION_ID,
  APPLOVIN_MAX_REPORTING_INTEGRATION_ID,
  TAPJOY_REPORTING_INTEGRATION_ID,
  PINTEREST_REPORTING_INTEGRATION_ID,
} from '@/entities/datasources';
import type {
  AppleSearchAdsReportingIntegration,
  AppLovinAdsReportingIntegration,
  GoogleAdsReportingIntegration,
  GooglePlayConsoleReportingIntegration,
  MetaAdsReportingIntegration,
  SnapchatAdsReportingIntegration,
  TikTokForBusinessReportingIntegration,
} from './types';

export const getIntegrationStatus = (
  integration: Integration,
): DatasourceStatus => {
  switch (integration.sourceIntegrationId) {
    case GOOGLE_ADS_REPORTING_INTEGRATION_ID:
      return getGoogleAdsReportingIntegrationStatus(
        integration as GoogleAdsReportingIntegration,
      );
    case META_ADS_REPORTING_INTEGRATION_ID:
      return getMetaAdsReportingIntegrationStatus(
        integration as MetaAdsReportingIntegration,
      );
    case TIK_TOK_FOR_BUSINESS_REPORTING_INTEGRATION_ID:
      return getTikTokForBusinessReportingIntegrationStatus(
        integration as TikTokForBusinessReportingIntegration,
      );
    case APPLE_SEARCH_ADS_REPORTING_INTEGRATION_ID:
      return getAppleSearchAdsReportingIntegrtaionStatus(
        integration as AppleSearchAdsReportingIntegration,
      );
    case SNAPCHAT_ADS_REPORTING_INTEGRATION_ID:
      return getSnapchatAdsReportingIntegrationStatus(
        integration as SnapchatAdsReportingIntegration,
      );
    case APPLOVIN_ADS_REPORTING_INTEGRATION_ID:
      return getAppLovinAdsReportingIntegrationStatus(
        integration as AppLovinAdsReportingIntegration,
      );
    case GOOGLE_PLAY_CONSOLE_REPORTING_INTEGRATION_ID:
      return getGooglePlayConsoleReportingIntegrationStatus(
        integration as GooglePlayConsoleReportingIntegration,
      );
    case APP_STORE_CONNECT_REPORTING_INTEGRATION_ID:
    case STRIPE_REPORTING_INTEGRATION_ID:
    case PAY_PAL_REPORTING_INTEGRATION_ID:
    case UNITY_ADS_REPORTING_INTEGRATION_ID:
    case MOLOCO_ADS_REPORTING_INTEGRATION_ID:
    case IRON_SOURCE_REPORTING_INTEGRATION_ID:
    case X_ADS_REPORTING_INTEGRATION_ID:
    case LIFTOFF_REPORTING_INTEGRATION_ID:
    case APPSFLYER_REPORTING_INTEGRATION_ID:
    case GOOGLE_ANALYTICS_REPORTING_INTEGRATION_ID:
    case MINTEGRAL_REPORTING_INTEGRATION_ID:
    case APPLOVIN_MAX_REPORTING_INTEGRATION_ID:
    case TAPJOY_REPORTING_INTEGRATION_ID:
    case PINTEREST_REPORTING_INTEGRATION_ID:
      return DatasourceStatus.active;
    default:
      throw new Error(
        `Unexpected source integration: "${integration.sourceIntegrationId}".`,
      );
  }
};

const getMetaAdsReportingIntegrationStatus = (
  integration: MetaAdsReportingIntegration,
): DatasourceStatus => {
  if (
    Object.entries(integration.integrationConfig.adAccountsActiveStatusRecord)
      .length === 0
  ) {
    return DatasourceStatus.inactive;
  }

  if (
    integration.integrationConfig.expirationDate &&
    integration.integrationConfig.expirationDate <= Date.now()
  ) {
    return DatasourceStatus.error;
  }

  return DatasourceStatus.active;
};

const getTikTokForBusinessReportingIntegrationStatus = (
  _integration: TikTokForBusinessReportingIntegration,
): DatasourceStatus => {
  return DatasourceStatus.active;
};

const getGoogleAdsReportingIntegrationStatus = (
  integration: GoogleAdsReportingIntegration,
): DatasourceStatus => {
  return Object.keys(
    integration.integrationConfig.accountListActiveStatusRecord,
  ).length > 0
    ? DatasourceStatus.active
    : DatasourceStatus.inactive;
};

const getAppleSearchAdsReportingIntegrtaionStatus = (
  _integration: AppleSearchAdsReportingIntegration,
): DatasourceStatus => {
  return DatasourceStatus.active;
};

const getSnapchatAdsReportingIntegrationStatus = (
  _integration: SnapchatAdsReportingIntegration,
): DatasourceStatus => {
  return DatasourceStatus.active;
};

const getAppLovinAdsReportingIntegrationStatus = (
  _integration: AppLovinAdsReportingIntegration,
): DatasourceStatus => {
  return DatasourceStatus.active;
};

const getGooglePlayConsoleReportingIntegrationStatus = (
  _integration: GooglePlayConsoleReportingIntegration,
): DatasourceStatus => {
  return DatasourceStatus.active;
};
