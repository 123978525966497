import { Box, Typography, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ChangeEvent } from 'react';
import { useEffect, useMemo, useRef } from 'react';
import { isFinished } from '@/shared/lib';
import { ToggleButtonGroup, SearchInput, Checkbox, Button } from '@/shared/ui';
import { getMediaFilterOptionValue } from '../../libs/getMediaFilterOptionValue';
import type { MediaFilterOption, MediaFilterParams } from '../../model/types';
import {
  FilterSelectionControlsProvider,
  useFilterSelectionControlsContext,
} from '../FilterSelectionControlsContext/FilterSelectionControlsContext';
import { useFiltersParamsContext } from '../FiltersParamsContext/FiltersParamsContext';
import type { Value } from '../MediaFilter/MediaFilter';
import { StringFilterSelectionType } from '../StringFilterSelectionTypeSwitcher/StringFilterSelectionTypeSwitcher';
import type { ItemRendererProps } from '../VirtualizedFilterNext/VirtualizedFilterNext';
import { VirtualizedFilterNext } from '../VirtualizedFilterNext/VirtualizedFilterNext';

const options = [
  {
    label: 'Include',
    value: StringFilterSelectionType.INCLUDE,
  },
  {
    label: 'Exclude',
    value: StringFilterSelectionType.EXCLUDE,
  },
];

interface Props {
  id: string;
  value?: Value;
  onChange: (v: Value) => void;
}

export function MediaFilterNext(props: Props) {
  return (
    <FilterSelectionControlsProvider
      id={props.id}
      value={props.value?.options || []}
      getOptionValue={getMediaFilterOptionValue}
    >
      <Content {...props} />
    </FilterSelectionControlsProvider>
  );
}

function Content({ id, value, onChange }: Props) {
  const {
    filterQuery,
    setFilterQuery,
    getSelectAllValue,
    getClearAllValue,
    isClearAllDisabled,
  } = useFilterSelectionControlsContext();
  const { paramsData, requestMediaFilterParams } = useFiltersParamsContext();
  const filterParams = paramsData[id]?.params as MediaFilterParams | undefined;
  const allOptions = useMemo(
    () =>
      filterParams?.options.map((option) => {
        return option.value;
      }) || [],
    [filterParams],
  );

  useEffect(
    () => {
      requestMediaFilterParams(id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => {
      if (isFinished(paramsData[id]?.status) && !value) {
        onChange({
          type: StringFilterSelectionType.INCLUDE,
          options: allOptions,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paramsData[id]],
  );

  const handleTypeChange = (type: StringFilterSelectionType) => {
    onChange({
      type,
      options: value?.options || allOptions,
    });
  };
  const handleOptionsChange = (options: string[]) => {
    onChange({
      type: value?.type || StringFilterSelectionType.INCLUDE,
      options,
    });
  };
  const handleClearAll = () => {
    if (!value) {
      return;
    }

    onChange({
      type: value.type,
      options: getClearAllValue(),
    });
  };
  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    if (!value) {
      return;
    }

    if (event.target.checked) {
      onChange({
        type: value.type,
        options: getSelectAllValue(),
      });
    } else {
      onChange({
        type: value.type,
        options: getClearAllValue(),
      });
    }
  };

  return (
    <div>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, p: 2 }}>
        <ToggleButtonGroup
          fullWidth
          options={options}
          value={value?.type}
          onChange={handleTypeChange}
        />
        <SearchInput query={filterQuery} onChange={setFilterQuery} />
      </Box>
      <Divider />
      <Box
        sx={{ py: 1, px: 2, display: 'flex', alignItems: 'center', gap: 1.5 }}
      >
        <Checkbox
          sx={{ p: 0 }}
          size="small"
          indeterminate={
            value?.options &&
            value?.options.length > 0 &&
            value?.options.length < allOptions.length
          }
          checked={allOptions.length === value?.options.length}
          onChange={handleSelectAll}
        />
        <Typography
          sx={{ flexGrow: 1, fontWeight: 500 }}
          variant="modalContent"
        >
          Selected:{' '}
          <Typography
            sx={{ fontWeight: 500, color: 'primary.main' }}
            component="span"
            variant="modalContent"
          >
            {value?.options.length || 0}
          </Typography>{' '}
          of {allOptions.length}
        </Typography>
        <Button
          sx={{ p: 0, lineHeight: '1.375rem', color: 'black' }}
          disabled={isClearAllDisabled(value?.options || [])}
          variant="text"
          color="secondary"
          onClick={handleClearAll}
        >
          Clear all
        </Button>
      </Box>
      <Divider />
      <VirtualizedFilterNext
        ItemRenderer={MediaOptionRenderer}
        value={value?.options}
        onChange={handleOptionsChange}
      />
    </div>
  );
}

const Image = styled('img')(() => ({
  height: 22,
  aspectRatio: '1 / 1',
  objectFit: 'contain',
}));

function MediaOptionRenderer({ value, setHeight }: ItemRendererProps) {
  const rootRef = useRef<HTMLDivElement>(null);
  const { url, value: optionValue } = value as MediaFilterOption;

  useEffect(
    () => {
      if (rootRef.current) {
        setHeight(rootRef.current.clientHeight);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rootRef],
  );

  return (
    <Box ref={rootRef} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      {url && <Image src={url} />}{' '}
      <Box component="span" sx={{ wordBreak: 'break-word' }}>
        {optionValue}
      </Box>
    </Box>
  );
}
