import { Box, Divider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMemo, useState } from 'react';
import { Button, Dropdown, SearchInput } from '@/shared/ui';
import { SavedViewItem } from '../SavedViewItem/SavedViewItem';
import { SavedViewMenu } from '../SavedViewMenu/SavedViewMenu';
import { useSavedViewsContext } from '../SavedViewsContext/SavedViewsContext';
import { useSavePresetContext } from '../SavePresetContext/SavePresetContext';

const List = styled('ul')(() => ({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  maxHeight: 300,
  overflowY: 'auto',
  width: 320,
}));

export function SavedViewsSelect() {
  const [query, setQuery] = useState('');
  const { appliedViewName, views, applyView } = useSavedViewsContext();
  const { openSavePresetDialog } = useSavePresetContext();
  const filteredViews = useMemo(() => {
    return views.filter((view) => {
      return view.savedViewName.toLowerCase().includes(query.toLowerCase());
    });
  }, [views, query]);
  const title = appliedViewName || 'Select View Preset';

  return (
    <Dropdown
      label={
        <Box
          component="span"
          sx={{
            maxWidth: 124,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
          title={appliedViewName ? appliedViewName : ''}
        >
          {title}
        </Box>
      }
      labelProps={{
        sx: {
          borderColor: '#BBBEC8',
          borderRadius: 2,
          fontWeight: 400,
          height: 40,
          width: 180,
          justifyContent: 'space-between',
        },
      }}
      popperProps={{
        placement: 'bottom-start',
        disablePortal: false,
      }}
    >
      <Box sx={{ p: 2 }}>
        <SearchInput query={query} onChange={setQuery} />
      </Box>
      <Divider />
      <List>
        {views.length === 0 && (
          <Box
            sx={{
              px: 2,
              py: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <svg
              width="33"
              height="32"
              viewBox="0 0 33 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M28.5 5.33334H19.1667M13.8333 5.33334H4.5M28.5 16H16.5M11.1667 16H4.5M28.5 26.6667H21.8333M16.5 26.6667H4.5M19.1667 2.66667V8.00001M11.1667 13.3333V18.6667M21.8333 24V29.3333"
                stroke="#7E828F"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 0.5,
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '0.875rem',
                  lineHeight: '1rem',
                  letterSpacing: 0,
                }}
              >
                No presets saved
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '0.75rem',
                  lineHeight: '0.875rem',
                  letterSpacing: 0,
                  textAlign: 'center',
                  color: '#7E828F',
                  width: 200,
                }}
              >
                Save filters and settings as a preset for quick access.
              </Typography>
            </Box>
            <Button
              sx={{ py: 0 }}
              variant="text"
              onClick={openSavePresetDialog}
            >
              Save View
            </Button>
          </Box>
        )}
        {views.length > 0 && filteredViews.length === 0 && (
          <Box sx={{ p: 2, color: '#817E7E', textAlign: 'center' }}>
            No views found
          </Box>
        )}
        {filteredViews.map((view) => {
          return (
            <SavedViewItem
              key={view.uuid}
              view={view}
              rightSlot={<SavedViewMenu viewId={view.uuid} />}
              onClick={applyView}
            />
          );
        })}
      </List>
    </Dropdown>
  );
}
