import { Box, Typography } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { isFinished, Analytics } from '@/shared/lib';
import { SortOptions } from '@/shared/types';
import { SortButton } from '@/shared/ui';
import type { StringFilterParams } from '../../model/types';
import { useFiltersParamsContext } from '../FiltersParamsContext/FiltersParamsContext';
import {
  StringFilterSelectionTypeSwitcher,
  StringFilterSelectionType,
} from '../StringFilterSelectionTypeSwitcher/StringFilterSelectionTypeSwitcher';
import type { ItemRendererProps } from '../VirtualizedFilter/VirtualizedFilter';
import { VirtualizedFilter } from '../VirtualizedFilter/VirtualizedFilter';

export interface Value {
  type: StringFilterSelectionType;
  options: string[];
}

interface Props {
  id: string;
  value?: Value;
  onChange: (v: Value) => void;
}

export function StringFilter({ id, value, onChange }: Props) {
  const { paramsData, requestStringFilterParams } = useFiltersParamsContext();
  const [sortOrder, setSortOrder] = useState<SortOptions | undefined>();
  const [type, setType] = useState(
    value?.type || StringFilterSelectionType.INCLUDE,
  );
  const optionsSortComparator = useMemo(() => {
    if (!sortOrder) {
      return undefined;
    }

    return sortOrder === SortOptions.ASC
      ? (a: string, b: string) => a.localeCompare(b)
      : (a: string, b: string) => b.localeCompare(a);
  }, [sortOrder]);
  const allOptions =
    (paramsData[id]?.params as StringFilterParams | undefined)?.options || [];

  useEffect(
    () => {
      requestStringFilterParams(id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => {
      if (isFinished(paramsData[id]?.status) && !value) {
        onChange({
          type: StringFilterSelectionType.INCLUDE,
          options: allOptions,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paramsData[id]],
  );

  const handleSortOrderChange = (newSortOrder?: SortOptions) => {
    setSortOrder(newSortOrder);
    Analytics.sendFeatureUsage('string_filter', 'sorting', {
      order: newSortOrder === undefined ? 'no_sorting' : newSortOrder,
    });
  };
  const handleTypeChange = (type: StringFilterSelectionType) => {
    setType(type);
    onChange({
      type,
      options: value?.options || allOptions,
    });
  };
  const handleOptionsChange = (options: string[]) => {
    onChange({
      type,
      options,
    });
  };

  return (
    <VirtualizedFilter
      filtersSlot={
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            justifyContent: 'space-between',
            mb: 2,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="modalContent">Type</Typography>
            <StringFilterSelectionTypeSwitcher
              value={type}
              onChange={handleTypeChange}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="modalContent">Sort</Typography>
            <SortButton value={sortOrder} onChange={handleSortOrderChange} />
          </Box>
        </Box>
      }
      ItemRenderer={StringOptionRenderer}
      value={value?.options}
      onChange={handleOptionsChange}
      optionsSortComparator={optionsSortComparator}
    />
  );
}

function StringOptionRenderer({ value, setHeight }: ItemRendererProps) {
  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(
    () => {
      if (rootRef.current) {
        setHeight(rootRef.current.clientHeight);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [rootRef],
  );

  return (
    <Box ref={rootRef} component="span" sx={{ wordBreak: 'break-word' }}>
      {value as string}
    </Box>
  );
}
