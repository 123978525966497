import { Box, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ForwardedRef, ReactNode } from 'react';
import { forwardRef, useEffect, useState } from 'react';
import { useDataGridApiRefContext, SubMenu } from '@/shared/ui';

const LabelWrapper = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 500,
  marginRight: theme.spacing(1),
}));

interface Props {
  field: string;
  title: string;
  description?: string;
  quickActions: (hovered: boolean) => ReactNode;
  menuOptions: Parameters<typeof SubMenu>[0]['options'];
  onMenuOptionClick: Parameters<typeof SubMenu>[0]['onOptionClick'];
}

export const HeaderCellNext = forwardRef(
  (
    {
      field,
      title,
      description,
      quickActions,
      menuOptions,
      onMenuOptionClick,
    }: Props,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const [hovered, setHovered] = useState(false);
    const { apiRef } = useDataGridApiRefContext();

    useEffect(() => {
      if (!apiRef) {
        return;
      }

      const columnHeaderElement = apiRef.getColumnHeaderElement(field);

      if (!columnHeaderElement) {
        return;
      }

      let skipMouseLeave = false;

      const handleMouseEnter = () => {
        setHovered(true);
      };
      const handleMouseLeave = () => {
        if (skipMouseLeave) {
          skipMouseLeave = false;
          return;
        }

        setHovered(false);
      };
      const handleClick = () => {
        skipMouseLeave = true;
      };

      columnHeaderElement.addEventListener('mouseenter', handleMouseEnter);
      columnHeaderElement.addEventListener('mouseleave', handleMouseLeave);
      columnHeaderElement.addEventListener('click', handleClick);

      return () => {
        columnHeaderElement.removeEventListener('mouseenter', handleMouseEnter);
        columnHeaderElement.removeEventListener('mouseleave', handleMouseLeave);
        columnHeaderElement.removeEventListener('click', handleClick);
      };
    }, [apiRef, field]);

    return (
      // need to use fragment to allow grid expand columns width by values
      <>
        <Tooltip
          arrow
          title={
            <>
              <p>{title}</p>
              {description && <p>{description}</p>}
            </>
          }
        >
          <LabelWrapper>{title}</LabelWrapper>
        </Tooltip>
        <Box ref={ref} sx={{ display: 'flex', alignItems: 'center' }}>
          {quickActions(hovered)}
          <SubMenu options={menuOptions} onOptionClick={onMenuOptionClick} />
        </Box>
      </>
    );
  },
);
