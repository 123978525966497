import { Box, Typography } from '@mui/material';
import { useFiltersContext } from '../FiltersContext/FiltersContext';
import { useFiltersParamsContext } from '../FiltersParamsContext/FiltersParamsContext';
import { ActiveFilter } from './ActiveFilter';

interface Props {
  onItemDelete: (id: string) => void;
}

export function FiltersList({ onItemDelete }: Props) {
  const { filters } = useFiltersContext();
  const { configsMap } = useFiltersParamsContext();

  return (
    <Box
      component="ul"
      sx={{
        m: 0,
        mt: 1,
        p: 0,
        listStyle: 'none',
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      {filters.length === 0 && (
        <Box>
          <Typography
            variant="modalContent"
            sx={{ lineHeight: '1.125rem', color: '#343844' }}
          >
            No filters applied
          </Typography>
          <Typography
            variant="label"
            sx={{ fontWeight: 400, color: '#7E828F' }}
          >
            The filters will appear here once applied.
          </Typography>
        </Box>
      )}
      {filters.map((filter) => {
        const config = configsMap[filter.id];

        return (
          <ActiveFilter
            key={filter.id}
            config={config}
            onDelete={onItemDelete}
          />
        );
      })}
    </Box>
  );
}
