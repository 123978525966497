import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import type { AccordionProps, AccordionSummaryProps } from '@mui/material';
import {
  Accordion as MUIAccordion,
  AccordionDetails as MUIAccordionDetails,
  AccordionSummary as MUIAccordionSummary,
  Typography,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useCallback, useMemo } from 'react';
import type { MetricDataFormatType } from '@/entities/columnsConfig';
import { toFormatType } from '@/entities/columnsConfig';
import type { DataType } from '@/shared/hooks';
import { Analytics, isError, isLoading } from '@/shared/lib';
import { isAllValuesSelected } from '../../model/isAllValuesSelected';
import {
  isMediaFilter,
  isNumberFilter,
  isStringFilter,
} from '../../model/predicates';
import type { FilterConfig, FilterValue } from '../../model/types';
import { DrawerMediaFilter } from '../DrawerMediaFilter/DrawerMediaFilter';
import { DrawerStringFilter } from '../DrawerStringFilter/DrawerStringFilter';
import { Filter } from '../Filter/Filter';
import { useFiltersContext } from '../FiltersContext/FiltersContext';
import { useFiltersParamsContext } from '../FiltersParamsContext/FiltersParamsContext';
import type { Value as MediaFilterValue } from '../MediaFilter/MediaFilter';
import { NumberFilter } from '../NumberFilter/NumberFilter';
import type { Value as NumberFilterValue } from '../NumberFilter/NumberFilter';
import type { Value as StringFilterValue } from '../StringFilter/StringFilter';

const Accordion = styled((props: AccordionProps) => {
  return (
    <MUIAccordion
      disableGutters
      elevation={0}
      square
      slotProps={{
        transition: {
          unmountOnExit: true,
        },
      }}
      {...props}
    />
  );
})(() => ({
  '&::before': {
    display: 'none',
  },
}));
const AccordionSummary = styled((props: AccordionSummaryProps) => {
  return (
    <MUIAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  );
})(({ theme }) => ({
  minHeight: 0,
  padding: 0,
  fontSize: '14px',
  fontWeight: 500,
  color: theme.palette.grey[700],
  borderBottom: `1px solid ${theme.palette.divider}`,

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
}));
const AccordionDetails = styled(MUIAccordionDetails)(({ theme }) => ({
  padding: `${theme.spacing(2)} 0 0`,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

interface Props {
  filterConfigs: FilterConfig[];
}

export function Filters({ filterConfigs }: Props) {
  const {
    paramsData,
    requestMediaFilterParams,
    requestNumberFilterParams,
    requestStringFilterParams,
  } = useFiltersParamsContext();
  const { filtersMap, updateFilters } = useFiltersContext();
  const groupedFilters = useMemo(() => {
    const groupsMap = new Map<
      string,
      { items: FilterConfig[]; order: number }
    >();

    filterConfigs.forEach((filter) => {
      const group = groupsMap.get(filter.group) || {
        order: Infinity,
        items: [],
      };

      group.items.push(filter);
      group.order = Math.min(filter.order, group.order);

      groupsMap.set(filter.group, group);
    });

    const result: { name: string; configs: FilterConfig[]; order: number }[] =
      [];

    groupsMap.forEach((value, key) => {
      result.push({
        name: key,
        configs: value.items,
        order: value.order,
      });
    });

    return result.sort((aGroup, bGroup) => {
      return aGroup.order - bGroup.order;
    });
  }, [filterConfigs]);
  const handleErrorRetry = useCallback(
    (id: string, handler: (id: string) => void) => () => {
      handler(id);
    },
    [],
  );

  if (groupedFilters.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography variant="subtitle2">Nothing found.</Typography>
      </Box>
    );
  }

  const handleChange = (id: string) => (newValue?: FilterValue) => {
    updateFilters(id, newValue);
    Analytics.sendFeatureUsage('drawer_filter', 'change_value', {
      filterName: id,
    });
  };

  return groupedFilters.map((group) => {
    return (
      <Accordion key={group.name} expanded>
        <AccordionSummary expandIcon={null}>{group.name}</AccordionSummary>
        <AccordionDetails>
          {group.configs.map((filter) => {
            const key = `${filter.group}-${filter.id}`;
            const active =
              !!filtersMap[filter.id] &&
              !isAllValuesSelected(
                filter.type,
                filtersMap[filter.id],
                paramsData[filter.id]?.params,
              );
            const loading =
              !paramsData[filter.id] || isLoading(paramsData[filter.id].status);
            const error =
              paramsData[filter.id] && isError(paramsData[filter.id].status);

            if (isNumberFilter(filter.type)) {
              const value = filtersMap[filter.id] as NumberFilterValue;

              return (
                <Filter
                  key={key}
                  loading={loading}
                  label={filter.name}
                  error={error}
                  active={active}
                  onErrorRetry={handleErrorRetry(
                    filter.id,
                    requestNumberFilterParams,
                  )}
                >
                  <NumberFilter
                    id={filter.id}
                    type={filter.type as DataType}
                    formatType={toFormatType(
                      filter.dataType as MetricDataFormatType,
                    )}
                    value={value}
                    onChange={handleChange(filter.id)}
                  />
                </Filter>
              );
            } else if (isStringFilter(filter.type)) {
              const value = filtersMap[filter.id] as StringFilterValue;
              const name = active
                ? `${filter.name} (${value.options.length})`
                : filter.name;

              return (
                <Filter
                  key={key}
                  active={active}
                  loading={loading}
                  error={error}
                  label={name}
                  onErrorRetry={handleErrorRetry(
                    filter.id,
                    requestStringFilterParams,
                  )}
                >
                  <DrawerStringFilter
                    id={filter.id}
                    value={value}
                    onChange={handleChange(filter.id)}
                  />
                </Filter>
              );
            } else if (isMediaFilter(filter.type)) {
              const value = filtersMap[filter.id] as MediaFilterValue;
              const name = active
                ? `${filter.name} (${value.options.length})`
                : filter.name;

              return (
                <Filter
                  key={key}
                  active={active}
                  loading={loading}
                  error={error}
                  label={name}
                  onErrorRetry={handleErrorRetry(
                    filter.id,
                    requestMediaFilterParams,
                  )}
                >
                  <DrawerMediaFilter
                    id={filter.id}
                    value={value}
                    onChange={handleChange(filter.id)}
                  />
                </Filter>
              );
            }

            return <p key={key}>Not implemented filter type: {filter.type}</p>;
          })}
        </AccordionDetails>
      </Accordion>
    );
  });
}
