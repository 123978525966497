import { Box } from '@mui/material';
import { useMemo } from 'react';
import type { MetricDataFormatType } from '@/entities/columnsConfig';
import { toFormatType } from '@/entities/columnsConfig';
import { selectCurrency } from '@/entities/session';
import type { DataType } from '@/shared/hooks';
import { useNumberFormatter } from '@/shared/hooks';
import { useAppSelector } from '@/shared/model';
import type { ColorLevel, MetricColoring } from '../../model/types';
import { LevelsDisplay } from '../LevelsDisplay/LevelsDisplay';
import { LevelsListNext } from '../LevelsListNext/LevelsListNext';
import { LevelsNumberSelectNext } from '../LevelsNumberSelectNext/LevelsNumberSelectNext';

interface Props {
  metricConfig: {
    name: string;
    type: DataType;
    dataType: MetricDataFormatType;
  };
  min?: number;
  max?: number;
  value: MetricColoring;
  onChange: (v: MetricColoring) => void;
}

export function MetricColoringEditorNext({
  metricConfig,
  min,
  max,
  value,
  onChange,
}: Props) {
  const userCurrency = useAppSelector(selectCurrency);
  const formattersMap = useNumberFormatter(userCurrency);
  const formatType = useMemo(
    () => toFormatType(metricConfig.dataType),
    [metricConfig.dataType],
  );

  const handleLevelsChange = (newLevels: ColorLevel[]) => {
    onChange({
      ...value,
      levels: newLevels,
    });
  };

  return (
    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 1.5 }}>
      <LevelsNumberSelectNext
        value={value.levels}
        onChange={handleLevelsChange}
      />
      <LevelsDisplay
        min={min}
        max={max}
        levels={value.levels}
        formatter={formattersMap[metricConfig.type][formatType]}
      />
      <LevelsListNext
        type={metricConfig.type}
        formatType={formatType}
        value={value.levels}
        onChange={handleLevelsChange}
      />
    </Box>
  );
}
