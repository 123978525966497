import { Box, Typography, Divider } from '@mui/material';
import type { ChangeEvent } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { isFinished, Analytics } from '@/shared/lib';
import { SortOptions } from '@/shared/types';
import {
  Checkbox,
  Button,
  SearchInput,
  SortButton,
  ToggleButtonGroup,
} from '@/shared/ui';
import { getStringFilterOptionValue } from '../../libs/getStringFilterOptionValue';
import type { StringFilterParams } from '../../model/types';
import {
  FilterSelectionControlsProvider,
  useFilterSelectionControlsContext,
} from '../FilterSelectionControlsContext/FilterSelectionControlsContext';
import { useFiltersParamsContext } from '../FiltersParamsContext/FiltersParamsContext';
import type { Value } from '../StringFilter/StringFilter';
import { StringFilterSelectionType } from '../StringFilterSelectionTypeSwitcher/StringFilterSelectionTypeSwitcher';
import type { ItemRendererProps } from '../VirtualizedFilter/VirtualizedFilter';
import { VirtualizedFilterNext } from '../VirtualizedFilterNext/VirtualizedFilterNext';

const options = [
  {
    label: 'Include',
    value: StringFilterSelectionType.INCLUDE,
  },
  {
    label: 'Exclude',
    value: StringFilterSelectionType.EXCLUDE,
  },
];

interface Props {
  id: string;
  value?: Value;
  onChange: (v: Value) => void;
}

export function StringFilterNext(props: Props) {
  return (
    <FilterSelectionControlsProvider
      id={props.id}
      value={props.value?.options || []}
      getOptionValue={getStringFilterOptionValue}
    >
      <Content {...props} />
    </FilterSelectionControlsProvider>
  );
}

function Content({ id, value, onChange }: Props) {
  const {
    filterQuery,
    setFilterQuery,
    getSelectAllValue,
    getClearAllValue,
    isClearAllDisabled,
  } = useFilterSelectionControlsContext();
  const { paramsData, requestStringFilterParams } = useFiltersParamsContext();
  const [sortOrder, setSortOrder] = useState<SortOptions | undefined>();
  const optionsSortComparator = useMemo(() => {
    if (!sortOrder) {
      return undefined;
    }

    return sortOrder === SortOptions.ASC
      ? (a: string, b: string) => a.localeCompare(b)
      : (a: string, b: string) => b.localeCompare(a);
  }, [sortOrder]);
  const allOptions =
    (paramsData[id]?.params as StringFilterParams | undefined)?.options || [];

  useEffect(
    () => {
      requestStringFilterParams(id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => {
      if (isFinished(paramsData[id]?.status) && !value) {
        onChange({
          type: StringFilterSelectionType.INCLUDE,
          options: allOptions,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paramsData[id]],
  );

  const handleTypeChange = (type: StringFilterSelectionType) => {
    onChange({
      type,
      options: value?.options || allOptions,
    });
    Analytics.sendFeatureUsage('string_filter', 'type_change', {
      type,
    });
  };
  const handleSortOrderChange = (newSortOrder?: SortOptions) => {
    setSortOrder(newSortOrder);
    Analytics.sendFeatureUsage('string_filter', 'sorting', {
      order: newSortOrder === undefined ? 'no_sorting' : newSortOrder,
    });
  };
  const handleOptionsChange = (options: string[]) => {
    onChange({
      type: value?.type || StringFilterSelectionType.INCLUDE,
      options,
    });
  };
  const handleClearAll = () => {
    if (!value) {
      return;
    }

    onChange({
      type: value.type,
      options: getClearAllValue(),
    });
  };
  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    if (!value) {
      return;
    }

    if (event.target.checked) {
      onChange({
        type: value.type,
        options: getSelectAllValue(),
      });
    } else {
      onChange({
        type: value.type,
        options: getClearAllValue(),
      });
    }
  };

  return (
    <div>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, p: 2 }}>
        <ToggleButtonGroup
          fullWidth
          options={options}
          value={value?.type}
          onChange={handleTypeChange}
        />
        <Box sx={{ display: 'flex', gap: 1.5 }}>
          <SearchInput query={filterQuery} onChange={setFilterQuery} />
          <SortButton
            sx={{
              width: 40,
              border: '1px solid #BBBEC8',
              backgroundColor: sortOrder ? '#E7ECFA' : 'transparent',
            }}
            value={sortOrder}
            onChange={handleSortOrderChange}
          />
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{ py: 1, px: 2, display: 'flex', alignItems: 'center', gap: 1.5 }}
      >
        <Checkbox
          sx={{ p: 0 }}
          size="small"
          indeterminate={
            value?.options &&
            value?.options.length > 0 &&
            value?.options.length < allOptions.length
          }
          checked={allOptions.length === value?.options.length}
          onChange={handleSelectAll}
        />
        <Typography
          sx={{ flexGrow: 1, fontWeight: 500 }}
          variant="modalContent"
        >
          Selected:{' '}
          <Typography
            sx={{ fontWeight: 500, color: 'primary.main' }}
            component="span"
            variant="modalContent"
          >
            {value?.options.length || 0}
          </Typography>{' '}
          of {allOptions.length}
        </Typography>
        <Button
          sx={{ p: 0, lineHeight: '1.375rem', color: 'black' }}
          disabled={isClearAllDisabled(value?.options || [])}
          variant="text"
          color="secondary"
          onClick={handleClearAll}
        >
          Clear all
        </Button>
      </Box>
      <Divider />
      <VirtualizedFilterNext
        ItemRenderer={StringOptionRenderer}
        value={value?.options}
        onChange={handleOptionsChange}
        optionsSortComparator={optionsSortComparator}
      />
    </div>
  );
}

function StringOptionRenderer({ value, setHeight }: ItemRendererProps) {
  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(
    () => {
      if (rootRef.current) {
        setHeight(rootRef.current.clientHeight);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [rootRef],
  );

  return (
    <Box ref={rootRef} component="span" sx={{ wordBreak: 'break-word' }}>
      {value as string}
    </Box>
  );
}
