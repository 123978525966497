import { TextField } from '@mui/material';
import { forwardRef, type ChangeEventHandler } from 'react';
import type { NumericFormatProps } from 'react-number-format';
import { NumericFormat } from 'react-number-format';
import type { InputComponentProps } from '../../model/types';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  step: number;
  min?: number;
  max?: number;
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, step, ...other } = props;

    return (
      <NumericFormat
        {...other}
        {...(step < 1
          ? { fixedDecimalScale: true, decimalScale: 2 }
          : { decimalScale: 0 })}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  },
);

export function NumberInput({
  textFieldProps,
  allowEmpty,
  placeholder,
  disabled,
  label,
  step,
  min,
  max,
  value,
  onChange,
  onBlur,
}: InputComponentProps) {
  const {
    sx,
    InputProps,
    inputProps,
    onChange: onChangeTextField,
    ...otherProps
  } = textFieldProps ?? {};

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (allowEmpty && event.target.value === '') {
      onChange?.(undefined);

      return;
    }

    const numberValue = +event.target.value;

    onChangeTextField?.(event);
    onChange?.(isNaN(numberValue) ? 0 : numberValue);
  };

  return (
    <TextField
      sx={{
        flexGrow: 1,
        ...sx,
      }}
      label={label}
      placeholder={placeholder}
      size="small"
      disabled={disabled}
      InputProps={{
        inputComponent: NumericFormatCustom as any,
        ...InputProps,
      }}
      inputProps={{
        step,
        min,
        max,
        ...inputProps,
      }}
      value={value}
      onBlur={onBlur}
      onChange={handleChange}
      {...otherProps}
    />
  );
}
