import LifetimeIcon from '@mui/icons-material/AllInclusiveRounded';
import type { SelectChangeEvent } from '@mui/material';
import { MenuItem, Select } from '@mui/material';
import { Lifetime } from '@/shared/types';

const ITEMS: Record<Lifetime, string> = {
  [Lifetime.THREE_MONTHS]: '3 months',
  [Lifetime.SIX_MONTHS]: '6 months',
  [Lifetime.YEAR]: '1 year',
};

interface Props {
  value: Lifetime;
  onChange: (v: Lifetime) => void;
}

export function LifetimeSelect({ value, onChange }: Props) {
  const handleChange = (event: SelectChangeEvent<Lifetime>) => {
    onChange(+event.target.value as Lifetime);
  };

  return (
    <Select
      sx={{ width: 145 }}
      startAdornment={<LifetimeIcon sx={{ color: 'text.secondary' }} />}
      value={value}
      onChange={handleChange}
    >
      {Object.entries(ITEMS).map(([value, label]) => {
        return (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        );
      })}
    </Select>
  );
}
