import {
  ToggleButtonGroup,
  ToggleButton as MUIToggleButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type { MouseEvent } from 'react';

const ToggleButton = styled(MUIToggleButton)(({ theme }) => ({
  textTransform: 'none',
  padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
}));

export enum StringFilterSelectionType {
  INCLUDE = 'include',
  EXCLUDE = 'exclude',
}

interface Props {
  value: StringFilterSelectionType;
  onChange: (value: StringFilterSelectionType) => void;
}

export function StringFilterSelectionTypeSwitcher({ value, onChange }: Props) {
  const handleChange = (
    _event: MouseEvent<HTMLElement>,
    newType?: StringFilterSelectionType,
  ) => {
    if (!newType) {
      return;
    }

    onChange(newType);
  };

  return (
    <ToggleButtonGroup
      exclusive
      size="small"
      value={value}
      onChange={handleChange}
    >
      <ToggleButton value={StringFilterSelectionType.INCLUDE}>
        Include
      </ToggleButton>
      <ToggleButton value={StringFilterSelectionType.EXCLUDE}>
        Exclude
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
