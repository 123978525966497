import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { prepareUIFilters } from '../../model/prepareUIFilters';
import type { FilterValue, Filters } from '../../model/types';
import { useFiltersParamsContext } from '../FiltersParamsContext/FiltersParamsContext';

interface ContextState {
  filters: Filters;
  filtersMap: Record<string, Filters[number]['value']>;
  updateFilters: (id: string, value?: FilterValue) => void;
  submitFilters: () => void;
  resetToInitialFilters: () => void;
}

const Context = createContext<ContextState | null>(null);

interface Props {
  activeFilters: Filters;
  children: ReactNode;
  onChange: (v: Filters) => void;
}

export const FiltersProvider = ({
  activeFilters,
  children,
  onChange,
}: Props) => {
  const { configsMap, paramsData, resetParamsData } = useFiltersParamsContext();
  const [filters, setFilters] = useState(activeFilters);
  const filtersMap = useMemo(() => {
    return Object.fromEntries(
      filters.map((filter) => {
        return [filter.id, filter.value];
      }),
    );
  }, [filters]);

  useEffect(() => {
    setFilters(activeFilters);
  }, [activeFilters]);

  return (
    <Context.Provider
      value={{
        filters,
        filtersMap,
        updateFilters: (id, value) => {
          setFilters((oldFilters) => {
            return [
              ...(value === undefined ? [] : [{ id, value }]),
              ...oldFilters.filter((f) => {
                return f.id !== id;
              }),
            ];
          });
        },
        submitFilters: () => {
          const newFilters = prepareUIFilters({
            filters,
            paramsData,
            configsMap,
          });

          resetParamsData(newFilters);
          onChange(newFilters);
        },
        resetToInitialFilters: () => {
          setFilters(activeFilters);
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useFiltersContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error('useFiltersContext hook used outside <FiltersProvider />');
  }

  return context;
};
