import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, Menu, MenuItem as MUIMenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { MouseEvent } from 'react';
import { useMemo, useState } from 'react';
import { Analytics } from '@/shared/lib';
import { useSavedViewsContext } from '../SavedViewsContext/SavedViewsContext';

const MenuItem = styled(MUIMenuItem)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}));

interface Props {
  viewId: string;
}

export function SavedViewMenu({ viewId }: Props) {
  const { updateView, deleteView, setAsDefault, unsetAsDefault, views } =
    useSavedViewsContext();
  const isDefault = useMemo(() => {
    return views.find((view) => {
      return view.uuid === viewId;
    })?.savedView.isDefault;
  }, [views, viewId]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const handleUpdateView = () => {
    updateView(viewId);
    Analytics.sendFeatureUsage('saved_views', 'update_view');
  };
  const handleDeleteView = () => {
    deleteView(viewId);
    Analytics.sendFeatureUsage('saved_views', 'delete_view');
  };
  const handleSetAsDefault = () => {
    setAsDefault(viewId);
    Analytics.sendFeatureUsage('saved_views', 'set_as_default');
  };
  const handleUnsetAsDefault = () => {
    unsetAsDefault(viewId);
    Analytics.sendFeatureUsage('saved_views', 'unset_as_default');
  };

  return (
    <Box>
      <IconButton size="small" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        MenuListProps={{
          sx: {
            p: 0,
          },
        }}
        anchorEl={anchorEl}
        open={open}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem onClick={handleUpdateView}>Update Preset</MenuItem>
        {isDefault ? (
          <MenuItem onClick={handleUnsetAsDefault}>Unset as default</MenuItem>
        ) : (
          <MenuItem onClick={handleSetAsDefault}>Set as default</MenuItem>
        )}
        <MenuItem onClick={handleDeleteView}>Delete</MenuItem>
      </Menu>
    </Box>
  );
}
