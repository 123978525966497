import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/ErrorRounded';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  IconButton as MUIIconButton,
  SvgIcon,
  Typography,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Analytics } from '@/shared/lib';
import { Loader } from '@/shared/ui';
import { useCreateSharedViewMutation } from '../../api/shareAPI';
import type { SharedView } from '../../model/types';
import { CopyButton } from '../CopyButton/CopyButton';

const IconButton = styled(MUIIconButton)(({ theme }) => ({
  color: '#7E828F',
  border: '1px solid #BBBEC8',
  borderRadius: theme.shape.borderRadius * 2,
  padding: `${theme.spacing(0.875)} ${theme.spacing(1.375)}`,

  '&:hover': {
    color: '#666666',
    backgroundColor: '#6666660a',
    borderColor: '#666666',
  },
}));

interface Props {
  linkPrefix: string;
  view: SharedView;
}

export function ShareViewNext({ linkPrefix, view }: Props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [createSharedView, { data: shareId, isLoading, isError }] =
    useCreateSharedViewMutation();

  const handleOpenDialog = () => {
    setDialogOpen(true);
    createSharedView(view);
    Analytics.sendFeatureUsage('share_view', 'create');
  };
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const shareLink = `${window.location.origin}${linkPrefix}/share/${shareId}`;

  return (
    <>
      <IconButton title="Share view" onClick={handleOpenDialog}>
        <SvgIcon sx={{ zIndex: 1 }}>
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.59 13.51L15.42 17.49M15.41 6.51L8.59 10.49M21 5C21 6.65685 19.6569 8 18 8C16.3431 8 15 6.65685 15 5C15 3.34315 16.3431 2 18 2C19.6569 2 21 3.34315 21 5ZM9 12C9 13.6569 7.65685 15 6 15C4.34315 15 3 13.6569 3 12C3 10.3431 4.34315 9 6 9C7.65685 9 9 10.3431 9 12ZM21 19C21 20.6569 19.6569 22 18 22C16.3431 22 15 20.6569 15 19C15 17.3431 16.3431 16 18 16C19.6569 16 21 17.3431 21 19Z"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </SvgIcon>
      </IconButton>
      <Dialog
        PaperProps={{
          sx: {
            borderRadius: 4,
          },
        }}
        open={dialogOpen}
        onClose={handleCloseDialog}
      >
        <DialogTitle
          sx={{
            fontWeight: 500,
            fontSize: '1.5rem',
            lineHeight: '1.75rem',
            letterSpacing: 0,
          }}
        >
          Share the view
        </DialogTitle>
        <Divider />
        <MUIIconButton
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          aria-label="close"
          onClick={handleCloseDialog}
        >
          <CloseIcon />
        </MUIIconButton>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            position: 'relative',
            width: 450,
            p: 2,
          }}
        >
          {isError ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ErrorIcon color="error" />
              <Typography color="error">
                Unable to share the view. Please try again later
              </Typography>
            </Box>
          ) : (
            <>
              <Typography
                sx={{
                  fontSize: '0.875rem',
                  lineHeight: '1rem',
                  letterSpacing: 0,
                  color: '#7E828F',
                }}
              >
                To share this view, send the link below
              </Typography>
              <OutlinedInput
                sx={{
                  pr: 0.66,
                  borderRadius: 2,
                }}
                disabled={!shareId}
                size="small"
                endAdornment={
                  <InputAdornment position="end">
                    <CopyButton disabled={!shareId} copyContent={shareLink} />
                  </InputAdornment>
                }
                inputProps={{
                  disabled: true,
                }}
                value={shareLink}
              />
            </>
          )}
          <Loader active={isLoading} />
        </DialogContent>
      </Dialog>
    </>
  );
}
