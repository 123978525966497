import ErrorIcon from '@mui/icons-material/ErrorRounded';
import ExpandIcon from '@mui/icons-material/ExpandMoreRounded';
import type { AccordionProps } from '@mui/material';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  accordionSummaryClasses,
  Box,
  Typography,
  IconButton,
  AccordionActions,
  Divider,
  SvgIcon,
} from '@mui/material';
import type { MouseEvent } from 'react';
import { useCallback, type ReactNode } from 'react';
import { Button, Loader } from '@/shared/ui';

interface Props {
  active: boolean;
  loading: boolean;
  error?: boolean;
  name: string;
  valueLabel?: string;
  children: ReactNode;
  actions?: ReactNode;
  slotProps?: {
    root?: {
      component?: AccordionProps['component'];
      expanded?: AccordionProps['expanded'];
      onChange?: AccordionProps['onChange'];
    };
  };
  onErrorRetry: () => void;
  onDelete?: () => void;
}

export function FilterNext({
  active,
  error,
  name,
  valueLabel,
  loading,
  children,
  actions,
  slotProps,
  onErrorRetry,
  onDelete,
}: Props) {
  const handleDelete = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      onDelete?.();
    },
    [onDelete],
  );

  let content: ReactNode = children;

  if (error) {
    content = (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
          py: 2,
          px: 3,
        }}
      >
        <ErrorIcon color="error" fontSize="large" />
        <Typography color="secondary" textAlign="center" variant="modalContent">
          Unable to get filter options. Please try again.
        </Typography>
        <Button
          size="small"
          variant="outlined"
          color="inherit"
          sx={{
            px: 2,
            py: 0.5,
          }}
          onClick={onErrorRetry}
        >
          Retry
        </Button>
      </Box>
    );
  }

  return (
    <Accordion
      slotProps={{ transition: { unmountOnExit: true } }}
      elevation={0}
      sx={{
        flexGrow: 1,
        borderRadius: 2,
        border: '1px solid #CDD0D8',

        '&::before': { display: 'none' },

        '&:first-of-type, &:last-of-type': {
          borderRadius: 2,
        },

        '&.Mui-expanded': {
          margin: 0,
        },
      }}
      {...slotProps?.root}
    >
      <AccordionSummary
        expandIcon={<ExpandIcon sx={{ color: '#7E828F' }} />}
        sx={{
          px: 2,
          py: 1.5,
          minHeight: 0,
          height: 40,
          fontSize: '0.875rem',
          lineHeight: '1.125rem',
          color: active ? '#343844' : '#7E828F',

          [`& .${accordionSummaryClasses.content}`]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          },

          [`&.${accordionSummaryClasses.expanded}`]: {
            minHeight: 0,

            [`& .${accordionSummaryClasses.content}`]: {
              margin: 0,
            },
          },
        }}
      >
        <Typography
          variant="modalContent"
          sx={{
            ...(valueLabel && {
              position: 'absolute',
              top: 0,
              left: 12,
              transform: 'translate(0, -50%)',
              backgroundColor: 'white',
              px: 0.5,
              fontSize: '0.75rem',
            }),
          }}
        >
          {name}
        </Typography>
        {valueLabel}
        {onDelete && (
          <IconButton sx={{ p: 0, mx: 1 }} onClick={handleDelete}>
            <SvgIcon sx={{ width: 20, height: 20 }}>
              <svg
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.4998 7.50008L7.49984 12.5001M7.49984 7.50008L12.4998 12.5001M18.3332 10.0001C18.3332 14.6025 14.6022 18.3334 9.99984 18.3334C5.39746 18.3334 1.6665 14.6025 1.6665 10.0001C1.6665 5.39771 5.39746 1.66675 9.99984 1.66675C14.6022 1.66675 18.3332 5.39771 18.3332 10.0001Z"
                  stroke="#BBBEC8"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SvgIcon>
          </IconButton>
        )}
      </AccordionSummary>
      <Divider />
      <Box sx={{ position: 'relative' }}>
        <AccordionDetails sx={{ p: 0, cursor: 'auto' }}>
          {content}
        </AccordionDetails>
        {actions && (
          <>
            <Divider />
            <AccordionActions sx={{ p: 2 }}>{actions}</AccordionActions>
          </>
        )}
        <Loader active={loading} />
      </Box>
    </Accordion>
  );
}
