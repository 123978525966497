import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function CalendarChartIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3 3V21H21"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.3391 3.42871V6.21443M15.9105 3.42871V6.21443M6.85693 9.00014H19.3926M8.24979 4.82157H17.9998C18.769 4.82157 19.3926 5.44517 19.3926 6.21443V15.9644C19.3926 16.7337 18.769 17.3573 17.9998 17.3573H8.24979C7.48054 17.3573 6.85693 16.7337 6.85693 15.9644V6.21443C6.85693 5.44517 7.48054 4.82157 8.24979 4.82157Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
