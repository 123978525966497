import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Drawer,
  List,
  ListItemButton,
  IconButton as MUIIconButton,
  Toolbar as MUIToolbar,
} from '@mui/material';
import type { ListItemButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Features, useFeatureFlags } from '@/features/featureFlags';
import { ROUTES } from '@/shared/constants';
import { Logo } from '@/shared/ui';
import '@fontsource/roboto/500.css';

const Toolbar = styled(MUIToolbar)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}));
const IconButton = styled(MUIIconButton)(() => ({
  height: '100%',
  aspectRatio: '1 / 1',
  borderRadius: 0,
  color: 'white',

  '&:hover': {
    backgroundColor: 'var(--accent-800)',
  },
}));
type ItemProps = ListItemButtonProps & {
  to: string;
};
const Item = styled(({ to, ...props }: ItemProps) => {
  return <ListItemButton size="large" component={NavLink} to={to} {...props} />;
})(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(5)} ${theme.spacing(2)} ${theme.spacing(2.5)}`,

  '&.active': {
    fontWeight: 500,
    borderLeft: `${theme.spacing(0.5)} solid ${theme.palette.primary.dark}`,
    backgroundColor: 'var(--accent-100)',
  },
}));

export function NavigationDrawer() {
  const { isFeatureAvailable } = useFeatureFlags();
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newValue: boolean) => () => {
    setOpen(newValue);
  };

  return (
    <>
      <IconButton onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>
      <Drawer
        PaperProps={{
          sx: {
            width: '312px',
          },
        }}
        ModalProps={{
          slotProps: {
            backdrop: {
              invisible: true,
            },
          },
        }}
        open={open}
        onClose={toggleDrawer(false)}
      >
        <Toolbar>
          <Logo sx={{ flexGrow: 1 }} />
          <MUIIconButton onClick={toggleDrawer(false)}>
            <CloseIcon />
          </MUIIconButton>
        </Toolbar>
        <List disablePadding>
          <Item to={ROUTES.home}>Home</Item>
          <Item to={ROUTES.cohortMarketingPerformance}>
            Cohort Marketing Performance
          </Item>
          <Item to={ROUTES.calendarInsights}>Calendar Insights</Item>
          <Item to={ROUTES.chartsView}>Charts</Item>
          {isFeatureAvailable(Features.redesign) && (
            <Item to={`${ROUTES.redesign}/`}>Redesign 🌸</Item>
          )}
        </List>
      </Drawer>
    </>
  );
}
