import CloseIcon from '@mui/icons-material/CloseRounded';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import type { ChangeEventHandler, MouseEvent } from 'react';
import { SearchIcon } from '../Icons/SearchIcon/SearchIcon';

interface Props {
  placeholder?: string;
  query: string;
  onChange: (query: string) => void;
}

export function SearchInput({
  query,
  onChange,
  placeholder = 'Search',
}: Props) {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange(event.target.value);
  };
  const handleClear = () => {
    onChange('');
  };
  const handleClick = (event: MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();
  };

  return (
    <OutlinedInput
      type="search"
      size="small"
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      endAdornment={
        query.length > 0 ? (
          <InputAdornment position="end">
            <IconButton size="small" onClick={handleClear}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        ) : null
      }
      sx={{ borderRadius: 2 }}
      placeholder={placeholder}
      fullWidth
      value={query}
      onChange={handleChange}
      onClick={handleClick}
    />
  );
}
