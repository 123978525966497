import { IconButton } from '@mui/material';
import { FiltersAndSettingsIcon } from '../FiltersAndSettingsIcon/FiltersAndSettingsIcon';

interface Props {
  onClick: () => void;
}

export function FilterAndSettingsTriggerButton({ onClick }: Props) {
  return (
    <IconButton
      sx={{
        border: '1px solid #BBBEC8',
        borderRadius: 1,
      }}
      onClick={onClick}
    >
      <FiltersAndSettingsIcon />
    </IconButton>
  );
}
