import { Box, Divider, MenuList, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import type { MetricDataFormatType } from '@/entities/columnsConfig';
import { toFormatType } from '@/entities/columnsConfig';
import type { DataType } from '@/shared/hooks';
import { Button as SharedButton } from '@/shared/ui';
import { isNumberFilterAllValuesSelected } from '../../model/isAllValuesSelected';
import type { NumberFilterParams } from '../../model/types';
import { useFiltersParamsContext } from '../FiltersParamsContext/FiltersParamsContext';
import { NumberFilter } from '../NumberFilter/NumberFilter';
import type { Value } from '../NumberFilter/NumberFilter';

const Button = styled(SharedButton)(({ theme }) => ({
  padding: `${theme.spacing(0.5)} ${theme.spacing(1.25)}`,
}));

interface Props {
  id: string;
  value?: Value;
  onChange: (v?: Value) => void;
}

export function QuickNumberFilter({ id, value, onChange }: Props) {
  const { configsMap, paramsData } = useFiltersParamsContext();
  const [internalValue, setInternalValue] = useState<Value | undefined>(value);
  const params =
    paramsData[id] && paramsData[id].params
      ? (paramsData[id].params as NumberFilterParams)
      : undefined;
  const { name, type, dataType } = configsMap[id] || {};

  const handleClear = () => {
    setInternalValue(undefined);
  };
  const handleApply = () => {
    onChange(internalValue);
  };

  return (
    <>
      <Box
        sx={{
          pt: 3,
          px: 3,
          pb: 1.25,
          display: 'flex',
          flexDirection: 'column',
          width: 384,
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            lineHeight: '22px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: 'var(--neutral-700)',
            mb: 2,
          }}
        >
          {name}
        </Typography>
        <MenuList>
          <NumberFilter
            id={id}
            type={type as DataType}
            formatType={toFormatType(dataType as MetricDataFormatType)}
            value={internalValue}
            onChange={setInternalValue}
          />
        </MenuList>
      </Box>
      <Divider />
      <Box
        sx={{
          py: 2,
          px: 3,
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 2,
        }}
      >
        <Button
          size="small"
          color="secondary"
          variant="text"
          onClick={handleClear}
          disabled={isNumberFilterAllValuesSelected(
            internalValue,
            params?.min,
            params?.max,
          )}
        >
          Clear
        </Button>
        <Button size="small" variant="contained" onClick={handleApply}>
          Apply
        </Button>
      </Box>
    </>
  );
}
