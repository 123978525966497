import { useCallback } from 'react';
import { selectEmail } from '@/entities/session';
import { useAppSelector } from '@/shared/model';

export enum Features {
  redesign = 'redesign',
}

enum AccessEntity {
  user = 'user',
  company = 'company',
}
type AccessRecord =
  | {
      type: AccessEntity.company;
      postfix: string;
    }
  | { type: AccessEntity.user; email: string };

const FEATURE_GROUPS: Record<Features, AccessRecord[]> = {
  [Features.redesign]: [
    {
      type: AccessEntity.company,
      postfix: '@campaignswell.com',
    },
  ],
};

export const useFeatureFlags = () => {
  const email = useAppSelector(selectEmail);
  const isFeatureAvailable = useCallback(
    (feature: Features) => {
      const groups = FEATURE_GROUPS[feature];

      return (
        !!email &&
        groups.some((group) => {
          switch (group.type) {
            case AccessEntity.company:
              return email.endsWith(group.postfix);
            case AccessEntity.user:
              return email === group.email;
          }

          return false;
        })
      );
    },
    [email],
  );

  return {
    isFeatureAvailable,
  };
};
