import { Box, ListSubheader } from '@mui/material';
import { Analytics } from '@/shared/lib';
import type { FilterValue } from '../../model/types';
import { FilterItem } from '../FilterItem/FilterItem';
import { useFiltersContext } from '../FiltersContext/FiltersContext';
import type { Group } from './AddFilterList';

interface Props {
  value: Group;
}

export function GroupItem({ value }: Props) {
  const { filtersMap, updateFilters } = useFiltersContext();

  const handleChange = (id: string) => (newValue?: FilterValue) => {
    updateFilters(id, newValue);
    Analytics.sendFeatureUsage('filters_and_settings_panel', 'change_value', {
      filterName: id,
    });
  };

  return (
    <li>
      <ul>
        <ListSubheader
          sx={{
            px: 2,
            py: 1,
            fontSize: '0.875rem',
            lineHeight: '1rem',
            fontWeight: 500,
            color: '#343844',
            backgroundColor: '#F1F3F8',
          }}
        >
          {value.name}
        </ListSubheader>
        <Box
          sx={{
            p: 2,
            listStyle: 'none',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {value.configs.map((filter) => {
            const key = `${filter.group}-${filter.id}`;

            return (
              <FilterItem
                key={key}
                filterConfig={filter}
                value={filtersMap[filter.id]}
                onChange={handleChange(filter.id)}
              />
            );
          })}
        </Box>
      </ul>
    </li>
  );
}
