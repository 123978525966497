import InfoOutlined from '@mui/icons-material/InfoOutlined';
import type { ListItemButtonProps } from '@mui/material';
import {
  ListItem,
  ListItemButton as MUIListItemButton,
  ListSubheader,
  Tooltip,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type { CSSProperties, MouseEvent } from 'react';
import { useRef, useEffect } from 'react';
import { Checkbox } from '@/shared/ui';
import type { Group } from '../../model/types';

const ListItemButton = styled((props: ListItemButtonProps) => {
  return (
    <MUIListItemButton component="label" {...props}>
      <Checkbox
        color="primary"
        sx={{
          p: 0,
          '&.Mui-disabled .MuiSvgIcon-root': {
            color: (theme) => theme.palette.primary.main,
          },
        }}
        disabled
        checked={props.selected}
        tabIndex={-1}
        disableRipple
      />
      {props.children}
    </MUIListItemButton>
  );
})(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.25),
  lineHeight: '22px',
  fontSize: '14px',
  padding: `${theme.spacing(1.25)} ${theme.spacing(2)}`,
  color: 'var(--neutral-700)',

  '&.Mui-selected': {
    backgroundColor: 'transparent',
  },

  '&:hover, &:focus-visible': {
    backgroundColor: 'var(--accent-100)',
  },
}));

interface Props {
  style: CSSProperties;
  value: Group;
  selected: Set<string>;
  onToggle: (item: string) => void;
  setHeight: (height: number) => void;
}

export function GroupItem({
  style,
  value,
  selected,
  onToggle,
  setHeight,
}: Props) {
  const rootRef = useRef<HTMLUListElement>(null);

  useEffect(
    () => {
      if (rootRef.current) {
        setHeight(rootRef.current.clientHeight - 21);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [rootRef],
  );

  const handleOptionToggle = (item: string) => (event: MouseEvent) => {
    event.stopPropagation();
    onToggle(item);
  };

  return (
    <li style={style}>
      <ul ref={rootRef}>
        <ListSubheader
          sx={{
            px: 2,
            py: 1,
            fontSize: '0.875rem',
            lineHeight: '1rem',
            fontWeight: 500,
            color: '#343844',
            backgroundColor: '#F1F3F8',
          }}
        >
          {value.name}
        </ListSubheader>
        {value.items.map((item) => {
          return (
            <ListItem key={item.value} disablePadding>
              <ListItemButton
                selected={selected.has(item.value)}
                onClick={handleOptionToggle(item.value)}
              >
                {item.name}
                <Box sx={{ flexGrow: 1 }} />
                <Tooltip arrow title={item.description}>
                  <InfoOutlined
                    sx={{ width: 16, height: 16, color: '#817E7E' }}
                  />
                </Tooltip>
              </ListItemButton>
            </ListItem>
          );
        })}
      </ul>
    </li>
  );
}
