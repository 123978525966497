import { TextField } from '@mui/material';

interface Props {
  value: string;
  error: string;
  onChange: (value: string) => void;
}

export function CreatePreset({ value, error, onChange }: Props) {
  return (
    <TextField
      label="Preset name"
      fullWidth
      InputProps={{
        inputProps: {
          sx: {
            py: 1.5,
            px: 2,
            height: 16,
            fontWeight: 400,
            fontSize: '0.875rem',
            lineHeight: '1rem',
            letterSpacing: 0,
          },
        },
        sx: {
          borderRadius: 2,
        },
      }}
      InputLabelProps={{
        sx: {
          top: -8,

          '&.MuiInputLabel-shrink': {
            top: 0,
          },
        },
      }}
      error={!!error}
      helperText={error}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
}
