import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Autocomplete, TextField } from '@mui/material';
import type { SavedViewRecord } from '../../model/types';

interface Props {
  views: SavedViewRecord[];
  value?: SavedViewRecord;
  onChange: (view: SavedViewRecord) => void;
}

export function UpdatePreset({ views, value, onChange }: Props) {
  return (
    <Autocomplete
      fullWidth
      popupIcon={<KeyboardArrowDownRoundedIcon />}
      disableClearable
      options={views}
      value={value}
      getOptionLabel={(view) => view.savedViewName}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Preset to update"
          InputProps={{
            ...params.InputProps,
            sx: {
              borderRadius: 2,
              height: 40,
            },
            inputProps: {
              ...params.inputProps,
              sx: {
                fontWeight: 400,
                fontSize: '0.875rem',
                lineHeight: '1rem',
                letterSpacing: 0,
              },
            },
            size: 'small',
          }}
        />
      )}
      isOptionEqualToValue={(view, value) => {
        return view.uuid === value.uuid;
      }}
      onChange={(_, newValue) => {
        onChange(newValue);
      }}
    />
  );
}
