import BackIcon from '@mui/icons-material/ArrowBackRounded';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import type { MouseEvent } from 'react';
import { Button, Panel, SearchInput, ToggleButtonGroup } from '@/shared/ui';
import type { FilterConfig } from '../../model/types';
import { AddFilterList } from '../AddFilterList/AddFilterList';
import { useFiltersContext } from '../FiltersContext/FiltersContext';
import { useFiltersParamsContext } from '../FiltersParamsContext/FiltersParamsContext';
import { useActiveFiltersCount } from './useActiveFiltersCount';

enum GroupType {
  all = 'all',
  dimensions = 'dimensions',
  metrics = 'metrics',
}

interface Props {
  className?: string;
}

export function AddFilter({ className }: Props) {
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeGroup, setActiveGroup] = useState<GroupType>(
    GroupType.dimensions,
  );
  const { filters, submitFilters, resetToInitialFilters } = useFiltersContext();
  const { dimensionFiltersCount, metricFiltersCount } =
    useActiveFiltersCount(filters);
  const { configs, metricConfigs, dimensionConfigs } =
    useFiltersParamsContext();
  const options = useMemo(() => {
    return [
      {
        label: 'All',
        value: GroupType.all,
      },
      {
        label: (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              whiteSpace: 'nowrap',
            }}
          >
            Dimensions{' '}
            <Box
              sx={{
                fontSize: '0.625rem',
                fontWeight: 500,
                lineHeight: '0.75rem',
                backgroundColor:
                  dimensionFiltersCount > 0 ? 'primary.main' : '#BBBEC8',
                color: 'white',
                px: 0.5,
                py: 0.25,
                borderRadius: 6,
                minWidth: 16,
              }}
            >
              {dimensionFiltersCount}
            </Box>
          </Box>
        ),
        value: GroupType.dimensions,
      },
      {
        label: (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              whiteSpace: 'nowrap',
            }}
          >
            Metrics{' '}
            <Box
              sx={{
                fontSize: '0.625rem',
                fontWeight: 500,
                lineHeight: '0.75rem',
                backgroundColor:
                  metricFiltersCount > 0 ? 'primary.main' : '#BBBEC8',
                color: 'white',
                px: 0.5,
                py: 0.25,
                borderRadius: 6,
                minWidth: 16,
              }}
            >
              {metricFiltersCount}
            </Box>
          </Box>
        ),
        value: GroupType.metrics,
      },
    ];
  }, [dimensionFiltersCount, metricFiltersCount]);
  const filteredConfigs = useMemo(() => {
    let activeConfigs: FilterConfig[] = configs;

    if (activeGroup === GroupType.dimensions) {
      activeConfigs = dimensionConfigs;
    } else if (activeGroup === GroupType.metrics) {
      activeConfigs = metricConfigs;
    }

    return activeConfigs.filter((config) => {
      return config.name.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }, [activeGroup, configs, metricConfigs, dimensionConfigs, searchQuery]);

  const handleOpen = () => {
    setActiveGroup(GroupType.dimensions);
    setOpen(true);
    setSearchQuery('');
  };
  const handleClose = () => {
    setOpen(false);
    resetToInitialFilters();
  };
  const handlePanelClick = (event: MouseEvent) => {
    event.stopPropagation();
  };
  const handleApplyFilters = () => {
    submitFilters();
    handleClose();
  };

  return (
    <>
      <Button
        className={className}
        variant="outlined"
        sx={{
          px: 1.5,
          py: 0.625,
          borderRadius: 2,
          lineHeight: '1.125rem',
        }}
        onClick={handleOpen}
      >
        Add Filter
      </Button>
      {open && (
        <Panel
          sx={{
            position: 'fixed',
            top: 80,
            left: 80,
            bottom: 8,
            width: 337,
            backgroundColor: 'white',
            zIndex: 1,
            boxShadow: 'none',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}
          onClick={handlePanelClick}
        >
          <Box
            sx={{
              py: 3,
              pl: 1.5,
              pr: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: '#F1F3F8',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <IconButton sx={{ p: 0 }} onClick={handleClose}>
                <BackIcon />
              </IconButton>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: 500,
                  fontSize: '1.5em',
                  lineHeight: '1.75rem',
                  letterSpacing: 0,
                }}
              >
                Add Filter
              </Typography>
            </Box>
            <IconButton sx={{ p: 0 }} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              backgroundColor: '#F1F3F8',
              animation: 'shrink 0.2s ease-out',

              '@keyframes shrink': {
                from: { height: 216 },
                to: { height: 0 },
              },
            }}
          />
          <Divider />
          <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <ToggleButtonGroup
              fullWidth
              options={options}
              value={activeGroup}
              onChange={setActiveGroup}
            />
            <SearchInput query={searchQuery} onChange={setSearchQuery} />
          </Box>
          <Divider />
          <Box sx={{ flexGrow: 1, position: 'relative' }}>
            <AddFilterList filterConfigs={filteredConfigs} />
          </Box>
          <Divider />
          <Box sx={{ p: 2, display: 'flex', gap: 2 }}>
            <Button
              sx={{ lineHeight: '1.125rem', borderRadius: 2 }}
              onClick={handleApplyFilters}
            >
              Apply Filters
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              sx={{ lineHeight: '1.125rem', borderRadius: 2 }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </Panel>
      )}
    </>
  );
}
