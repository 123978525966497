import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useAppDispatch } from '@/shared/model';
import { submitTikTokForBusinessReportingIntegration } from '../../../model/actions';
import { BROADCAST_CHANNEL_NAME } from './constants';

interface TikTokAuthCodeState {
  processing: boolean;
  openDataAccessDialog: () => void;
}

export const useTikTokAuthCode = (): TikTokAuthCodeState => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [processing, setProcessing] = useState(false);

  return {
    processing,
    openDataAccessDialog: () => {
      const appId = '7487879684303093776';
      const url = `https://business-api.tiktok.com/portal/auth?app_id=${appId}&redirect_uri=${window.location.href}`;
      const dataAccessWindow = window.open(url, '_blank');
      const broadcastChannel = new BroadcastChannel(BROADCAST_CHANNEL_NAME);
      const messageHandler = (
        event: MessageEvent<{ auth_code: string; code: string }>,
      ) => {
        const { code, auth_code } = event.data;

        dataAccessWindow?.close();
        broadcastChannel.removeEventListener('message', messageHandler);

        setProcessing(true);
        dispatch(
          submitTikTokForBusinessReportingIntegration({
            appId,
            code,
            authCode: auth_code,
          }),
        )
          .catch(() => {
            enqueueSnackbar('Unable to add account. Please, try again.', {
              variant: 'error',
              autoHideDuration: 4000,
            });
          })
          .finally(() => {
            setProcessing(false);
          });
      };

      broadcastChannel.addEventListener('message', messageHandler);
    },
  };
};
