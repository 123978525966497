import type { ListItemButtonProps } from '@mui/material';
import {
  Box,
  Divider,
  ListItem,
  ListItemButton as MUIListItemButton,
  Typography,
  buttonClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMemo, useState } from 'react';
import type { ColumnsConfig } from '@/entities/columnsConfig';
import type { MinMaxData } from '@/shared/ui';
import { Button, Checkbox, Dropdown, SearchInput } from '@/shared/ui';
import { getDefaultMetricColoring } from '../../libs/getDefaultMetricColoring';
import type { MetricColoring } from '../../model/types';

const ListItemButton = styled((props: ListItemButtonProps) => {
  return (
    <MUIListItemButton component="label" {...props}>
      <Checkbox
        color="primary"
        sx={{
          p: 0,
          '&.Mui-disabled .MuiSvgIcon-root': {
            color: (theme) => theme.palette.primary.main,
          },
        }}
        disabled
        checked={props.selected}
        tabIndex={-1}
        disableRipple
      />
      {props.children}
    </MUIListItemButton>
  );
})(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.25),
  lineHeight: '22px',
  fontSize: '14px',
  padding: `${theme.spacing(1.25)} ${theme.spacing(2)}`,
  color: 'var(--neutral-700)',

  '&.Mui-selected': {
    backgroundColor: 'transparent',
  },

  '&:hover, &:focus-visible': {
    backgroundColor: 'var(--accent-100)',
  },
}));

interface Props {
  className?: string;
  activeMetrics: string[];
  metricsColoring: MetricColoring[];
  minMax?: MinMaxData;
  columnsConfig: ColumnsConfig;
  isColumnsConfigLoaded: boolean;
  onColoredMetricsChange: (v: MetricColoring[]) => void;
}

export function AddThreshold({
  className,
  activeMetrics,
  metricsColoring,
  minMax,
  columnsConfig,
  isColumnsConfigLoaded,
  onColoredMetricsChange,
}: Props) {
  const [searchQuery, setSearchQuery] = useState('');
  const [checkedMetrics, setCheckedMetrics] = useState<string[]>(
    metricsColoring.map((metric) => metric.metric),
  );
  const [open, setOpen] = useState(false);
  const filteredMetrics = useMemo(() => {
    return activeMetrics.filter((metric) => {
      return metric.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }, [activeMetrics, searchQuery]);
  const coloredMetricsMap = useMemo(() => {
    return new Map(metricsColoring.map((metric) => [metric.metric, metric]));
  }, [metricsColoring]);

  if (!isColumnsConfigLoaded) {
    return null;
  }

  const handleOpen = (newOpen: boolean) => {
    if (newOpen) {
      setCheckedMetrics(metricsColoring.map((metric) => metric.metric));
    }

    setOpen(newOpen);
  };
  const handleClearAll = () => {
    setCheckedMetrics([]);
  };
  const handleApply = () => {
    onColoredMetricsChange(
      checkedMetrics.map((metric) => {
        if (coloredMetricsMap.has(metric)) {
          return coloredMetricsMap.get(metric)!;
        }

        return getDefaultMetricColoring(metric, minMax);
      }),
    );
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const handlePanelClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };
  const handleMetricToggle = (metric: string) => () => {
    if (checkedMetrics.includes(metric)) {
      setCheckedMetrics(checkedMetrics.filter((m) => m !== metric));
    } else {
      setCheckedMetrics([...checkedMetrics, metric]);
    }
  };

  return (
    <Dropdown
      labelProps={{
        className: className,
        color: 'primary',
        variant: 'outlined',
        sx: {
          px: 1.5,
          py: 0.625,
          borderRadius: 2,

          [`& .${buttonClasses.endIcon}`]: {
            display: 'none',
          },
        },
      }}
      paperProps={{
        sx: {
          width: 304,
          borderRadius: 2,
        },
      }}
      label="Add Thresholds"
      open={open}
      onOpenChange={handleOpen}
    >
      <Box onClick={handlePanelClick}>
        <Box sx={{ p: 2 }}>
          <SearchInput query={searchQuery} onChange={setSearchQuery} />
        </Box>
        <Divider />
        <Box
          sx={{
            px: 2,
            py: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            component="span"
            variant="modalContent"
            sx={{ fontWeight: 500, color: '#7E828F' }}
          >
            Selected:{' '}
            <Box
              component="span"
              sx={{ color: (theme) => theme.palette.primary.main }}
            >
              {checkedMetrics.length}
            </Box>{' '}
            of {activeMetrics.length}
          </Typography>
          <Button
            sx={{ py: 0, lineHeight: '1.375rem', color: 'black' }}
            variant="text"
            color="secondary"
            onClick={handleClearAll}
          >
            Clear all
          </Button>
        </Box>
        <Divider />
        <Box component="ul" sx={{ m: 0, p: 0, height: 250, overflow: 'auto' }}>
          {filteredMetrics.map((metric) => {
            const config = columnsConfig[metric];

            return (
              <ListItem key={metric} disablePadding>
                <ListItemButton
                  selected={checkedMetrics.includes(metric)}
                  onClick={handleMetricToggle(metric)}
                >
                  {config.name}
                </ListItemButton>
              </ListItem>
            );
          })}
        </Box>
        <Divider />
        <Box sx={{ p: 2, display: 'flex', gap: 2 }}>
          <Button
            sx={{ px: 2, py: 1.5, lineHeight: '1.125rem', borderRadius: 2 }}
            variant="contained"
            color="primary"
            onClick={handleApply}
          >
            Apply
          </Button>
          <Button
            sx={{
              px: 2,
              py: 1.5,
              lineHeight: '1.125rem',
              color: 'black',
              borderRadius: 2,
            }}
            variant="outlined"
            color="secondary"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Dropdown>
  );
}
