import type { SxProps } from '@mui/material';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { selectCurrency } from '@/entities/session';
import type { DataType, FormatType } from '@/shared/hooks';
import { useNumberFormatter } from '@/shared/hooks';
import { isFinished } from '@/shared/lib';
import { useAppSelector } from '@/shared/model';
import { TypedNumberInput } from '@/shared/ui';
import type { NumberFilterParams } from '../../model/types';
import { useFiltersParamsContext } from '../FiltersParamsContext/FiltersParamsContext';

const ControlWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
}));
const ControlHelperText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: theme.typography.pxToRem(12),
  color: theme.palette.text.secondary,
}));

export enum NumberFilterIntervalType {
  CLOSED = 'closed',
  OPEN_POSITIVE = 'openPositive',
  OPEN_NEGATIVE = 'openNegative',
}

export type Value =
  | {
      type: NumberFilterIntervalType.CLOSED;
      value: [number, number];
    }
  | {
      type:
        | NumberFilterIntervalType.OPEN_POSITIVE
        | NumberFilterIntervalType.OPEN_NEGATIVE;
      value: number;
    };

interface Props {
  sx?: SxProps;
  id: string;
  type: DataType;
  formatType: FormatType;
  value?: Value;
  onChange: (v?: Value) => void;
}

export function NumberFilter({
  sx,
  id,
  type,
  formatType,
  value,
  onChange,
}: Props) {
  const userCurrency = useAppSelector(selectCurrency);
  const formattersMap = useNumberFormatter(userCurrency);
  const { paramsData, requestNumberFilterParams } = useFiltersParamsContext();
  const filterParamsLoadable = paramsData[id];
  const params = isFinished(filterParamsLoadable?.status)
    ? (filterParamsLoadable.params as NumberFilterParams)
    : undefined;

  useEffect(
    () => {
      requestNumberFilterParams(id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (!filterParamsLoadable) {
    return null;
  }

  const handleFromChange = (newFrom?: number) => {
    const toValue = getToValue(value);

    if (newFrom === undefined) {
      if (toValue === undefined) {
        onChange(undefined);
      } else {
        onChange({
          type: NumberFilterIntervalType.OPEN_NEGATIVE,
          value: toValue,
        });
      }
    } else if (toValue === undefined) {
      onChange({
        type: NumberFilterIntervalType.OPEN_POSITIVE,
        value: newFrom,
      });
    } else {
      onChange({
        type: NumberFilterIntervalType.CLOSED,
        value: [newFrom, toValue!],
      });
    }
  };
  const handleToChange = (newTo?: number) => {
    const fromValue = getFromValue(value);

    if (newTo === undefined) {
      if (fromValue === undefined) {
        onChange(undefined);
      } else {
        onChange({
          type: NumberFilterIntervalType.OPEN_POSITIVE,
          value: fromValue,
        });
      }
    } else if (fromValue === undefined) {
      onChange({
        type: NumberFilterIntervalType.OPEN_NEGATIVE,
        value: newTo,
      });
    } else {
      onChange({
        type: NumberFilterIntervalType.CLOSED,
        value: [fromValue, newTo!],
      });
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: 5, ...sx }}>
      <ControlWrapper>
        <Typography color="secondary" variant="subtitle2">
          From
        </Typography>
        <TypedNumberInput
          currency={userCurrency}
          allowEmpty
          type={type}
          placeholder="-∞"
          formatType={formatType}
          value={getFromValue(value) || -Infinity}
          onChange={handleFromChange}
        />
        {params?.min != null && (
          <ControlHelperText>
            Min: {formattersMap[type][formatType](params.min)}
          </ControlHelperText>
        )}
      </ControlWrapper>
      <ControlWrapper>
        <Typography color="secondary" variant="subtitle2">
          To
        </Typography>
        <TypedNumberInput
          currency={userCurrency}
          allowEmpty
          type={type}
          formatType={formatType}
          placeholder="∞"
          value={getToValue(value) || Infinity}
          onChange={handleToChange}
        />
        {params?.max != null && (
          <ControlHelperText>
            Max: {formattersMap[type][formatType](params.max)}
          </ControlHelperText>
        )}
      </ControlWrapper>
    </Box>
  );
}

const getFromValue = (value?: Value): number | undefined => {
  if (!value) {
    return undefined;
  }

  if (value.type === NumberFilterIntervalType.CLOSED) {
    return value.value[0];
  }

  if (value.type === NumberFilterIntervalType.OPEN_POSITIVE) {
    return value.value;
  }

  return undefined;
};

export const getToValue = (value?: Value): number | undefined => {
  if (!value) {
    return undefined;
  }

  if (value.type === NumberFilterIntervalType.OPEN_NEGATIVE) {
    return value.value;
  }

  if (value.type === NumberFilterIntervalType.CLOSED) {
    return value.value[1];
  }

  return undefined;
};
