import { DateScale } from '@/shared/types';
import { Select } from '../Select/Select';

const ITEMS: Record<DateScale, string> = {
  [DateScale.DAY]: 'Daily',
  [DateScale.WEEK]: 'Weekly',
  [DateScale.MONTH]: 'Monthly',
  [DateScale.THREE_DAYS]: '3 days',
  [DateScale.SEVEN_DAYS]: '7 days',
  [DateScale.FOURTEEN_DAYS]: '14 days',
  [DateScale.THIRTY_DAYS]: '30 days',
};

interface Props {
  label?: string;
  value: DateScale;
  onChange: (v: DateScale) => void;
}

export function DateScaleSelectNext({ label, value, onChange }: Props) {
  return (
    <Select
      label={label ?? 'Date Scale'}
      options={ITEMS}
      value={value}
      onChange={onChange}
    />
  );
}
