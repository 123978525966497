import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';
import type { MetricColoring } from '@/features/DataGridColoring';
import type { Filters } from '@/features/filters';
import type { ColumnsConfig } from '@/entities/columnsConfig';
import type { Sorting } from '@/shared/types';
import type { MinMaxData } from '@/shared/ui';

interface ContextState {
  minMax?: MinMaxData;
  columnsConfig: ColumnsConfig;
  advancedSortableOptions: { name: string; value: string }[];
  sorting: Sorting;
  filters: Filters;
  metricsColoring: MetricColoring[];
  updateSorting: (field: string, sorting?: Sorting[number]) => void;
  updateFilters: (filters: Filters) => void;
  updateMetricsColoring: (metricsColoring: MetricColoring[]) => void;
  onDimensionDelete: (field: string) => void;
  onMetricDelete: (field: string) => void;
}

const Context = createContext<ContextState | null>(null);

interface Props {
  children: ReactNode;
  minMax?: MinMaxData;
  columnsConfig: ColumnsConfig;
  advancedSortableOptions: { name: string; value: string }[];
  sorting: Sorting;
  filters: Filters;
  metricsColoring: MetricColoring[];
  updateMetricsColoring: (metricsColoring: MetricColoring[]) => void;
  updateFilters: (filters: Filters) => void;
  updateSorting: (field: string, sorting?: Sorting[number]) => void;
  onDimensionDelete: (field: string) => void;
  onMetricDelete: (field: string) => void;
}

export function TableHeaderPropsProvider({
  children,
  minMax,
  columnsConfig,
  advancedSortableOptions,
  sorting,
  filters,
  metricsColoring,
  updateMetricsColoring,
  updateFilters,
  updateSorting,
  onDimensionDelete,
  onMetricDelete,
}: Props) {
  return (
    <Context.Provider
      value={{
        minMax,
        columnsConfig,
        advancedSortableOptions,
        sorting,
        filters,
        metricsColoring,
        updateSorting,
        updateFilters,
        updateMetricsColoring,
        onDimensionDelete,
        onMetricDelete,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export const useTableHeaderPropsContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error(
      'useTableHeaderPropsContext hook used outside <TableHeaderPropsProvider />',
    );
  }

  return context;
};
