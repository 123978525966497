import CampaignIcon from '@mui/icons-material/Campaign';
import LinkIcon from '@mui/icons-material/InsertLinkRounded';
import PasswordIcon from '@mui/icons-material/KeyRounded';
import LogoutIcon from '@mui/icons-material/LogoutRounded';
import {
  Divider,
  IconButton as MUIIconButton,
  Menu,
  MenuItem as MUIMenuItem,
  Tooltip,
  Card,
  dividerClasses,
  ListItemIcon,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ChangePasswordDialog } from '@/features/changePassword';
import { logout, selectEmail } from '@/entities/session';
import { ROUTES } from '@/shared/constants';
import { Monitoring } from '@/shared/lib';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { DatasourcesIcon } from '@/shared/ui';

const NO_EMAIL_PLACEHOLDER = 'Unknow user';

const IconButton = styled(MUIIconButton)(() => ({
  height: '100%',
  aspectRatio: '1 / 1',
}));
const MenuItem = styled(MUIMenuItem)(({ theme }) => ({
  fontSize: '14px',
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,

  [`+.${dividerClasses.root}`]: {
    margin: 0,
  },
})) as typeof MUIMenuItem;

export function ProfileMenuNext() {
  const dispatch = useAppDispatch();
  const email = useAppSelector(selectEmail);
  const anchorRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [changePasswordDialogOpen, setChangePasswordDialogOpen] =
    useState(false);

  const toggleMenu = (newValue: boolean) => () => {
    setMenuOpen(newValue);
  };
  const toggleChangePasswordOpen = (newValue: boolean) => () => {
    setChangePasswordDialogOpen(newValue);
  };
  const showReportDialog = () => {
    Monitoring.showReportDialog();
  };
  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      <Tooltip title="Account settings">
        <IconButton
          ref={anchorRef}
          sx={{ mx: -1, height: 56, width: 56 }}
          onClick={toggleMenu(!menuOpen)}
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="16" cy="16" r="16" fill="#DFE6FF" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.5 14.9C18.433 14.9 20 13.3554 20 11.45C20 9.54465 18.433 8 16.5 8C14.567 8 13 9.54465 13 11.45C13 13.3554 14.567 14.9 16.5 14.9ZM23 20.8032C23 22.6956 20.09 23.9968 16.5002 23.9968H16.4998C12.91 23.9968 10 22.6956 10 20.8032C10 19.0762 11.6774 17.1362 13.9137 16.4968C14.5664 17.562 15.7777 18.8868 16.4998 18.8868H16.5002C17.2223 18.8868 18.4339 17.562 19.0863 16.4968C21.3226 17.1362 23 19.0762 23 20.8032Z"
              fill="#063FFE"
            />
          </svg>
        </IconButton>
      </Tooltip>
      <Menu
        MenuListProps={{
          sx: {
            p: 1,
          },
        }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: 3,
            },
          },
        }}
        anchorEl={anchorRef.current}
        open={menuOpen}
        onClose={toggleMenu(false)}
        onClick={toggleMenu(false)}
      >
        <Card
          elevation={0}
          sx={{
            px: 2,
            py: 3,
            mb: 2,
            borderRadius: 2,
            fontSize: '0.875rem',
            color: 'text.secondary',
            bgcolor: 'var(--neutral-100)',
          }}
        >
          {email || NO_EMAIL_PLACEHOLDER}
        </Card>
        <MenuItem component={Link} to={ROUTES.webLinks}>
          <ListItemIcon>
            <LinkIcon />
          </ListItemIcon>
          Web links
        </MenuItem>
        <MenuItem component={Link} to={ROUTES.datasources}>
          <ListItemIcon>
            <DatasourcesIcon />
          </ListItemIcon>
          Data sources
        </MenuItem>
        <Divider />
        <MenuItem onClick={toggleChangePasswordOpen(true)}>
          <ListItemIcon>
            <PasswordIcon />
          </ListItemIcon>
          Change password
        </MenuItem>
        <MenuItem onClick={showReportDialog}>
          <ListItemIcon>
            <CampaignIcon />
          </ListItemIcon>
          Report feedback
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <ChangePasswordDialog
        open={changePasswordDialogOpen}
        onClose={toggleChangePasswordOpen(false)}
      />
    </>
  );
}
