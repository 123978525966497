import { SvgIcon } from '@mui/material';
import { RowHeights } from '@/shared/constants/dataTable';
import { ToggleButtonGroup } from '../ToggleButtonGroup/ToggleButtonGroup';

const options = [
  {
    label: (
      <SvgIcon>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4 6H20"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M4 12H20"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M4 18H20"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </SvgIcon>
    ),
    value: RowHeights.sparse,
    title: 'Large row size',
  },
  {
    label: (
      <SvgIcon>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4 5H20"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M4 10H20"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M4 15H20"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M4 20H20"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </SvgIcon>
    ),
    value: RowHeights.normal,
    title: 'Normal row size',
  },
  {
    label: (
      <SvgIcon>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4 4H20"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M4 8H20"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M4 12H20"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M4 16H20"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M4 20H20"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </SvgIcon>
    ),
    value: RowHeights.dense,
    title: 'Dense row size',
  },
];

interface Props {
  value: number;
  onChange: (v: number) => void;
}

export function DensitySwitcherNext({ value, onChange }: Props) {
  return (
    <ToggleButtonGroup options={options} value={value} onChange={onChange} />
  );
}
