import type { ReactNode } from 'react';
import { createContext, useContext, useState, useCallback } from 'react';

interface ContextState {
  canApplyDefaultView: boolean;
  onNavigatedViewDetect: () => void;
  onNavigatedViewApply: () => void;
  onNavigatedViewError: () => void;
}

const Context = createContext<ContextState | null>(null);

enum States {
  noNavigatedView = 'noNavigatedView',
  navigatedViewProcessing = 'navigatedViewProcessing',
  navigatedViewApplied = 'navigatedViewApplied',
  navigatedViewError = 'navigatedViewError',
}

interface Props {
  children: ReactNode;
}

export const ViewInitializationStateMachineProvider = ({ children }: Props) => {
  const [state, setState] = useState<States>(States.noNavigatedView);
  const onNavigatedViewDetect = useCallback(() => {
    setState(States.navigatedViewProcessing);
  }, []);
  const onNavigatedViewApply = useCallback(() => {
    setState(States.navigatedViewApplied);
  }, []);
  const onNavigatedViewError = useCallback(() => {
    setState(States.navigatedViewError);
  }, []);

  return (
    <Context.Provider
      value={{
        get canApplyDefaultView() {
          return (
            state === States.noNavigatedView ||
            state === States.navigatedViewError
          );
        },
        onNavigatedViewDetect,
        onNavigatedViewApply,
        onNavigatedViewError,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useViewInitializationStateMachineContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error(
      'useViewInitializationStateMachineContext hook used outside <ViewInitializationStateMachineProvider />',
    );
  }

  return context;
};
