import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogTitle, Divider, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { SearchInput } from '@/shared/ui';
import type { SavedViewRecord } from '../../model/types';
import { SavedViewItem } from '../SavedViewItem/SavedViewItem';

const List = styled('ul')(() => ({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  height: 300,
  overflowY: 'auto',
  width: 420,
}));

interface Props {
  viewName: string;
  viewURL: string;
  savedViews: SavedViewRecord[];
  open: boolean;
  onClose: () => void;
}

export function SavedViewsDialog({
  viewName,
  viewURL,
  savedViews,
  open,
  onClose,
}: Props) {
  const [query, setQuery] = useState('');
  const filteredViews = savedViews.filter((view) => {
    return view.savedViewName.toLowerCase().includes(query.toLowerCase());
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <span>{viewName} Presets</span>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <Box sx={{ p: 2 }}>
        <SearchInput query={query} onChange={setQuery} />
      </Box>
      <Divider />
      <List>
        {filteredViews.length === 0 && (
          <Box sx={{ p: 2, color: '#817E7E', textAlign: 'center' }}>
            No views found
          </Box>
        )}
        {filteredViews.map((view) => {
          return (
            <SavedViewItem key={view.uuid} view={view} viewURL={viewURL} />
          );
        })}
      </List>
    </Dialog>
  );
}
