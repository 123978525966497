import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import * as Sentry from '@sentry/browser';
import { baseApi, getErrorMessage } from '@/shared/api';
import { legacyFiltersValueAdapter } from '@/shared/data';
import type { SharedView } from '../model/types';
import type { SharedViewDTO } from './types';

export const api = createApi({
  reducerPath: 'sharedViewsAPI',
  baseQuery: fakeBaseQuery(),
  endpoints: (builder) => ({
    getSharedView: builder.query({
      queryFn: async (id: string) => {
        const { data, error } = await baseApi
          .from('shared_views')
          .select('*')
          .eq('id', id)
          .returns<SharedViewDTO[]>();

        if (!data) {
          Sentry.captureException(error);

          return { error: new Error(getErrorMessage(error)) };
        }

        return {
          data: {
            ...data[0].view,
            snapshot: {
              ...(data[0].view.snapshot as Object),
              filters: legacyFiltersValueAdapter(
                (
                  data[0].view.snapshot as {
                    filters: { id: string; value: unknown }[];
                  }
                ).filters,
              ),
            },
          },
        };
      },
    }),
    createSharedView: builder.mutation<string, SharedView>({
      queryFn: async (view) => {
        const { data, error } = await baseApi
          .from('shared_views')
          .insert([
            {
              view,
            },
          ])
          .select()
          .returns<[SharedViewDTO]>();

        if (!data) {
          Sentry.captureException(error);

          return { error: new Error(getErrorMessage(error)) };
        }

        return { data: data[0].id };
      },
    }),
  }),
});

export const {
  useGetSharedViewQuery,
  useCreateSharedViewMutation,
  middleware,
} = api;
