import { Box, SvgIcon, Tooltip } from '@mui/material';
import { LabeledSwitcher } from '@/shared/ui';

interface Props {
  value: boolean;
  onChange: (newValue: boolean) => void;
}

export function ProbabilisticAttributionSwitcher({ value, onChange }: Props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <LabeledSwitcher
        label="Probabilistic Attribution"
        value={value}
        onChange={onChange}
      />
      <Tooltip
        arrow
        title="Probabilistic attribution uses statistical modeling and machine learning to measure the effectiveness of marketing channels and campaigns at the cohort level without direct user identification. It analyzes anonymous data patterns to estimate the likelihood that specific touchpoints are associated with particular marketing channels or campaigns and their contribution to conversions."
      >
        <SvgIcon fontSize="small">
          <svg
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.58594 10.0007C2.58594 14.0968 5.90649 17.4173 10.0026 17.4173C14.0987 17.4173 17.4193 14.0968 17.4193 10.0007C17.4193 5.90454 14.0987 2.58398 10.0026 2.58398C5.90649 2.58398 2.58594 5.90454 2.58594 10.0007ZM15.9193 10.0007C15.9193 13.2683 13.2703 15.9173 10.0026 15.9173C6.73492 15.9173 4.08594 13.2683 4.08594 10.0007C4.08594 6.73297 6.73492 4.08398 10.0026 4.08398C13.2703 4.08398 15.9193 6.73297 15.9193 10.0007Z"
              fill="#7E828F"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.9193 10.0007C15.9193 13.2683 13.2703 15.9173 10.0026 15.9173C6.73492 15.9173 4.08594 13.2683 4.08594 10.0007C4.08594 6.73297 6.73492 4.08398 10.0026 4.08398C13.2703 4.08398 15.9193 6.73297 15.9193 10.0007ZM10.0026 8.33398C9.54237 8.33398 9.16927 8.70708 9.16927 9.16732V13.334C9.16927 13.7942 9.54237 14.1673 10.0026 14.1673C10.4628 14.1673 10.8359 13.7942 10.8359 13.334V9.16732C10.8359 8.70708 10.4628 8.33398 10.0026 8.33398ZM10.0026 5.83398C10.4628 5.83398 10.8359 6.20708 10.8359 6.66732C10.8359 7.12755 10.4628 7.50065 10.0026 7.50065C9.54237 7.50065 9.16927 7.12755 9.16927 6.66732C9.16927 6.20708 9.54237 5.83398 10.0026 5.83398Z"
              fill="#7E828F"
            />
          </svg>
        </SvgIcon>
      </Tooltip>
    </Box>
  );
}
