import { useCallback, useMemo } from 'react';
import { useDataFetcher, usePagedDataFetcher } from '@/shared/hooks';
import type { TotalData } from '@/shared/ui';
import type { Row } from '../../model/types';

interface DataTableData {
  loading: boolean;
  rows:
    | {
        [x: string]: string | number | boolean;
        id: number;
        isTotal: boolean;
      }[]
    | undefined;
  getNextPage: () => void;
}

interface Params {
  getRowsPage: (
    offset?: number,
  ) => Promise<{ rows: Row[]; hasNextPage: boolean }>;
  getTotal: () => Promise<TotalData>;
}

export const useDataTableData = ({
  getRowsPage,
  getTotal,
}: Params): DataTableData => {
  const { data: total, loading: totalLoading } = useDataFetcher(getTotal);
  const getPage = useCallback(
    (offset?: number) => {
      return getRowsPage(offset).then(({ rows, hasNextPage }) => {
        return {
          data: rows,
          hasNextPage,
        };
      });
    },
    [getRowsPage],
  );
  const { data: rows, loading, getNextPage } = usePagedDataFetcher({ getPage });
  const preparedRows = useMemo(() => {
    if (!total && !rows) {
      return undefined;
    }

    return [
      ...(total
        ? [
            {
              id: -1,
              isTotal: true,
              ...total,
            },
          ]
        : []),
      ...(rows || []).map((row, i) => {
        return {
          id: i,
          isTotal: false,
          ...row,
        };
      }),
    ];
  }, [rows, total]);

  return {
    loading: loading || totalLoading,
    rows: preparedRows,
    getNextPage,
  };
};
