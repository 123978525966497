import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Panel } from '@/shared/ui';
import { Logo } from '../Logo/Logo';
import { NavigationList } from '../NavigationList/NavigationList';

const NavigationPanel = styled('nav')(() => ({
  width: 64,
  position: 'relative',
}));

export function NavigationDrawer() {
  const [expanded, setExpanded] = useState(false);

  return (
    <NavigationPanel>
      <Panel
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          width: '100%',
          transition: 'width 0.2s ease-in-out',
          zIndex: 1301,
          boxShadow: '0px 2px 6px 0px rgba(50, 50, 71, 0.16)',

          '&:hover': {
            width: 280,
          },
        }}
        onMouseEnter={() => setExpanded(true)}
        onMouseLeave={() => setExpanded(false)}
      >
        <Logo expanded={expanded} />
        <NavigationList expanded={expanded} />
      </Panel>
    </NavigationPanel>
  );
}
