import OldVersionIcon from '@mui/icons-material/MilitaryTechRounded';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  listItemIconClasses,
  listItemTextClasses,
} from '@mui/material';
import type { ListItemButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '@/shared/constants';
import {
  CalendarChartIcon,
  CalendarInsightsIcon,
  CohortChartIcon,
  CohortMarketingPerformanceIcon,
  HomeIcon,
} from '@/shared/ui';

const List = styled('ul')(({ theme }) => ({
  padding: 0,
  margin: `${theme.spacing(1.5)} ${theme.spacing(1)} 0`,
  listStyle: 'none',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
}));
type ItemProps = ListItemButtonProps & {
  to: string;
  expanded: boolean;
  end?: boolean;
};
const Item = styled(({ to, expanded, ...props }: ItemProps) => {
  return <ListItemButton size="large" component={NavLink} to={to} {...props} />;
})<ItemProps>(({ theme, expanded }) => ({
  padding: theme.spacing(1.25),
  height: 48,
  display: 'flex',
  gap: theme.spacing(1.25),
  fontWeight: 500,
  fontSize: '0.875rem',
  borderRadius: theme.shape.borderRadius,

  [`& .${listItemIconClasses.root}`]: {
    minWidth: 0,
    width: 28,
    height: 28,
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${listItemTextClasses.root}`]: {
    opacity: 0,
    visibility: 'hidden',
  },

  ...(expanded && {
    [`& .${listItemTextClasses.root}`]: {
      opacity: 1,
      visibility: 'visible',
      transition: 'opacity 0.1s ease-in-out 0.2s',
    },
  }),

  '&.active': {
    backgroundColor: '#ECF0FE',

    [`& .${listItemIconClasses.root}`]: {
      color: '#063FFE',
    },
  },
}));

interface Props {
  expanded: boolean;
}

export function NavigationList({ expanded }: Props) {
  return (
    <List>
      <Item expanded={expanded} end to={getRedesignRoute(ROUTES.home)}>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText disableTypography primary="Home" />
      </Item>
      <Item
        expanded={expanded}
        to={getRedesignRoute(ROUTES.cohortMarketingPerformance)}
      >
        <ListItemIcon>
          <CohortMarketingPerformanceIcon />
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary="Cohort Marketing Performance"
        />
      </Item>
      <Item expanded={expanded} to={getRedesignRoute(ROUTES.calendarInsights)}>
        <ListItemIcon>
          <CalendarInsightsIcon />
        </ListItemIcon>
        <ListItemText disableTypography primary="Calendar Insights" />
      </Item>
      <Item expanded={expanded} to={getRedesignRoute(ROUTES.cohortChart)}>
        <ListItemIcon>
          <CohortChartIcon />
        </ListItemIcon>
        <ListItemText disableTypography primary="Cohort Chart" />
      </Item>
      <Item expanded={expanded} to={getRedesignRoute(ROUTES.calendarChart)}>
        <ListItemIcon>
          <CalendarChartIcon />
        </ListItemIcon>
        <ListItemText disableTypography primary="Calendar Chart" />
      </Item>
      <Item expanded={expanded} to={ROUTES.home}>
        <ListItemIcon>
          <OldVersionIcon />
        </ListItemIcon>
        <ListItemText disableTypography primary="Old version" />
      </Item>
    </List>
  );
}

const getRedesignRoute = (route: string): string => {
  return `${ROUTES.redesign}${route}`;
};
