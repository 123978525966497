import { Box } from '@mui/material';
import type { ReactNode } from 'react';
import { useRef } from 'react';
import { useDrop, useDrag } from 'react-dnd';

interface Props {
  children: ReactNode;
  id: string;
  index: number;
  acceptSymbol: string;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}

// taken from https://codesandbox.io/p/sandbox/peaceful-kate-gh8wll
export function DraggableItem({
  acceptSymbol,
  moveCard,
  index,
  id,
  children,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop<{ index: number; id: string }>({
    accept: acceptSymbol,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = (clientOffset?.y ?? 0) - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [, drag] = useDrag<{
    index: number;
    id: string;
    type: string;
  }>({
    type: acceptSymbol,
    item: { type: acceptSymbol, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <Box ref={ref} component="li" sx={{ borderRadius: 2 }}>
      {children}
    </Box>
  );
}
