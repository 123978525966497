import { LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import type {
  GridSlots,
  GridColumnOrderChangeParams,
  GridColumnResizeParams,
} from '@mui/x-data-grid-pro';
import {
  DataGridPro as MUIDataGridPro,
  gridClasses,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import type { FC } from 'react';
import { useEffect } from 'react';
import type { MetricColoring } from '@/features/DataGridColoring';
import type { ColumnsConfig } from '@/entities/columnsConfig';
import { Analytics } from '@/shared/lib';
import type { MinMaxData, TotalData } from '@/shared/ui';
import { useDataGridApiRefContext, useDataGridListeners } from '@/shared/ui';
import type { Row } from '../../model/types';
import {
  DataTableContext,
  useDataTableContext,
} from '../DataTableContext/DataTableContext';
import { useDataTableData } from './useDataTableData';
import { useDataTableProps } from './useDataTableProps';

const Wrapper = styled('div')(() => ({
  flexGrow: 1,
  position: 'relative',
}));
const Container = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
}));
const DataGrid = styled(MUIDataGridPro)(({ theme }) => ({
  '& .MuiDataGrid-main': {
    '--DataGrid-containerBackground': '#F1F3F8',
    '--DataGrid-headersTotalHeight': '56px',
    '--DataGrid-topContainerHeight': '56px',
  },

  '& .dimensionCell + .metricCell, & .dimensionHeader + .metricHeader': {
    boxShadow: 'inset 8px 0 8px -8px #00234033',
  },

  [`.${gridClasses['row--detailPanelExpanded']}`]: {
    '& .mediaCell': {
      overflow: 'visible',
    },
  },

  '& .MuiDataGrid-columnHeaderTitleContainerContent': {
    flexGrow: 1,
    justifyContent: 'space-between',
  },

  '& .MuiDataGrid-cell': {
    padding: 0,
    borderRight: '1px solid transparent',
    overflow: 'visible',
  },

  '& .dimensionCell': {
    padding: `0 ${theme.spacing(1.25)}`,
    flexShrink: 0,
  },

  '& .total': {
    backgroundColor: '#E8F3FF',
    fontWeight: 500,

    '& .MuiDataGrid-cell': {
      padding: `0 ${theme.spacing(1.25)}`,
    },
  },

  '& .columnGroup': {
    display: 'none',
  },
}));

interface Props {
  columnsConfig: ColumnsConfig;
  dimensions: string[];
  metrics: string[];
  rowHeight: number;
  columnsWidth: Record<string, number | undefined>;
  minMax?: MinMaxData;
  metricsColoring: MetricColoring[];
  getRowsPage: (
    offset?: number,
  ) => Promise<{ rows: Row[]; hasNextPage: boolean }>;
  getTotal: () => Promise<TotalData>;
  reorderDimensions: (info: {
    id: string;
    oldIndex: number;
    newIndex: number;
  }) => void;
  reorderMetrics: (info: {
    id: string;
    oldIndex: number;
    newIndex: number;
  }) => void;
  updateColumnsWidth: (columnName: string, newWidth?: number) => void;
  DimensionHeaderComponent: Parameters<
    typeof useDataTableProps
  >[0]['DimensionHeaderComponent'];
  MetricHeaderComponent: Parameters<
    typeof useDataTableProps
  >[0]['MetricHeaderComponent'];
}

const Content: FC<Props> = ({
  minMax,
  metricsColoring,
  columnsConfig,
  dimensions,
  metrics,
  rowHeight,
  columnsWidth,
  getRowsPage,
  getTotal,
  reorderDimensions,
  reorderMetrics,
  updateColumnsWidth,
  DimensionHeaderComponent,
  MetricHeaderComponent,
}) => {
  const apiRef = useGridApiRef();
  const { setApiRef } = useDataGridApiRefContext();
  const {
    initialState,
    detailPanelExpandedRowIds,
    getDetailPanelHeight,
    getDetailPanelContent,
  } = useDataTableContext();
  const { rows, loading, getNextPage } = useDataTableData({
    getTotal,
    getRowsPage,
  });
  const { columnGroupingModel, columns } = useDataTableProps({
    columnsWidth,
    columnsConfig,
    dimensions,
    metrics,
    minMax,
    metricsColoring,
    DimensionHeaderComponent,
    MetricHeaderComponent,
  });

  useDataGridListeners();

  useEffect(() => {
    apiRef.current.unstable_setColumnVirtualization(false);
    setApiRef(apiRef.current);
  }, [apiRef, setApiRef]);

  const handleColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const { column } = params;
    // Need to substract 1 from indexes due to hidden row details column
    const oldIndex = params.oldIndex - 1;
    const targetIndex = params.targetIndex - 1;

    if (oldIndex === targetIndex) {
      return;
    }

    if (oldIndex < dimensions.length) {
      reorderDimensions({
        id: column.field,
        oldIndex: oldIndex,
        newIndex: targetIndex,
      });
    } else {
      reorderMetrics({
        id: column.field,
        oldIndex: oldIndex - dimensions.length,
        newIndex: targetIndex - dimensions.length,
      });
    }
    Analytics.sendFeatureUsage('data_table', 'reorder_column', {
      page: window.location.pathname,
    });
  };
  const handleColumnWidthChange = (params: GridColumnResizeParams) => {
    const { width, field } = params.colDef;

    updateColumnsWidth(field, width);
  };

  return (
    <Wrapper>
      <Container>
        <DataGrid
          hideFooter
          disableColumnMenu
          disableColumnSorting
          apiRef={apiRef}
          initialState={initialState}
          loading={loading}
          detailPanelExpandedRowIds={detailPanelExpandedRowIds}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
          columnGroupingModel={columnGroupingModel}
          getRowHeight={() => rowHeight}
          slots={{
            loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
          }}
          columns={columns}
          rows={rows}
          getRowClassName={(params) => {
            return params.row.isTotal ? 'total' : '';
          }}
          onRowsScrollEnd={getNextPage}
          onColumnOrderChange={handleColumnOrderChange}
          onColumnWidthChange={handleColumnWidthChange}
        />
      </Container>
    </Wrapper>
  );
};

export const DataTable: FC<Props> = (props) => {
  return (
    <DataTableContext pageFetcher={props.getRowsPage}>
      <Content {...props} />
    </DataTableContext>
  );
};
