import { TextField } from '@mui/material';
import type { ChangeEvent } from 'react';
import { forwardRef } from 'react';
import type { NumericFormatProps } from 'react-number-format';
import { NumericFormat } from 'react-number-format';
import type { InputComponentProps } from '../../model/types';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  step: number;
  name: string;
  min?: number;
  max?: number;
  currencySymbol: string;
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, step, currencySymbol, ...other } = props;

    return (
      <NumericFormat
        {...other}
        {...(step < 1
          ? { fixedDecimalScale: true, decimalScale: 2 }
          : { decimalScale: 0 })}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        prefix={currencySymbol}
      />
    );
  },
);

export function CurrencyInput({
  textFieldProps,
  currency,
  allowEmpty,
  disabled,
  label,
  placeholder,
  step,
  min,
  max,
  value,
  onChange,
  onBlur,
}: InputComponentProps & { currency: string }) {
  const {
    InputProps,
    inputProps,
    onChange: onChangeTextField,
    ...otherProps
  } = textFieldProps ?? {};
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (allowEmpty && event.target.value === '') {
      onChange?.(undefined);

      return;
    }

    const numberValue = +event.target.value;

    onChangeTextField?.(event);
    onChange?.(isNaN(numberValue) ? 0 : numberValue);
  };

  return (
    <TextField
      size="small"
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      InputProps={{
        inputComponent: NumericFormatCustom as any,
        ...InputProps,
      }}
      variant="outlined"
      inputProps={{
        step,
        min,
        max,
        currencySymbol: getCurrencySymbol(currency),
        ...inputProps,
      }}
      value={value}
      onBlur={onBlur}
      onChange={handleChange}
      {...otherProps}
    />
  );
}

const getCurrencySymbol = (currency: string) => {
  return (0)
    .toLocaleString('en-US', {
      style: 'currency',
      currency: currency || 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
};
