import ClearIcon from '@mui/icons-material/Clear';
import { Box, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { MetricDataFormatType } from '@/entities/columnsConfig';
import { toFormatType } from '@/entities/columnsConfig';
import { selectCurrency } from '@/entities/session';
import type { DataType } from '@/shared/hooks';
import { useNumberFormatter } from '@/shared/hooks';
import { useAppSelector } from '@/shared/model';
import type { ColorLevel, MetricColoring } from '../../model/types';
import { LevelsDisplay } from '../LevelsDisplay/LevelsDisplay';
import { LevelsList } from '../LevelsList/LevelsList';

const Container = styled('li')(({ theme }) => ({
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
}));

interface Props {
  metricConfig: {
    name: string;
    type: DataType;
    dataType: MetricDataFormatType;
  };
  min?: number;
  max?: number;
  value: MetricColoring;
  onChange: (v: MetricColoring) => void;
  onDelete: (v: string) => void;
}

export function MetricColoringEditor({
  metricConfig,
  min,
  max,
  value,
  onChange,
  onDelete,
}: Props) {
  const userCurrency = useAppSelector(selectCurrency);
  const formattersMap = useNumberFormatter(userCurrency);

  const handleDelete = () => {
    onDelete(value.metric);
  };
  const handleLevelsChange = (newLevels: ColorLevel[]) => {
    onChange({
      ...value,
      levels: newLevels,
    });
  };

  const formatType = toFormatType(metricConfig.dataType);

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 2,
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {metricConfig.name}
        </Typography>
        <IconButton size="small" onClick={handleDelete}>
          <ClearIcon />
        </IconButton>
      </Box>
      <LevelsDisplay
        min={min}
        max={max}
        levels={value.levels}
        formatter={formattersMap[metricConfig.type][formatType]}
      />
      <LevelsList
        type={metricConfig.type}
        formatType={formatType}
        value={value.levels}
        onChange={handleLevelsChange}
      />
    </Container>
  );
}
