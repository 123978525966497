import type { SxProps } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { DraggableItem } from '../DraggableItem/DraggableItem';

const List = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

interface Props<T extends { id: string }> {
  sx?: SxProps;
  items: T[];
  renderItem: (item: T) => React.ReactNode;
  onChange: (items: T[]) => void;
}

export function DraggableList<T extends { id: string }>({
  sx,
  items,
  renderItem,
  onChange,
}: Props<T>) {
  const [acceptSymbol] = useState(`draggableItem${Math.random()}`);

  const moveCard = (dragIndex: number, hoverIndex: number) => {
    const newItems = [...items];
    const [removed] = newItems.splice(dragIndex, 1);
    newItems.splice(hoverIndex, 0, removed);
    onChange(newItems);
  };

  return (
    <List sx={sx}>
      {items.map((item, index) => (
        <DraggableItem
          key={index}
          acceptSymbol={acceptSymbol}
          index={index}
          id={item.id}
          moveCard={moveCard}
        >
          {renderItem(item)}
        </DraggableItem>
      ))}
    </List>
  );
}
