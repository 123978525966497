import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button as SharedButton } from '@/shared/ui';
import { getMediaFilterOptionValue } from '../../libs/getMediaFilterOptionValue';
import {
  FilterSelectionControlsProvider,
  useFilterSelectionControlsContext,
} from '../FilterSelectionControlsContext/FilterSelectionControlsContext';
import { MediaFilter } from '../MediaFilter/MediaFilter';
import type { Value } from '../MediaFilter/MediaFilter';

const Button = styled(SharedButton)(() => ({
  padding: '0 14px',
  lineHeight: '1.375rem',
}));

interface Props {
  id: string;
  value?: Value;
  onChange: (v: Value) => void;
}

function Content({ id, value, onChange }: Props) {
  const {
    getSelectAllValue,
    getClearAllValue,
    isClearAllDisabled,
    isSelectAllDisabled,
  } = useFilterSelectionControlsContext();

  const handleClearAll = () => {
    if (!value) {
      return;
    }

    onChange({
      type: value.type,
      options: getClearAllValue(),
    });
  };
  const handleSelectAll = () => {
    if (!value) {
      return;
    }

    onChange({
      type: value.type,
      options: getSelectAllValue(),
    });
  };

  return (
    <>
      <Box
        sx={{
          mb: 1.5,
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 1,
        }}
      >
        <Button
          color="secondary"
          variant="text"
          size="small"
          disabled={!value || isClearAllDisabled(value.options)}
          onClick={handleClearAll}
        >
          Clear all
        </Button>
        <Button
          color="secondary"
          variant="outlined"
          size="small"
          disabled={!value || isSelectAllDisabled(value.options)}
          onClick={handleSelectAll}
        >
          Select all
        </Button>
      </Box>
      <MediaFilter id={id} value={value} onChange={onChange} />
    </>
  );
}

export function DrawerMediaFilter(props: Props) {
  return (
    <FilterSelectionControlsProvider
      id={props.id}
      value={props.value?.options || []}
      getOptionValue={getMediaFilterOptionValue}
    >
      <Content {...props} />
    </FilterSelectionControlsProvider>
  );
}
