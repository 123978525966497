import { useCallback, useEffect } from 'react';
import type { DataConfig } from '@/widgets/calendarInsights';
import {
  dataConfigValidator,
  selectMetaIsLoaded,
  updateConfig,
} from '@/widgets/calendarInsights';
import { ImportSharedView } from '@/entities/ShareView';
import { ROUTES } from '@/shared/constants';
import { Analytics } from '@/shared/lib';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { useViewInitializationStateMachineContext } from '@/shared/ui';

export function ImportSharedViewDialog() {
  const dispatch = useAppDispatch();
  const isColumnsMetaLoaded = useAppSelector(selectMetaIsLoaded);
  const { onNavigatedViewDetect, onNavigatedViewApply, onNavigatedViewError } =
    useViewInitializationStateMachineContext();

  useEffect(() => {
    onNavigatedViewDetect();
  }, [onNavigatedViewDetect]);

  const handleApplyView = useCallback(
    (view: unknown) => {
      onNavigatedViewApply();
      dispatch(updateConfig(view as DataConfig));
      Analytics.sendFeatureUsage('share_view', 'apply', {
        page: ROUTES.calendarInsights,
      });
    },
    [dispatch, onNavigatedViewApply],
  );
  const isViewValid = useCallback((view: unknown) => {
    const parseResult = dataConfigValidator.safeParse(view);

    return parseResult.success;
  }, []);

  if (!isColumnsMetaLoaded) {
    return null;
  }

  return (
    <ImportSharedView
      isViewValid={isViewValid}
      applyView={handleApplyView}
      onViewError={onNavigatedViewError}
    />
  );
}
