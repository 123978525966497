import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { selectProducts } from '@/entities/products';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { Loader } from '@/shared/ui';
import { addWebLink } from '../../model/slice';
import { ProductSelect } from '../ProductSelect/ProductSelect';
import { URLInput } from '../URLInput/URLInput';
import { WebLinkTypeSelect } from '../WebLinkTypeSelect/WebLinkTypeSelect';
import { useAddWebLinkFields } from './useAddWebLinkFields';

interface Props {
  onClose: () => void;
}

export function AddWebLinkRow({ onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const [processing, setProcessing] = useState(false);
  const products = useAppSelector(selectProducts);
  const { urlInputProps, productIdProps, webLinkTypeProps } =
    useAddWebLinkFields();
  const hasError = !urlInputProps.value || !productIdProps.value;

  const handleCreate = () => {
    if (hasError) {
      return;
    }

    setProcessing(true);
    dispatch(
      addWebLink({
        url: urlInputProps.value,
        productId: productIdProps.value,
        productName: products.find((p) => p.id === productIdProps.value)?.name!,
        type: webLinkTypeProps.value,
      }),
    )
      .then(onClose)
      .catch(() => {
        enqueueSnackbar('Unable to add web link. Please, try again.', {
          variant: 'error',
          autoHideDuration: 4000,
        });
        setProcessing(false);
      });
  };

  return (
    <TableRow sx={{ position: 'relative' }}>
      <TableCell sx={{ verticalAlign: 'top' }}>
        <URLInput {...urlInputProps} />
      </TableCell>
      <TableCell sx={{ verticalAlign: 'top' }}>
        <ProductSelect {...productIdProps} />
      </TableCell>
      <TableCell sx={{ verticalAlign: 'top' }}>
        <WebLinkTypeSelect {...webLinkTypeProps} />
      </TableCell>
      <TableCell sx={{ px: 0, verticalAlign: 'top' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Tooltip
              title={
                hasError ? 'To create web link, fill correct in all fields' : ''
              }
            >
              <Box>
                <IconButton
                  sx={{
                    my: 0.625,
                    color: (theme) => theme.palette.success.main,
                  }}
                  size="small"
                  disabled={hasError}
                  onClick={handleCreate}
                >
                  <CheckIcon fontSize="small" />
                </IconButton>
              </Box>
            </Tooltip>
            <IconButton sx={{ my: 0.625 }} size="small" onClick={onClose}>
              <CloseIcon fontSize="small" color="error" />
            </IconButton>
          </Box>
        </Box>
        <Loader active={processing} />
      </TableCell>
    </TableRow>
  );
}
