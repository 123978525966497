import CloseIcon from '@mui/icons-material/CloseRounded';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Radio,
  RadioGroup as MUIRadioGroup,
  Typography,
  FormControlLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { Button as SharedButton } from '@/shared/ui';
import type { SavedViewRecord } from '../../model/types';
import { useSavedViewsContext } from '../SavedViewsContext/SavedViewsContext';
import { CreatePreset } from './CreatePreset';
import { UpdatePreset } from './UpdatePreset';

const Button = styled(SharedButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
}));
const RadioGroup = styled(MUIRadioGroup)(() => ({
  fontSize: '0.875rem',
  lineHeight: '1rem',
  letterSpacing: 0,

  '& .MuiFormControlLabel-root': {
    marginLeft: -6,
  },
}));

interface ContextState {
  openSavePresetDialog: () => void;
}

const Context = createContext<ContextState | null>(null);

enum ActionType {
  create = 'create',
  update = 'update',
}

interface Props {
  children: ReactNode;
}

export function SavePresetProvider({ children }: Props) {
  const { views, updateView, createView } = useSavedViewsContext();
  const [actionType, setActionType] = useState<ActionType>(ActionType.create);
  const [viewToUpdate, setViewToUpdate] = useState<SavedViewRecord>();
  const [viewName, setViewName] = useState<string>('');
  const [viewNameError, setViewNameError] = useState<string>('');
  const [openSavePresetDialog, setOpenSavePresetDialog] = useState(false);

  useEffect(() => {
    setViewToUpdate(views[0]);
  }, [views]);

  const handleOpenSavePresetDialog = () => {
    setOpenSavePresetDialog(true);
  };
  const handleCloseSavePresetDialog = () => {
    setOpenSavePresetDialog(false);
  };
  const handleSave = () => {
    if (actionType === ActionType.create) {
      if (!viewName) {
        setViewNameError('Preset name is required');
        return;
      }

      createView(viewName).then(() => {
        handleCloseSavePresetDialog();
      });
    } else {
      updateView(viewToUpdate!.uuid);
      handleCloseSavePresetDialog();
    }
  };

  return (
    <Context.Provider
      value={{
        openSavePresetDialog: handleOpenSavePresetDialog,
      }}
    >
      {children}
      <Dialog
        PaperProps={{
          sx: {
            borderRadius: 4,
            width: 370,
          },
        }}
        open={openSavePresetDialog}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2,
            p: 2,
          }}
        >
          <DialogTitle
            sx={{
              p: 0,
              fontWeight: 500,
              fontSize: '1.5rem',
              lineHeight: '1.75rem',
              letterSpacing: 0,
            }}
          >
            Save Preset
          </DialogTitle>
          <IconButton size="small" onClick={handleCloseSavePresetDialog}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent dividers sx={{ p: 2 }}>
          {views.length > 0 && (
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}
            >
              <Typography
                sx={{
                  fontSize: '0.875rem',
                  lineHeight: '1rem',
                  letterSpacing: 0,
                  color: '#7E828F',
                }}
              >
                Please choose how you would like to save this view:
              </Typography>
              <RadioGroup
                row
                value={actionType}
                onChange={(e) => setActionType(e.target.value as ActionType)}
              >
                <FormControlLabel
                  disableTypography
                  value={ActionType.create}
                  control={<Radio size="small" sx={{ p: 0.25 }} />}
                  label="Create New"
                />
                <FormControlLabel
                  disableTypography
                  value={ActionType.update}
                  control={<Radio size="small" sx={{ p: 0.25 }} />}
                  label="Update Existing Preset"
                />
              </RadioGroup>
            </Box>
          )}
          {actionType === ActionType.create && (
            <CreatePreset
              value={viewName}
              error={viewNameError}
              onChange={setViewName}
            />
          )}
          {actionType === ActionType.update && (
            <UpdatePreset
              views={views}
              value={viewToUpdate}
              onChange={setViewToUpdate}
            />
          )}
        </DialogContent>
        <DialogActions
          disableSpacing
          sx={{ justifyContent: 'flex-start', gap: 2, p: 2 }}
        >
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCloseSavePresetDialog}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Context.Provider>
  );
}

export const useSavePresetContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error(
      'useSavePresetContext must be used within a SavePresetProvider',
    );
  }

  return context;
};
