import type {
  ColumnsConfig,
  MetricDataFormatType,
} from '@/entities/columnsConfig';
import type { DataType } from '@/shared/hooks';
import type { MetricColoring } from '../model/types';

export const getMetricConfigMap = (
  columnsConfig: ColumnsConfig,
  metricsColoring: MetricColoring[],
): Record<
  string,
  { name: string; type: DataType; dataType: MetricDataFormatType }
> => {
  return Object.fromEntries(
    metricsColoring.map((metricColoring) => {
      return [
        metricColoring.metric,
        {
          name: columnsConfig[metricColoring.metric].name,
          type: columnsConfig[metricColoring.metric]
            .type as unknown as DataType,
          dataType: columnsConfig[metricColoring.metric]
            .dataType as MetricDataFormatType,
        },
      ];
    }),
  );
};
