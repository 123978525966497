import type { ReactNode } from 'react';
import { useCallback } from 'react';
import type { MetricDataFormatType } from '@/entities/columnsConfig';
import { toFormatType } from '@/entities/columnsConfig';
import { selectCurrency } from '@/entities/session';
import type { DataType } from '@/shared/hooks';
import { useNumberFormatter } from '@/shared/hooks';
import { isError, isLoading } from '@/shared/lib';
import { useAppSelector } from '@/shared/model';
import { isAllValuesSelected } from '../../model/isAllValuesSelected';
import {
  isMediaFilter,
  isNumberFilter,
  isStringFilter,
} from '../../model/predicates';
import type { FilterConfig, FilterValue } from '../../model/types';
import { FilterNext } from '../FilterNext/FilterNext';
import { useFiltersParamsContext } from '../FiltersParamsContext/FiltersParamsContext';
import type { Value as MediaFilterValue } from '../MediaFilter/MediaFilter';
import { MediaFilterNext } from '../MediaFilterNext/MediaFilterNext';
import {
  NumberFilter,
  NumberFilterIntervalType,
} from '../NumberFilter/NumberFilter';
import type { Value as NumberFilterValue } from '../NumberFilter/NumberFilter';
import type { Value as StringFilterValue } from '../StringFilter/StringFilter';
import { StringFilterNext } from '../StringFilterNext/StringFilterNext';
import { StringFilterSelectionType } from '../StringFilterSelectionTypeSwitcher/StringFilterSelectionTypeSwitcher';

interface Props {
  slotProps?: Parameters<typeof FilterNext>[0]['slotProps'];
  filterConfig: FilterConfig;
  value?: FilterValue;
  actions?: ReactNode;
  onChange: (newValue?: FilterValue) => void;
  onDelete?: () => void;
}

export function FilterItem({
  slotProps,
  filterConfig,
  value,
  actions,
  onChange,
  onDelete,
}: Props) {
  const {
    paramsData,
    requestMediaFilterParams,
    requestNumberFilterParams,
    requestStringFilterParams,
  } = useFiltersParamsContext();
  const userCurrency = useAppSelector(selectCurrency);
  const formattersMap = useNumberFormatter(userCurrency);

  const handleErrorRetry = useCallback(
    (id: string, handler: (id: string) => void) => () => {
      handler(id);
    },
    [],
  );

  const active =
    !!value &&
    !isAllValuesSelected(
      filterConfig.type,
      value,
      paramsData[filterConfig.id]?.params,
    );
  const loading =
    !paramsData[filterConfig.id] ||
    isLoading(paramsData[filterConfig.id].status);
  const error =
    paramsData[filterConfig.id] && isError(paramsData[filterConfig.id].status);

  if (isNumberFilter(filterConfig.type)) {
    const formatter =
      formattersMap[filterConfig.type as DataType][
        toFormatType(filterConfig.dataType as MetricDataFormatType)
      ];
    const valueLabel = active
      ? getNumberFilterValueLabel(value as NumberFilterValue, formatter)
      : '';

    return (
      <FilterNext
        slotProps={slotProps}
        loading={loading}
        name={filterConfig.name}
        valueLabel={valueLabel}
        error={error}
        active={active}
        actions={actions}
        onErrorRetry={handleErrorRetry(
          filterConfig.id,
          requestNumberFilterParams,
        )}
        onDelete={onDelete}
      >
        <NumberFilter
          sx={{
            p: 2,
            gap: 2,
          }}
          id={filterConfig.id}
          type={filterConfig.type as DataType}
          formatType={toFormatType(
            filterConfig.dataType as MetricDataFormatType,
          )}
          value={value as NumberFilterValue}
          onChange={onChange}
        />
      </FilterNext>
    );
  } else if (isStringFilter(filterConfig.type)) {
    const typedValue = value as StringFilterValue | undefined;
    const valueLabel = active ? getStringFilterValueLabel(typedValue!) : '';

    return (
      <FilterNext
        slotProps={slotProps}
        active={active}
        loading={loading}
        error={error}
        name={filterConfig.name}
        valueLabel={valueLabel}
        actions={actions}
        onErrorRetry={handleErrorRetry(
          filterConfig.id,
          requestStringFilterParams,
        )}
        onDelete={onDelete}
      >
        <StringFilterNext
          id={filterConfig.id}
          value={typedValue}
          onChange={onChange}
        />
      </FilterNext>
    );
  } else if (isMediaFilter(filterConfig.type)) {
    const typedValue = value as MediaFilterValue | undefined;
    const valueLabel = active ? getStringFilterValueLabel(typedValue!) : '';

    return (
      <FilterNext
        slotProps={slotProps}
        active={active}
        loading={loading}
        error={error}
        name={filterConfig.name}
        valueLabel={valueLabel}
        actions={actions}
        onErrorRetry={handleErrorRetry(
          filterConfig.id,
          requestMediaFilterParams,
        )}
        onDelete={onDelete}
      >
        <MediaFilterNext
          id={filterConfig.id}
          value={typedValue}
          onChange={onChange}
        />
      </FilterNext>
    );
  }

  return <p>Not implemented filter type: {filterConfig.type}</p>;
}

const getStringFilterValueLabel = (
  value: StringFilterValue | MediaFilterValue,
) => {
  return `${value.options.length} ${value.type === StringFilterSelectionType.INCLUDE ? 'selected' : 'excluded'}`;
};

const getNumberFilterValueLabel = (
  value: NumberFilterValue,
  formatter: (v: number) => string,
) => {
  if (value.type === NumberFilterIntervalType.CLOSED) {
    return `${formatter(value.value[0])} - ${formatter(value.value[1])}`;
  } else if (value.type === NumberFilterIntervalType.OPEN_POSITIVE) {
    return `from ${formatter(value.value)}`;
  } else if (value.type === NumberFilterIntervalType.OPEN_NEGATIVE) {
    return `to ${formatter(value.value)}`;
  }
};
