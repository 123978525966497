import { useEffect } from 'react';
import { Analytics } from '@/shared/lib';
import { useDataGridApiRefContext } from '@/shared/ui';

export const useDataGridListeners = () => {
  const { apiRef } = useDataGridApiRefContext();

  useEffect(() => {
    if (!apiRef) {
      return;
    }

    const unsubscribe = apiRef.subscribeEvent('columnResizeStop', () => {
      Analytics.sendFeatureUsage('data_table', 'resize_column');
    });

    return unsubscribe;
  }, [apiRef]);
};
