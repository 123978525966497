import { Lifetime } from '@/shared/types';
import { Select } from '@/shared/ui';

const ITEMS: Record<Lifetime, string> = {
  [Lifetime.THREE_MONTHS]: '3 months',
  [Lifetime.SIX_MONTHS]: '6 months',
  [Lifetime.YEAR]: '1 year',
};

interface Props {
  value: Lifetime;
  onChange: (v: Lifetime) => void;
}

export function LifetimeSelectNext({ value, onChange }: Props) {
  const handleChange = (newValue: string) => {
    onChange(+newValue as Lifetime);
  };

  return (
    <Select
      label="Forecasting Horizon"
      options={ITEMS}
      value={`${value}`}
      onChange={handleChange}
    />
  );
}
