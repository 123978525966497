import { Box } from '@mui/material';
import type { DataGridProProps, GridInitialState } from '@mui/x-data-grid-pro';
import { GRID_DETAIL_PANEL_TOGGLE_FIELD } from '@mui/x-data-grid-pro';
import type { ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ROW_DETAILS_HEIGHT } from '@/shared/ui';

interface ContextState {
  initialState: GridInitialState;
  detailPanelExpandedRowIds: DataGridProProps['detailPanelExpandedRowIds'];
  getDetailPanelHeight: DataGridProProps['getDetailPanelHeight'];
  getDetailPanelContent: DataGridProProps['getDetailPanelContent'];
  toggleRowDetails: (id: number) => void;
}

const Context = createContext<ContextState | null>(null);

interface Props {
  children: ReactNode;
  pageFetcher: Function;
}

export function DataTableContext({ children, pageFetcher }: Props) {
  const [rowWithDetails, setRowWithDetails] = useState<number[]>([]);

  useEffect(() => {
    setRowWithDetails([]);
  }, [pageFetcher]);

  const getDetailPanelHeight = useCallback(() => {
    return ROW_DETAILS_HEIGHT;
  }, []);
  const getDetailPanelContent = useCallback(() => {
    return <Box sx={{ height: ROW_DETAILS_HEIGHT }} />;
  }, []);

  return (
    <Context.Provider
      value={{
        initialState: {
          columns: {
            columnVisibilityModel: {
              [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
            },
          },
        },
        detailPanelExpandedRowIds: rowWithDetails,
        getDetailPanelHeight,
        getDetailPanelContent,
        toggleRowDetails(id) {
          if (id === rowWithDetails[0]) {
            setRowWithDetails([]);
          } else {
            setRowWithDetails([id]);
          }
        },
      }}
    >
      {children}
    </Context.Provider>
  );
}

export const useDataTableContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error(
      'useDataTableContext hook used outside <DataTableContext />',
    );
  }

  return context;
};
