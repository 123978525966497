import { Box, Divider, Typography, buttonClasses } from '@mui/material';
import { useMemo, useState } from 'react';
import type { ColumnsConfig } from '@/entities/columnsConfig';
import { Button, Dropdown, SearchInput, ToggleButtonGroup } from '@/shared/ui';
import { useColumnsInfo } from '../../model/useColumnsInfo';
import { AllColumnsList } from '../AllColumnsList/AllColumnsList';
import { ColumnsListNext } from '../ColumnsListNext/ColumnsListNext';

enum GroupType {
  all = 'all',
  dimensions = 'dimensions',
  metrics = 'metrics',
}

interface Props {
  className?: string;
  isColumnsConfigLoaded: boolean;
  selectedDimensions: string[];
  selectedMetrics: string[];
  allMetrics: string[];
  allDimensions: string[];
  columnsConfig: ColumnsConfig;
  updateColumns: (newColumns: {
    dimensions: string[];
    metrics: string[];
  }) => void;
}

export function AddColumnsNext({
  className,
  columnsConfig,
  selectedDimensions,
  selectedMetrics,
  allMetrics,
  allDimensions,
  isColumnsConfigLoaded,
  updateColumns,
}: Props) {
  const { dimensionsColumns, metricsColumns } = useColumnsInfo({
    columnsConfig,
    allDimensions,
    allMetrics,
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [checkedDimensions, setCheckedDimensions] = useState<string[]>([]);
  const [checkedMetrics, setCheckedMetrics] = useState<string[]>([]);
  const [activeGroup, setActiveGroup] = useState<GroupType>(GroupType.all);
  const options = useMemo(() => {
    return [
      {
        label: 'All',
        value: GroupType.all,
      },
      {
        label: (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              whiteSpace: 'nowrap',
            }}
          >
            Dimensions{' '}
            {checkedDimensions.length > 0 && (
              <Box
                sx={{
                  fontSize: '0.625rem',
                  fontWeight: 500,
                  lineHeight: '0.75rem',
                  backgroundColor: 'primary.main',
                  color: 'white',
                  px: 0.5,
                  py: 0.25,
                  borderRadius: 6,
                  minWidth: 16,
                }}
              >
                {checkedDimensions.length}
              </Box>
            )}
          </Box>
        ),
        value: GroupType.dimensions,
      },
      {
        label: (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              whiteSpace: 'nowrap',
            }}
          >
            Metrics{' '}
            {checkedMetrics.length > 0 && (
              <Box
                sx={{
                  fontSize: '0.625rem',
                  fontWeight: 500,
                  lineHeight: '0.75rem',
                  backgroundColor: 'primary.main',
                  color: 'white',
                  px: 0.5,
                  py: 0.25,
                  borderRadius: 6,
                  minWidth: 16,
                }}
              >
                {checkedMetrics.length}
              </Box>
            )}
          </Box>
        ),
        value: GroupType.metrics,
      },
    ];
  }, [checkedDimensions.length, checkedMetrics.length]);
  const activeGroupCount = useMemo(() => {
    if (activeGroup === GroupType.all) {
      return checkedDimensions.length + checkedMetrics.length;
    }

    return activeGroup === GroupType.dimensions
      ? checkedDimensions.length
      : checkedMetrics.length;
  }, [activeGroup, checkedDimensions.length, checkedMetrics.length]);
  const activeGroupTotalCount = useMemo(() => {
    if (activeGroup === GroupType.all) {
      return allDimensions.length + allMetrics.length;
    }

    return activeGroup === GroupType.dimensions
      ? allDimensions.length
      : allMetrics.length;
  }, [activeGroup, allDimensions.length, allMetrics.length]);
  const [open, setOpen] = useState(false);
  const filteredDimensionsColumns = useMemo(() => {
    return dimensionsColumns.filter((column) => {
      return column.value.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }, [dimensionsColumns, searchQuery]);
  const filteredMetricsColumns = useMemo(() => {
    return metricsColumns.filter((column) => {
      return column.value.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }, [metricsColumns, searchQuery]);

  if (!isColumnsConfigLoaded) {
    return null;
  }

  const handleOpen = (newOpen: boolean) => {
    if (newOpen) {
      setCheckedDimensions(selectedDimensions);
      setCheckedMetrics(selectedMetrics);
    }

    setActiveGroup(GroupType.all);
    setOpen(newOpen);
  };
  const handleClearAll = () => {
    if (activeGroup === GroupType.all) {
      setCheckedDimensions([]);
      setCheckedMetrics([]);
    } else if (activeGroup === GroupType.dimensions) {
      setCheckedDimensions([]);
    } else {
      setCheckedMetrics([]);
    }
  };
  const handleApply = () => {
    updateColumns({
      dimensions: checkedDimensions,
      metrics: checkedMetrics,
    });
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const handlePanelClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <Dropdown
      labelProps={{
        className: className,
        color: 'primary',
        variant: 'contained',
        sx: {
          px: 1.5,
          py: 0.625,
          borderRadius: 2,

          [`& .${buttonClasses.endIcon}`]: {
            display: 'none',
          },
        },
      }}
      paperProps={{
        sx: {
          width: 304,
          borderRadius: 2,
        },
      }}
      label="Add Columns"
      open={open}
      onOpenChange={handleOpen}
    >
      <Box onClick={handlePanelClick}>
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <ToggleButtonGroup
            fullWidth
            options={options}
            value={activeGroup}
            onChange={setActiveGroup}
          />
          <SearchInput query={searchQuery} onChange={setSearchQuery} />
        </Box>
        <Divider />
        <Box
          sx={{
            px: 2,
            py: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            component="span"
            variant="modalContent"
            sx={{ fontWeight: 500, color: '#7E828F' }}
          >
            Selected:{' '}
            <Box
              component="span"
              sx={{ color: (theme) => theme.palette.primary.main }}
            >
              {activeGroupCount}
            </Box>{' '}
            of {activeGroupTotalCount}
          </Typography>
          <Button
            sx={{ py: 0, lineHeight: '1.375rem', color: 'black' }}
            variant="text"
            color="secondary"
            onClick={handleClearAll}
          >
            Clear all
          </Button>
        </Box>
        <Divider />
        {activeGroup === GroupType.all && (
          <AllColumnsList
            dimensionsColumns={filteredDimensionsColumns}
            metricsColumns={filteredMetricsColumns}
            selectedDimensions={checkedDimensions}
            selectedMetrics={checkedMetrics}
            onSelectedDimensionsChange={setCheckedDimensions}
            onSelectedMetricsChange={setCheckedMetrics}
          />
        )}
        {activeGroup === GroupType.dimensions && (
          <ColumnsListNext
            columns={filteredDimensionsColumns}
            selectedColumns={checkedDimensions}
            onSelectedColumnsChange={setCheckedDimensions}
          />
        )}
        {activeGroup === GroupType.metrics && (
          <ColumnsListNext
            columns={filteredMetricsColumns}
            selectedColumns={checkedMetrics}
            onSelectedColumnsChange={setCheckedMetrics}
          />
        )}
        <Divider />
        <Box sx={{ p: 2, display: 'flex', gap: 2 }}>
          <Button
            sx={{ px: 2, py: 1.5, lineHeight: '1.125rem', borderRadius: 2 }}
            variant="contained"
            color="primary"
            onClick={handleApply}
          >
            Apply
          </Button>
          <Button
            sx={{
              px: 2,
              py: 1.5,
              lineHeight: '1.125rem',
              color: 'black',
              borderRadius: 2,
            }}
            variant="outlined"
            color="secondary"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Dropdown>
  );
}
