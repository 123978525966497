import lodashGet from 'lodash.get';
import { useMemo } from 'react';
import type {
  MetricColumnDataType,
  MetricDataFormatType,
} from '@/entities/columnsConfig';
import { toFormatType } from '@/entities/columnsConfig';
import { selectCurrency } from '@/entities/session';
import { useNumberFormatter } from '@/shared/hooks';
import { useAppSelector } from '@/shared/model';
import { NO_AXIS_SELECTED_VALUE } from '../../model/constants';
import type { Chart, ChartMeta } from '../../model/types';
import type { DataPoint, YAxisParams } from './types';

interface Params<T extends string> {
  meta?: ChartMeta;
  chart: Chart;
  data: DataPoint<T>[];
  splitOptions?: string[];
  defaultSplitOption: string;
  excludedSplitOptions: string[];
}

interface Line {
  name: string;
  value: string;
}

interface LineChartParams<T extends string> {
  lines: Line[];
  leftY: YAxisParams;
  rightY?: YAxisParams;
  data: DataPoint<T>[];
  noData: boolean;
}

export function useLineChartParams<T extends string>({
  meta,
  chart,
  data,
  splitOptions,
  defaultSplitOption,
  excludedSplitOptions,
}: Params<T>): LineChartParams<T> {
  const userCurrency = useAppSelector(selectCurrency);
  const formattersMap = useNumberFormatter(userCurrency);
  const lines = useMemo(() => {
    return getChartLines(
      chart.splitDimension,
      defaultSplitOption,
      splitOptions,
    );
  }, [chart, splitOptions, defaultSplitOption]);
  const leftY = getYAxisParams({
    meta,
    metric: chart.primaryMetric,
    data,
    lines,
    excludedLines: excludedSplitOptions,
    formattersMap,
  });
  const rightY =
    chart.secondaryMetric !== NO_AXIS_SELECTED_VALUE
      ? getYAxisParams({
          meta,
          metric: chart.secondaryMetric,
          data,
          lines,
          excludedLines: excludedSplitOptions,
          formattersMap,
        })
      : undefined;

  return {
    noData: leftY.noData && (!rightY || rightY.noData),
    lines,
    leftY,
    rightY,
    data,
  };
}

const getChartLines = (
  split: string,
  defaultSplit: string,
  options?: string[],
): Line[] => {
  if (split === defaultSplit) {
    return [{ name: '', value: defaultSplit }];
  }

  return (options || []).map((option) => {
    return {
      name: option,
      value: option,
    };
  });
};

const getYAxisParams = ({
  meta,
  metric,
  data,
  lines,
  excludedLines,
  formattersMap,
}: {
  meta?: ChartMeta;
  metric: string;
  data: unknown[];
  lines: Line[];
  excludedLines: string[];
  formattersMap: ReturnType<typeof useNumberFormatter>;
}): YAxisParams => {
  const DEFAULT_MAX = 1;
  const DEFAULT_MIN = 0;

  if (!meta) {
    return {
      unit: '',
      maxLabelLength: 1,
      dataPropLabel: '',
      tickFormatter: () => '',
      getDataProp: () => '',
      noData: false,
      maxValue: DEFAULT_MAX,
      minValue: DEFAULT_MIN,
    };
  }

  const metricConfig = meta.config[metric];
  const tickFormatter =
    formattersMap[metricConfig.type as MetricColumnDataType][
      toFormatType(metricConfig.dataType as MetricDataFormatType)
    ];
  const getDataProp = (lineProp: string) => `data['${lineProp}'].${metric}`;
  const noData = data.every((row) => {
    return lines.every((line) => {
      return lodashGet(row, getDataProp(line.value)) === undefined;
    });
  });
  const excludedLinesSet = new Set(excludedLines);

  return {
    unit: metric,
    maxLabelLength: data.reduce<number>((acc, row) => {
      return Math.max(
        acc,
        ...lines.map((line) => {
          return tickFormatter(lodashGet(row, getDataProp(line.value), 0))
            .length;
        }),
      );
    }, 0),
    noData,
    dataPropLabel: metricConfig.name,
    tickFormatter,
    getDataProp,
    maxValue: noData
      ? DEFAULT_MAX
      : data.reduce<number>((acc, row) => {
          return Math.max(
            acc,
            ...lines.map((line) => {
              if (excludedLinesSet.has(line.value)) {
                return -Infinity;
              }

              return lodashGet(row, getDataProp(line.value), -Infinity);
            }),
          );
        }, -Infinity),
    minValue: noData
      ? DEFAULT_MIN
      : data.reduce<number>((acc, row) => {
          return Math.min(
            acc,
            ...lines.map((line) => {
              if (excludedLinesSet.has(line.value)) {
                return Infinity;
              }

              return lodashGet(row, getDataProp(line.value), Infinity);
            }),
          );
        }, Infinity),
  };
};
