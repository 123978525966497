import ReplayIcon from '@mui/icons-material/Replay';
import type { TypographyProps, SxProps } from '@mui/material';
import {
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDataFetcher } from '@/shared/hooks';
import { formatDate, formatTime, formatTimezone } from '@/shared/lib';

const Text = styled((props: TypographyProps) => {
  const { sx, ...otherProps } = props;

  return (
    <Typography
      variant="caption"
      sx={{ mt: '3px', ...(sx || {}) }}
      {...otherProps}
    />
  );
})(() => ({}));

interface Props {
  sx?: SxProps;
  onGetDataUpdateInfo: () => Promise<{ updateTime: string }>;
}

export function DataUpdateInfo({ onGetDataUpdateInfo, sx }: Props) {
  const { data, loading, error, retry } = useDataFetcher(onGetDataUpdateInfo);

  if (loading) {
    return (
      <Text sx={{ ...sx, display: 'flex', alignItems: 'center', gap: 1 }}>
        Requesting update time: <CircularProgress color="inherit" size={14} />
      </Text>
    );
  }

  if (error) {
    return (
      <Text sx={{ ...sx, display: 'flex', alignItems: 'center', gap: 1 }}>
        Unable to receive update time.
        <Tooltip title="Retry">
          <IconButton sx={{ width: 16, height: 16 }} onClick={retry}>
            <ReplayIcon color="error" fontSize="small" />
          </IconButton>
        </Tooltip>
      </Text>
    );
  }

  return (
    <Text sx={sx}>
      Updated: {formatDate(data?.updateTime!)} {formatTime(data?.updateTime!)}{' '}
      {formatTimezone(data?.updateTime!)}
    </Text>
  );
}
