import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import type { MouseEvent } from 'react';
import { useState } from 'react';
import type { DistributiveOmit } from 'react-redux';
import type { Option, SortingItem } from '@/shared/types';
import { AdvancedSortFunctions, SortOptions, SortTypes } from '@/shared/types';
import { SortButton } from '@/shared/ui';

export type ReducedSortingItem = DistributiveOmit<SortingItem, 'name'>;

interface Props {
  advancedFieldOptions: Option[];
  order?: number;
  value?: SortOptions;
  advancedField?: string;
  onChange: (v?: ReducedSortingItem) => void;
}

export function AdvancedSortButton({
  advancedFieldOptions,
  order,
  advancedField,
  value,
  onChange,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleAdvancedFieldSelect = (newAdvancedField: string) => () => {
    onChange({
      type: SortTypes.ADVANCED,
      value: value ?? SortOptions.ASC,
      field: newAdvancedField,
      function: AdvancedSortFunctions.SUM,
    });
  };
  const handleSortOptionChange = (newSortOption?: SortOptions) => {
    if (!newSortOption) {
      onChange();
    } else if (advancedField) {
      onChange({
        type: SortTypes.ADVANCED,
        value: newSortOption,
        field: advancedField,
        function: AdvancedSortFunctions.SUM,
      });
    } else {
      onChange({
        type: SortTypes.SIMPLE,
        value: newSortOption,
      });
    }
  };

  return (
    <div>
      <SortButton
        value={value}
        order={order}
        onChange={handleSortOptionChange}
      />
      <Tooltip title="Advanced sorting">
        <IconButton sx={{ p: 0, borderRadius: 0.5 }} onClick={handleClick}>
          <KeyboardArrowDownRoundedIcon sx={{ width: 12, height: 12 }} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{
          maxHeight: 400,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        MenuListProps={{
          disablePadding: true,
        }}
      >
        {advancedFieldOptions.map((option) => {
          return (
            <MenuItem
              key={option.value}
              selected={option.value === advancedField}
              onClick={handleAdvancedFieldSelect(option.value)}
            >
              SUM({option.name})
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
