import type { TextFieldProps } from '@mui/material';
import { Box, Divider, InputAdornment, styled, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { selectCurrency } from '@/entities/session';
import type { DataType, FormatType } from '@/shared/hooks';
import { useNumberFormatter } from '@/shared/hooks';
import { useAppSelector } from '@/shared/model';
import { TypedNumberInput } from '@/shared/ui';
import type { ColorLevel } from '../../model/types';
import { ColorPicker } from '../ColorPicker/ColorPicker';

const DisabledField = styled((props: TextFieldProps) => {
  const { label, ...otherProps } = props;

  return (
    <TextField
      disabled
      variant="standard"
      size="small"
      InputProps={{
        sx: {
          fontSize: '0.875rem',
          p: 0.5,

          '&::before, &::after': {
            display: 'none',
          },
        },
        slotProps: {
          input: {
            sx: {
              p: 0,
              fontSize: '0.875rem',
            },
          },
        },
        startAdornment: (
          <InputAdornment
            position="start"
            sx={{ fontSize: '0.75rem', mr: 0.5 }}
          >
            {label}:
          </InputAdornment>
        ),
      }}
      {...otherProps}
    />
  );
})(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

interface Props {
  type: DataType;
  formatType: FormatType;
  previousLevelValue: ColorLevel['value'];
  isLastLevel: boolean;
  value: ColorLevel;
  onChange: (v: ColorLevel) => void;
}

export function ColorLevelEditorNext({
  type,
  formatType,
  previousLevelValue,
  isLastLevel,
  value,
  onChange,
}: Props) {
  const userCurrency = useAppSelector(selectCurrency);
  const formattersMap = useNumberFormatter(userCurrency);
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const handleValueChange = (newValue?: number) => {
    setInternalValue({
      color: value.color,
      value: newValue!, // value is always defined because of allowEmpty is not set
    });
  };
  const handleColorChange = (newColor: string) => {
    onChange({
      ...internalValue,
      color: newColor,
    });
  };
  const handleSubmit = () => {
    onChange(internalValue);
  };

  return (
    <Box
      sx={{
        border: '1px solid #BBBEC8',
        borderRadius: 2,
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <ColorPicker
        sx={{
          minWidth: 40,
          width: 40,
          border: 'none',
          borderRadius: 0,
          p: 1.5,

          ':hover': {
            border: 'none',
          },
        }}
        value={value.color}
        onChange={handleColorChange}
      />
      <Divider orientation="vertical" flexItem />
      <DisabledField
        label="From"
        value={formattersMap[type][formatType](previousLevelValue)}
      />
      <Divider orientation="vertical" flexItem />
      {isLastLevel ? (
        <DisabledField
          label="To"
          value={formattersMap[type][formatType](Infinity)}
        />
      ) : (
        <TypedNumberInput
          textFieldProps={{
            sx: {
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            },
            variant: 'standard',
            size: 'small',
            InputProps: {
              sx: {
                fontSize: '0.875rem',
                p: 0.5,

                '&::before, &::after': {
                  display: 'none',
                },
              },
              slotProps: {
                input: {
                  sx: {
                    p: 0,
                    fontSize: '0.875rem',
                  },
                },
              },
              startAdornment: (
                <InputAdornment
                  position="start"
                  disableTypography
                  sx={{ fontSize: '0.75rem', mr: 0.5 }}
                >
                  To:
                </InputAdornment>
              ),
            },
          }}
          currency={userCurrency}
          type={type}
          formatType={formatType}
          min={previousLevelValue}
          value={internalValue.value}
          onChange={handleValueChange}
          onBlur={handleSubmit}
        />
      )}
    </Box>
  );
}
