import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function CalendarInsightsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.0834 13.3332C9.0499 13.3332 9.8334 12.5497 9.8334 11.5832C9.8334 10.6167 9.0499 9.8332 8.0834 9.8332C7.1169 9.8332 6.3334 10.6167 6.3334 11.5832C6.3334 12.5497 7.1169 13.3332 8.0834 13.3332Z"
          fill="currentColor"
        />
        <path
          d="M15.6667 11.5832C15.6667 12.5497 14.8832 13.3332 13.9167 13.3332C12.9502 13.3332 12.1667 12.5497 12.1667 11.5832C12.1667 10.6167 12.9502 9.8332 13.9167 9.8332C14.8832 9.8332 15.6667 10.6167 15.6667 11.5832Z"
          fill="currentColor"
        />
        <path
          d="M8.0834 17.9999C9.0499 17.9999 9.8334 17.2164 9.8334 16.2499C9.8334 15.2834 9.0499 14.4999 8.0834 14.4999C7.1169 14.4999 6.3334 15.2834 6.3334 16.2499C6.3334 17.2164 7.1169 17.9999 8.0834 17.9999Z"
          fill="currentColor"
        />
        <path
          d="M15.6667 16.2499C15.6667 17.2164 14.8832 17.9999 13.9167 17.9999C12.9502 17.9999 12.1667 17.2164 12.1667 16.2499C12.1667 15.2834 12.9502 14.4999 13.9167 14.4999C14.8832 14.4999 15.6667 15.2834 15.6667 16.2499Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.16673 0.0332031C5.70202 0.0332031 6.14376 0.433763 6.20855 0.951493L6.21673 1.0832V1.89987H15.7834V1.0832C15.7834 0.503304 16.2535 0.0332031 16.8334 0.0332031C17.3687 0.0332031 17.8104 0.433763 17.8752 0.951493L17.8834 1.0832V1.89987H20.1001C20.7444 1.89987 21.2667 2.4222 21.2667 3.06654V17.9999C21.2667 19.804 19.8042 21.2665 18.0001 21.2665H4.00006C2.19593 21.2665 0.733398 19.804 0.733398 17.9999V3.06654C0.733398 2.4222 1.25573 1.89987 1.90007 1.89987H4.11673V1.0832C4.11673 0.503304 4.58683 0.0332031 5.16673 0.0332031ZM15.7834 3.76654V3.99987L15.7916 4.13158C15.8564 4.64931 16.2981 5.04987 16.8334 5.04987C17.4133 5.04987 17.8834 4.57977 17.8834 3.99987V3.76654H19.4001V6.56654H2.60007V3.76654H4.11673V3.99987L4.12491 4.13158C4.1897 4.64931 4.63144 5.04987 5.16673 5.04987C5.74663 5.04987 6.21673 4.57977 6.21673 3.99987V3.76654H15.7834ZM2.60007 8.4332V17.9999C2.60007 18.7247 3.15096 19.3209 3.85692 19.3926L4.00007 19.3999H18.0001C18.7733 19.3999 19.4001 18.7731 19.4001 17.9999V8.4332H2.60007Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}
