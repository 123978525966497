import CompressIcon from '@mui/icons-material/Compress';
import ExpandIcon from '@mui/icons-material/Expand';
import { DEFAULT_COLUMN_WIDTH } from '@/shared/constants';
import { Analytics } from '@/shared/lib';
import { useDataGridApiRefContext, ToggleButtonGroup } from '@/shared/ui';
import { DefaultWidthIcon } from '../DefaultWidthIcon/DefaultWidthIcon';

enum AutosizeOptions {
  shrink = 'shrink',
  expand = 'expand',
  defaultWidth = 'defaultWidth',
}
const options = [
  {
    label: <CompressIcon sx={{ transform: 'rotate(90deg)' }} />,
    value: AutosizeOptions.shrink,
    title: 'Shrink columns to fit the values',
  },
  {
    label: <ExpandIcon sx={{ transform: 'rotate(90deg)' }} />,
    value: AutosizeOptions.expand,
    title: 'Expand columns to fit the headers',
  },
  {
    label: <DefaultWidthIcon sx={{ transform: 'rotate(90deg)' }} />,
    value: AutosizeOptions.defaultWidth,
    title: 'Restore default columns width',
  },
];

interface Props {
  onColumnsResize: (v: { name: string; width: number }[]) => void;
}

export function DataGridAutosizeNext({ onColumnsResize }: Props) {
  const { apiRef } = useDataGridApiRefContext();

  const handleShrink = () => {
    if (!apiRef) {
      return;
    }

    // [HACK] to workaround buggy behavior with resize when view scrolled from top 0 left 0
    const initialScrollPosition = apiRef.getScrollPosition();
    const shrinkHandler = () => {
      apiRef
        .autosizeColumns({
          includeHeaders: false,
          includeOutliers: true,
        })
        // [HACK] investigate root cause why columns not sized properly on first call
        .then(() => {
          return apiRef.autosizeColumns({
            includeHeaders: false,
            includeOutliers: true,
          });
        })
        .then(() => {
          apiRef.scroll(initialScrollPosition);
        });
    };

    if (initialScrollPosition.left !== 0) {
      const unsubscribe = apiRef.subscribeEvent('scrollPositionChange', () => {
        unsubscribe();
        shrinkHandler();
      });
      apiRef.scroll({
        left: 0,
      });
    } else {
      shrinkHandler();
    }
    Analytics.sendFeatureUsage('data_grid_autosize', 'columns_shrink');
  };
  const handleExpand = () => {
    if (!apiRef) {
      return;
    }

    const initialScrollPosition = apiRef.getScrollPosition();
    apiRef
      .autosizeColumns({
        includeHeaders: true,
        includeOutliers: true,
      })
      .then(() => {
        apiRef.scroll(initialScrollPosition);
      });
    Analytics.sendFeatureUsage('data_grid_autosize', 'columns_expand');
  };
  const handleResizeToDefaultWidth = () => {
    if (!apiRef) {
      return;
    }

    const columns = apiRef.getAllColumns();
    const update = columns.map((column) => {
      return {
        ...column,
        width: DEFAULT_COLUMN_WIDTH,
      };
    });

    apiRef.updateColumns(update);
    // Need to update columns width manually, because `updateColumns` doesn't trigger `onColumnWidthChange` event
    onColumnsResize(
      update.map((column) => ({ name: column.field, width: column.width })),
    );
    Analytics.sendFeatureUsage('data_grid_autosize', 'columns_default_width');
  };
  const handleChange = (value: AutosizeOptions) => {
    switch (value) {
      case AutosizeOptions.shrink:
        handleShrink();
        break;
      case AutosizeOptions.expand:
        handleExpand();
        break;
      case AutosizeOptions.defaultWidth:
        handleResizeToDefaultWidth();
        break;
    }
  };

  return <ToggleButtonGroup options={options} onChange={handleChange} />;
}
