import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useAppDispatch } from '@/shared/model';
import { submitPinterestReportingIntegration } from '../../../model/actions';
import { BROADCAST_CHANNEL_NAME } from './constants';

interface UsePinterestCodeState {
  processing: boolean;
  openDataAccessDialog: () => void;
}

export const usePinterestCode = (): UsePinterestCodeState => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [processing, setProcessing] = useState(false);

  return {
    processing,
    openDataAccessDialog: () => {
      const clientId = '1515953';
      const redirectURI = window.location.href;
      const url = `https://www.pinterest.com/oauth/?client_id=${clientId}&redirect_uri=${redirectURI}&scope=ads:read,user_accounts:read&response_type=code`;
      const broadcastChannel = new BroadcastChannel(BROADCAST_CHANNEL_NAME);
      const dataAccessWindow = window.open(url, '_blank');
      const messageHandler = (event: MessageEvent<{ code: string }>) => {
        const { code } = event.data;

        dataAccessWindow?.close();
        broadcastChannel.removeEventListener('message', messageHandler);

        setProcessing(true);
        dispatch(
          submitPinterestReportingIntegration({
            clientId,
            code,
            redirectURI,
          }),
        )
          .catch(() => {
            enqueueSnackbar('Unable to add account. Please, try again.', {
              variant: 'error',
              autoHideDuration: 4000,
            });
          })
          .finally(() => {
            setProcessing(false);
          });
      };

      broadcastChannel.addEventListener('message', messageHandler);
    },
  };
};
