import CloseIcon from '@mui/icons-material/CloseRounded';
import ExpandIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import {
  Box,
  IconButton,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  accordionSummaryClasses,
  collapseClasses,
  accordionClasses,
} from '@mui/material';
import type { ReactNode, SyntheticEvent } from 'react';
import { Fragment } from 'react';
import { Panel } from '@/shared/ui';
import { FiltersAndSettingsIcon } from '../FiltersAndSettingsIcon/FiltersAndSettingsIcon';

interface Props {
  quickAccessSlot?: ReactNode;
  expandedGroup: string;
  onExpandedGroupChange: (group: string) => void;
  ignoreClickClassSelector: string;
  settingGroups: {
    name: string;
    count: number;
    addButton: ReactNode;
    content: ReactNode;
  }[];
  open: boolean;
  onClose: () => void;
}

export function FiltersAndSettingsPanel({
  quickAccessSlot,
  expandedGroup,
  settingGroups,
  open,
  onClose,
  ignoreClickClassSelector,
  onExpandedGroupChange,
}: Props) {
  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      if (
        (event.target as HTMLElement).matches(`.${ignoreClickClassSelector}`)
      ) {
        return;
      }

      onExpandedGroupChange(isExpanded ? panel : '');
    };

  return (
    <Panel
      sx={{
        width: 0,
        mr: 0,
        backgroundColor: '#F1F3F8',
        transition: 'width 0.2s ease-in-out',
        visibility: 'hidden',

        '& .content': {
          opacity: 0,
        },

        [`& .${accordionClasses.region}`]: {
          height: '100%',
          position: 'relative',
        },

        ...(open && {
          width: 337,
          mr: 1,
          transition: 'width 0.2s ease-in-out',
          visibility: 'visible',
          overflow: 'hidden',

          '& .content': {
            opacity: 1,
            transition: 'opacity 0s ease-in-out 0.2s',
            height: '100%',
          },
        }),
      }}
    >
      <Box
        className="content"
        sx={{ display: 'flex', flexDirection: 'column' }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 3,
            px: 2,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <FiltersAndSettingsIcon />
            <Typography
              variant="h3"
              sx={{
                fontWeight: 500,
                fontSize: '1.5em',
                lineHeight: '1.75rem',
                letterSpacing: 0,
              }}
            >
              Filters and Settings
            </Typography>
          </Box>
          <IconButton sx={{ p: 0 }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        {quickAccessSlot}
        <Box sx={{ flexGrow: 1, position: 'relative' }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {settingGroups.map((group, index) => (
              <Fragment key={group.name}>
                <Divider />
                <Accordion
                  key={group.name}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: expandedGroup === group.name ? 1 : 0,
                    boxShadow: 'none',
                    maxHeight: '100%',

                    '&::before': { display: 'none' },
                  }}
                  slotProps={{
                    transition: {
                      sx: {
                        flexGrow: 1,
                        display: 'flex',

                        [`& .${collapseClasses.wrapper}`]: {
                          flexGrow: 1,
                        },
                      },
                    },
                  }}
                  disableGutters
                  square
                  expanded={expandedGroup === group.name}
                  onChange={handleChange(group.name)}
                >
                  <AccordionSummary
                    sx={{
                      p: 2,
                      flexDirection: 'row-reverse',
                      gap: 1,
                      backgroundColor: '#F1F3F8',

                      [`&.${accordionSummaryClasses.expanded}`]: {
                        backgroundColor: 'white',
                      },

                      [`& .${accordionSummaryClasses.content}`]: {
                        margin: 0,
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      },
                    }}
                    expandIcon={<ExpandIcon />}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 600,
                        fontSize: '1.125rem',
                        lineHeight: '1.375rem',
                        letterSpacing: 0,
                      }}
                    >
                      {group.name}:{' '}
                      <Box component="span" sx={{ color: '#7E828F' }}>
                        {group.count}
                      </Box>
                    </Typography>
                    {group.addButton}
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      overflow: 'auto',
                      mb: 1.5,
                      pt: 0,
                      pr: 1.5,
                      pb: 0,
                      pl: 3,
                    }}
                  >
                    {group.content}
                  </AccordionDetails>
                </Accordion>
                {index === settingGroups.length - 1 && <Divider />}
              </Fragment>
            ))}
          </Box>
        </Box>
      </Box>
    </Panel>
  );
}
