import { legacyFiltersValueAdapter } from '@/shared/data';
import type { SavedViewDTO } from '../api/types';
import type { SavedViewRecord } from '../model/types';

export const mapSavedView = (dto: SavedViewDTO): SavedViewRecord => {
  return {
    uuid: dto.uuid,
    savedView: {
      storage: dto.saved_view.storage,
      snapshot: {
        ...(dto.saved_view.snapshot as Object),
        filters: legacyFiltersValueAdapter(
          (
            dto.saved_view.snapshot as {
              filters: { id: string; value: unknown }[];
            }
          ).filters,
        ),
      },
      isDefault: dto.saved_view.isDefault,
    },
    savedViewName: dto.saved_view_name,
    updatedAt: dto.updated_at,
    updating: false,
  };
};
