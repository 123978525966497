import type { Sorting } from '@/shared/types';

type ColumnSorting = Sorting[number] & { index: number };

export const getColumnSorting = (
  sorting: Sorting,
  column: string,
): ColumnSorting | undefined => {
  const index = sorting.findIndex((item) => item.name === column);

  if (index === -1) {
    return;
  }

  return { index: index + 1, ...sorting[index] };
};
