import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useMemo, useRef, useState } from 'react';
import { isFinished } from '@/shared/lib/dataFetching';
import type { MediaFilterOption, MediaFilterParams } from '../../model/types';
import { useFiltersParamsContext } from '../FiltersParamsContext/FiltersParamsContext';
import {
  StringFilterSelectionType,
  StringFilterSelectionTypeSwitcher,
} from '../StringFilterSelectionTypeSwitcher/StringFilterSelectionTypeSwitcher';
import type { ItemRendererProps } from '../VirtualizedFilter/VirtualizedFilter';
import { VirtualizedFilter } from '../VirtualizedFilter/VirtualizedFilter';

const Image = styled('img')(() => ({
  height: 22,
  aspectRatio: '1 / 1',
  objectFit: 'contain',
}));

export interface Value {
  type: StringFilterSelectionType;
  options: string[];
}

interface Props {
  id: string;
  value?: Value;
  onChange: (v: Value) => void;
}

export function MediaFilter({ id, value, onChange }: Props) {
  const { paramsData, requestMediaFilterParams } = useFiltersParamsContext();
  const [type, setType] = useState(
    value?.type || StringFilterSelectionType.INCLUDE,
  );
  const filterParams = paramsData[id]?.params as MediaFilterParams | undefined;
  const allOptions = useMemo(
    () =>
      filterParams?.options.map((option) => {
        return option.value;
      }) || [],
    [filterParams],
  );

  useEffect(
    () => {
      requestMediaFilterParams(id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => {
      if (isFinished(paramsData[id]?.status) && !value) {
        onChange({
          type: StringFilterSelectionType.INCLUDE,
          options: allOptions,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paramsData[id]],
  );

  const handleTypeChange = (type: StringFilterSelectionType) => {
    setType(type);
    onChange({
      type,
      options: value?.options || allOptions,
    });
  };
  const handleOptionsChange = (options: string[]) => {
    onChange({
      type,
      options,
    });
  };

  return (
    <VirtualizedFilter
      filtersSlot={
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
          <Typography variant="modalContent">Type</Typography>
          <StringFilterSelectionTypeSwitcher
            value={type}
            onChange={handleTypeChange}
          />
        </Box>
      }
      ItemRenderer={MediaOptionRenderer}
      value={value?.options}
      onChange={handleOptionsChange}
    />
  );
}

function MediaOptionRenderer({ value, setHeight }: ItemRendererProps) {
  const rootRef = useRef<HTMLDivElement>(null);
  const { url, value: optionValue } = value as MediaFilterOption;

  useEffect(
    () => {
      if (rootRef.current) {
        setHeight(rootRef.current.clientHeight);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rootRef],
  );

  return (
    <Box ref={rootRef} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      {url && <Image src={url} />}{' '}
      <Box component="span" sx={{ wordBreak: 'break-word' }}>
        {optionValue}
      </Box>
    </Box>
  );
}
