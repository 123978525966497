import type { TextFieldProps } from '@mui/material';
import type { FocusEventHandler, ReactNode } from 'react';
import type { FormatType } from '@/shared/hooks';
import { DataType } from '@/shared/hooks';
import { getStep } from '../../model/getStep';
import { CurrencyInput } from '../CurrencyInput/CurrencyInput';
import { NumberInput } from '../NumberInput/NumberInput';
import { PercentageInput } from '../PercentageInput/PercentageInput';

interface Props {
  textFieldProps?: TextFieldProps;
  currency: string;
  allowEmpty?: true;
  label?: ReactNode;
  disabled?: boolean;
  placeholder?: string;
  type: DataType;
  formatType: FormatType;
  min?: number;
  max?: number;
  value: number;
  onChange?: (v: number | undefined) => void;
  onBlur?: () => void;
}

export function TypedNumberInput({
  textFieldProps,
  currency,
  allowEmpty,
  disabled,
  label,
  placeholder,
  type,
  formatType,
  min,
  max,
  value,
  onChange,
  onBlur,
}: Props) {
  const step = getStep(formatType);

  const handleBlur: FocusEventHandler<HTMLInputElement> = () => {
    onBlur?.();
  };

  if (type === DataType.CURRENCY) {
    return (
      <CurrencyInput
        textFieldProps={textFieldProps}
        currency={currency}
        allowEmpty={allowEmpty}
        disabled={disabled}
        label={label}
        placeholder={placeholder}
        step={step}
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        onBlur={handleBlur}
      />
    );
  }

  if (type === DataType.PERCENTAGE) {
    return (
      <PercentageInput
        textFieldProps={textFieldProps}
        allowEmpty={allowEmpty}
        disabled={disabled}
        label={label}
        placeholder={placeholder}
        step={step}
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        onBlur={handleBlur}
      />
    );
  }

  if (type === DataType.NUMBER) {
    return (
      <NumberInput
        textFieldProps={textFieldProps}
        allowEmpty={allowEmpty}
        disabled={disabled}
        label={label}
        placeholder={placeholder}
        step={step}
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        onBlur={handleBlur}
      />
    );
  }

  return <div>Unsupported type: {type}</div>;
}
