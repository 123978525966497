import { IconButton, SvgIcon } from '@mui/material';

interface Props {
  onClick: () => void;
}

export function FilterIconButton({ onClick }: Props) {
  return (
    <IconButton sx={{ p: 0, width: 20, height: 20 }} onClick={onClick}>
      <SvgIcon sx={{ width: 18, height: 18 }}>
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18.3337 2.5H1.66699L8.33366 10.3833V15.8333L11.667 17.5V10.3833L18.3337 2.5Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </SvgIcon>
    </IconButton>
  );
}
