import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useCallback, useMemo } from 'react';
import { VirtualizedList } from '@/shared/ui';
import type { Column, Group } from '../../model/types';
import { GroupItem } from '../GroupItem/GroupItem';

const List = styled('ul')(() => ({
  height: 250,
  width: '100%',
  padding: 0,
  margin: 0,
  color: '#343844',

  '& ul': {
    padding: 0,
  },
}));

interface Props {
  columns: Column[];
  selectedColumns: string[];
  onSelectedColumnsChange: (v: string[]) => void;
}

export function ColumnsListNext({
  columns,
  selectedColumns,
  onSelectedColumnsChange,
}: Props) {
  const selectedColumnsSet = useMemo(() => {
    return new Set(selectedColumns);
  }, [selectedColumns]);
  const groups: Group[] = useMemo(() => {
    const groupsMap = new Map<string, Column[]>();

    columns.forEach((column) => {
      const group = groupsMap.get(column.group) || [];

      group.push(column);
      groupsMap.set(column.group, group);
    });
    groupsMap.forEach((group) => {
      group.sort((colA, colB) => {
        return colA.order - colB.order;
      });
    });

    return [...groupsMap.entries()]
      .sort((groupA, groupB) => {
        return groupA[1][0].order - groupB[1][0].order;
      })
      .map(([key, value]) => {
        return {
          name: key,
          items: value,
        };
      });
  }, [columns]);
  const getGroupItemHeight = useCallback((option: Group) => {
    const GROUP_HEADER_HEIGHT = 32;
    const ITEMS_HEIGHT = option.items.length * 42;

    return GROUP_HEADER_HEIGHT + ITEMS_HEIGHT;
  }, []);

  if (groups.length === 0) {
    return (
      <Typography
        variant="modalContent"
        sx={{ height: 250, p: 2, textAlign: 'center' }}
      >
        Nothing found
      </Typography>
    );
  }

  const handleOptionToggle = (column: string) => {
    if (!selectedColumnsSet.has(column)) {
      selectedColumnsSet.add(column);
    } else {
      selectedColumnsSet.delete(column);
    }
    onSelectedColumnsChange([...selectedColumnsSet]);
  };

  return (
    <List>
      <VirtualizedList
        options={groups}
        itemProps={{
          selected: selectedColumnsSet,
          onToggle: handleOptionToggle,
        }}
        ItemRenderer={GroupItem}
        getDefaultOptionHeight={getGroupItemHeight}
      />
    </List>
  );
}
