import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function HomeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.99935 18.1668H6.49935V12.3335C6.49935 12.0029 6.61135 11.7261 6.83535 11.5028C7.05935 11.2796 7.33624 11.1676 7.66601 11.1668H12.3327C12.6632 11.1668 12.9405 11.2788 13.1645 11.5028C13.3885 11.7268 13.5001 12.0037 13.4993 12.3335V18.1668H16.9993V7.66683L9.99935 2.41683L2.99935 7.66683V18.1668ZM0.666016 18.1668V7.66683C0.666016 7.29738 0.748849 6.94738 0.914515 6.61683C1.08018 6.28627 1.30846 6.01405 1.59935 5.80016L8.59935 0.550163C9.00768 0.239052 9.47435 0.0834961 9.99935 0.0834961C10.5243 0.0834961 10.991 0.239052 11.3993 0.550163L18.3993 5.80016C18.691 6.01405 18.9197 6.28627 19.0853 6.61683C19.251 6.94738 19.3335 7.29738 19.3327 7.66683V18.1668C19.3327 18.8085 19.104 19.358 18.6467 19.8153C18.1893 20.2727 17.6402 20.5009 16.9993 20.5002H12.3327C12.0021 20.5002 11.7252 20.3882 11.502 20.1642C11.2788 19.9402 11.1668 19.6633 11.166 19.3335V13.5002H8.83268V19.3335C8.83268 19.664 8.72068 19.9413 8.49668 20.1653C8.27268 20.3893 7.99579 20.5009 7.66601 20.5002H2.99935C2.35768 20.5002 1.80857 20.2719 1.35202 19.8153C0.89546 19.3588 0.666793 18.8093 0.666016 18.1668Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}
