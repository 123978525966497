import { SvgIcon } from '@mui/material';
import { Analytics } from '@/shared/lib';
import { ToggleButtonGroup } from '@/shared/ui';
import { DataGridViewType } from '../../model/types';

const options = [
  {
    label: (
      <>
        <SvgIcon sx={{ mr: 0.5 }}>
          <svg
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.25 9H21.25M15.25 9V20M15.25 9H9.25M21.25 9V18C21.25 18.943 21.25 19.414 20.957 19.707C20.664 20 20.193 20 19.25 20H15.25M21.25 9V6C21.25 5.057 21.25 4.586 20.957 4.293C20.664 4 20.193 4 19.25 4H5.25C4.307 4 3.836 4 3.543 4.293C3.25 4.586 3.25 5.057 3.25 6V9M21.25 9H3.25M15.25 20H9.25M3.25 9H9.25M3.25 9V18C3.25 18.943 3.25 19.414 3.543 19.707C3.836 20 4.307 20 5.25 20H9.25M9.25 9V20"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </SvgIcon>
        Table
      </>
    ),
    value: DataGridViewType.dataTable,
  },
  {
    label: (
      <>
        <SvgIcon sx={{ mr: 0.5 }}>
          <svg
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.875 3C5.17881 3 4.51113 3.27656 4.01884 3.76884C3.52656 4.26113 3.25 4.92881 3.25 5.625V7.125C3.25 8.574 4.426 9.75 5.875 9.75H6.502C6.33519 10.1015 6.24909 10.4859 6.25 10.875V13.125C6.25 13.86 6.5515 14.523 7.0375 15C6.5515 15.477 6.25 16.14 6.25 16.875V18.375C6.25 19.824 7.426 21 8.875 21H18.625C19.3212 21 19.9889 20.7234 20.4812 20.2312C20.9734 19.7389 21.25 19.0712 21.25 18.375V16.875C21.25 16.14 20.9485 15.477 20.4625 15C20.9485 14.523 21.25 13.86 21.25 13.125V10.875C21.25 10.14 20.9485 9.477 20.4625 9C20.9485 8.523 21.25 7.86 21.25 7.125V5.625C21.25 4.92881 20.9734 4.26113 20.4812 3.76884C19.9889 3.27656 19.3212 3 18.625 3H5.875ZM18.625 14.25H12.25V9.75H18.625C18.9234 9.75 19.2095 9.86853 19.4205 10.0795C19.6315 10.2905 19.75 10.5766 19.75 10.875V13.125C19.75 13.4234 19.6315 13.7095 19.4205 13.9205C19.2095 14.1315 18.9234 14.25 18.625 14.25ZM10.75 9.75V14.25H8.875C8.57663 14.25 8.29048 14.1315 8.0795 13.9205C7.86853 13.7095 7.75 13.4234 7.75 13.125V10.875C7.75 10.5766 7.86853 10.2905 8.0795 10.0795C8.29048 9.86853 8.57663 9.75 8.875 9.75H10.75ZM12.25 15.75H18.625C18.9234 15.75 19.2095 15.8685 19.4205 16.0795C19.6315 16.2905 19.75 16.5766 19.75 16.875V18.375C19.75 18.6734 19.6315 18.9595 19.4205 19.1705C19.2095 19.3815 18.9234 19.5 18.625 19.5H12.25V15.75ZM10.75 15.75V19.5H8.875C8.57663 19.5 8.29048 19.3815 8.0795 19.1705C7.86853 18.9595 7.75 18.6734 7.75 18.375V16.875C7.75 16.5766 7.86853 16.2905 8.0795 16.0795C8.29048 15.8685 8.57663 15.75 8.875 15.75H10.75ZM9.25 8.25V4.5H18.625C18.9234 4.5 19.2095 4.61853 19.4205 4.8295C19.6315 5.04048 19.75 5.32663 19.75 5.625V7.125C19.75 7.42337 19.6315 7.70952 19.4205 7.9205C19.2095 8.13147 18.9234 8.25 18.625 8.25H9.25ZM7.75 8.25H5.875C5.57663 8.25 5.29048 8.13147 5.0795 7.9205C4.86853 7.70952 4.75 7.42337 4.75 7.125V5.625C4.75 5.32663 4.86853 5.04048 5.0795 4.8295C5.29048 4.61853 5.57663 4.5 5.875 4.5H7.75V8.25Z"
              fill="currentColor"
            />
          </svg>
        </SvgIcon>
        Tree
      </>
    ),
    value: DataGridViewType.treeDataGrid,
  },
];
interface Props {
  value?: DataGridViewType;
  onChange: (v: DataGridViewType) => void;
}

export function DataGridTypeSwitcherNext({
  value = DataGridViewType.dataTable,
  onChange,
}: Props) {
  const handleChange = (newValue: DataGridViewType) => {
    onChange(newValue);
    Analytics.sendFeatureUsage('grid_type_switch', newValue);
  };

  return (
    <ToggleButtonGroup
      options={options}
      value={value}
      onChange={handleChange}
    />
  );
}
