import { createSelector } from '@reduxjs/toolkit';
import type { User } from '@supabase/supabase-js';
import { Analytics, DataStatus, Monitoring } from '@/shared/lib';
import type { AppThunk } from '@/shared/model';
import { createAppSlice } from '@/shared/model';
import * as SessionAPI from '../api/sessionAPI';
import type { LoginParams } from '../api/types';
import type { Organization } from './types';

interface State {
  user?: User;
  organization?: Organization;
  status: DataStatus;
  showPaymentOvedueBanner: boolean;
}

const initialState: State = {
  status: DataStatus.idle,
  showPaymentOvedueBanner: false,
};

export const sessionSlice = createAppSlice({
  name: 'session',
  initialState,
  reducers: (create) => ({
    setupUser: create.asyncThunk(
      (_: void) => {
        return SessionAPI.getUserData();
      },
      {
        pending: (state) => {
          state.status = DataStatus.loading;
        },
        fulfilled: (state, action) => {
          state.user = action.payload;
        },
        settled: (state) => {
          state.status = DataStatus.finished;
        },
      },
    ),
    setupOrganization: create.asyncThunk(
      (_: void) => {
        return SessionAPI.getOrganization();
      },
      {
        fulfilled: (state, action) => {
          state.organization = action.payload;
        },
      },
    ),
    login: create.asyncThunk(
      (params: LoginParams, { dispatch }) => {
        if (params.shouldRemember) {
          SessionAPI.updateRememberMe(
            (params.credentials as { email: string }).email,
          );
        }

        return SessionAPI.login(params.credentials).then((user) => {
          (dispatch as AppDispatch)(setupApp());

          return user;
        });
      },
      {
        pending: (state) => {
          state.status = DataStatus.loading;
        },
        fulfilled: (state, action) => {
          state.user = action.payload;
        },
        settled: (state) => {
          state.status = DataStatus.finished;
        },
      },
    ),
    logout: create.asyncThunk(
      (_: void) => {
        SessionAPI.deleteRememberMe();
        SessionAPI.deleteShowPaymentOverdueBanner();

        return SessionAPI.logout();
      },
      {
        pending: (state) => {
          state.status = DataStatus.loading;
        },
        fulfilled: (state) => {
          state.user = undefined;
        },
        settled: (state) => {
          state.status = DataStatus.idle;
        },
      },
    ),
    setShowPaymentOverdueBanner: create.reducer((state) => {
      state.showPaymentOvedueBanner = true;
    }),
    hidePaymentOverdueBanner: create.reducer((state) => {
      state.showPaymentOvedueBanner = false;
    }),
  }),
  selectors: {
    selectIsSessionRequested: (session) => {
      return session.status !== DataStatus.idle;
    },
    selectIsSessionLoading: (session) => {
      return session.status === DataStatus.loading;
    },
    selectIsAuthorized: (session) => {
      return session.status === DataStatus.finished && !!session.user;
    },
    selectEmail: (session) => {
      return session.user?.email;
    },
    selectShowPaymentOverdueBanner: (session) => {
      return session.showPaymentOvedueBanner;
    },
    selectCurrency: (session) => {
      return session.organization?.currency;
    },
  },
});

export const selectCurrency = createSelector(
  sessionSlice.selectors.selectCurrency,
  (currency) => {
    const validCurrency = isValidCurrency(currency) ? currency! : 'USD';

    return validCurrency;
  },
);

const isValidCurrency = (currency: string | undefined): boolean => {
  // ISO 4217 currency codes are 3 uppercase letters
  const currencyRegex = /^[A-Z]{3}$/;

  return !!currency && currencyRegex.test(currency);
};

export const setupApp = (): AppThunk => (dispatch) => {
  dispatch(setupUser());
  dispatch(setupOrganization());
};

const setupUser = (): AppThunk => async (dispatch) => {
  const user = await dispatch(sessionSlice.actions.setupUser()).unwrap();

  if (!user) {
    return;
  }

  Monitoring.setUser({ id: user.id, email: user.email });
  SessionAPI.getAnalyticsId(user.id).then((id) => {
    Analytics.setUserId(id);
  });
};

const setupOrganization = (): AppThunk => async (dispatch) => {
  const organization = await dispatch(
    sessionSlice.actions.setupOrganization(),
  ).unwrap();

  if (!organization) {
    return;
  }

  SessionAPI.setShowPaymentOverdueBanner(organization.showPaymentOvedueBanner);

  if (SessionAPI.getShowPaymentOverdueBanner()) {
    dispatch(sessionSlice.actions.setShowPaymentOverdueBanner());
  }
};

export const hidePaymentOverdueBanner = (): AppThunk => (dispatch) => {
  dispatch(sessionSlice.actions.hidePaymentOverdueBanner());
  SessionAPI.hidePaymentOverdueBanner();
};

export const { login, logout } = sessionSlice.actions;

export const {
  selectIsSessionRequested,
  selectIsSessionLoading,
  selectIsAuthorized,
  selectEmail,
  selectShowPaymentOverdueBanner,
} = sessionSlice.selectors;
