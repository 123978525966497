import {
  Box,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import {
  PINTEREST_REPORTING_INTEGRATION_ID,
  deleteIntegration,
  IntegrationActionsMenu,
  selectIntegrations,
  selectIsLoading,
} from '@/entities/datasources';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { Button, Loader } from '@/shared/ui';
import type { PinterestReportingIntegration } from '../../model/types';
import { useURLSearchParamsBroadcaster } from '../../model/useURLSearchParamsBroadcaster';
import { BROADCAST_CHANNEL_NAME } from './model/constants';
import { usePinterestCode } from './model/usePinterestCode';

const PinterestButton = styled(Button)(() => ({
  backgroundColor: '#111111',
  borderRadius: 24,

  '&:hover': {
    backgroundColor: '#555555',
  },
}));

export function PinterestDatasource() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const integrationsLoading = useAppSelector(selectIsLoading);
  const integrations = useAppSelector(selectIntegrations);
  const pinterestReportingIntegrations = useMemo(() => {
    return integrations.filter((integration) => {
      return (
        integration.sourceIntegrationId === PINTEREST_REPORTING_INTEGRATION_ID
      );
    }) as PinterestReportingIntegration[];
  }, [integrations]);
  const { processing, openDataAccessDialog } = usePinterestCode();

  useURLSearchParamsBroadcaster({
    channelName: BROADCAST_CHANNEL_NAME,
    params: ['code'],
  });

  const handleDeleteIntegration = (id: string) => () => {
    return dispatch(deleteIntegration(id))
      .unwrap()
      .then(() => {
        enqueueSnackbar('Integration successfully deleted.', {
          variant: 'success',
          autoHideDuration: 2000,
        });
      })
      .catch(() => {
        enqueueSnackbar('Unable to delete integration. Please, try again.', {
          variant: 'error',
          autoHideDuration: 4000,
        });
      });
  };

  return (
    <Box
      sx={{
        py: 3,
        px: 5,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 2,
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Username</TableCell>
            <TableCell sx={{ width: 170 }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pinterestReportingIntegrations.length === 0 && (
            <TableRow>
              <TableCell colSpan={2} align="center">
                No accounts added
              </TableCell>
            </TableRow>
          )}
          {pinterestReportingIntegrations.map((integration) => {
            return (
              <TableRow key={integration.id} sx={{ position: 'relative' }}>
                <TableCell>{integration.integrationConfig.username}</TableCell>
                <TableCell>
                  <IntegrationActionsMenu
                    integrationName={integration.integrationConfig.username}
                    onDelete={handleDeleteIntegration(integration.id)}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <PinterestButton
        startIcon={
          <SvgIcon>
            <svg viewBox="0 0 24 24">
              <circle cx="12" cy="12" r="11.5" fill="#fff" />
              <path
                fill="#e60023"
                d="M7.54 23.15q-.2-2.05.26-3.93L9 14.04a7 7 0 0 1-.35-2.07c0-1.68.81-2.88 2.09-2.88.88 0 1.53.62 1.53 1.8q0 .57-.23 1.28l-.52 1.72q-.15.5-.15.92c0 1.2.91 1.87 2.08 1.87 2.09 0 3.57-2.16 3.57-4.96 0-3.12-2.04-5.12-5.05-5.12-3.36 0-5.49 2.19-5.49 5.24 0 1.22.38 2.36 1.11 3.14-.24.41-.5.48-.88.48-1.2 0-2.34-1.69-2.34-4 0-4 3.2-7.17 7.68-7.17 4.7 0 7.66 3.29 7.66 7.33s-2.88 7.15-5.98 7.15a3.8 3.8 0 0 1-3.06-1.48l-.62 2.5a11 11 0 0 1-1.62 3.67A11.98 11.98 0 0 0 24 12a11.99 11.99 0 1 0-24 0 12 12 0 0 0 7.54 11.15"
              ></path>
            </svg>
          </SvgIcon>
        }
        onClick={openDataAccessDialog}
      >
        Add account
      </PinterestButton>
      <Loader active={processing || integrationsLoading} />
    </Box>
  );
}
