import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Divider, MenuItem as MUIMenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import type { ColumnsConfig } from '@/entities/columnsConfig';
import { ColumnType } from '@/entities/columnsConfig';
import { Dropdown } from '@/shared/ui';
import { useColumnsInfo } from '../../model/useColumnsInfo';
import { ColumnsPanel } from '../ColumnsPanel/ColumnsPanel';

const MenuItem = styled(MUIMenuItem)(({ theme, selected }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  fontSize: '14px',
  color: 'var(--neutral-700)',
  justifyContent: 'space-between',

  ...(selected && {
    fontWeight: 500,
    color: theme.palette.text.primary,
  }),

  '& .MuiSvgIcon-root': {
    color: 'var(--neutral-600)',
  },
}));

interface Props {
  isColumnsConfigLoading: boolean;
  selectedDimensions: string[];
  selectedMetrics: string[];
  allMetrics: string[];
  allDimensions: string[];
  columnsConfig: ColumnsConfig;
  requestColumnsConfig: () => void;
  updateColumns: (newColumns: {
    dimensions: string[];
    metrics: string[];
  }) => void;
}

export function AddColumns({
  isColumnsConfigLoading,
  selectedDimensions,
  selectedMetrics,
  columnsConfig,
  allDimensions,
  allMetrics,
  requestColumnsConfig,
  updateColumns,
}: Props) {
  const { dimensionsColumns, metricsColumns } = useColumnsInfo({
    columnsConfig,
    allDimensions,
    allMetrics,
  });
  const [open, setOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState(ColumnType.DIMENSION);
  const [checkedDimensions, setCheckedDimensions] = useState<string[]>([]);
  const [checkedMetrics, setCheckedMetrics] = useState<string[]>([]);

  useEffect(() => {
    requestColumnsConfig();
  }, [requestColumnsConfig]);

  const handleOpen = (newOpen: boolean) => {
    if (newOpen) {
      setCheckedDimensions(selectedDimensions);
      setCheckedMetrics(selectedMetrics);
    }

    setOpen(newOpen);
  };
  const handleCategoryChange = (type: ColumnType) => () => {
    setActiveCategory(type);
  };
  const handleApply = () => {
    updateColumns({
      dimensions: checkedDimensions,
      metrics: checkedMetrics,
    });
    setOpen(false);
  };

  const isDimensionsActive = activeCategory === ColumnType.DIMENSION;
  const isMetricsActive = activeCategory === ColumnType.METRIC;

  return (
    <Dropdown
      label="Add columns"
      labelProps={{
        variant: 'contained',
        color: 'primary',
        startIcon: <AddIcon />,
        endIcon: null,
      }}
      loading={isColumnsConfigLoading}
      open={open}
      onOpenChange={handleOpen}
    >
      <Box display="flex">
        <Box
          component="ul"
          sx={{
            width: 200,
            py: 1,
            px: 0,
            m: 0,
          }}
        >
          <MenuItem
            selected={isDimensionsActive}
            onClick={handleCategoryChange(ColumnType.DIMENSION)}
          >
            Cohorts
            <KeyboardArrowRightIcon />
          </MenuItem>
          <MenuItem
            selected={isMetricsActive}
            onClick={handleCategoryChange(ColumnType.METRIC)}
          >
            Metrics
            <KeyboardArrowRightIcon />
          </MenuItem>
        </Box>
        <Divider flexItem orientation="vertical" />
        {isDimensionsActive && (
          <ColumnsPanel
            columns={dimensionsColumns}
            selectedColumns={checkedDimensions}
            onApply={handleApply}
            onSelectedColumnsChange={setCheckedDimensions}
          />
        )}
        {isMetricsActive && (
          <ColumnsPanel
            columns={metricsColumns}
            selectedColumns={checkedMetrics}
            onApply={handleApply}
            onSelectedColumnsChange={setCheckedMetrics}
          />
        )}
      </Box>
    </Dropdown>
  );
}
