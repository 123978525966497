import { lazy } from 'react';
import type { createBrowserRouter } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { ImportSharedViewDialog as CalendarInsightsImportSharedViewDialog } from '@/pages/CalendarInsights';
import { ImportSharedViewDialog as ChartsViewImportSharedViewDialog } from '@/pages/ChartsView';
import { ImportSharedViewDialog as CohortMarketingPerformanceImportSharedViewDialog } from '@/pages/CohortMarketingPerformance';
import { DatasourcePage } from '@/pages/Datasource';
import { DatasourcesPage } from '@/pages/Datasources';
import { PasswordResetPage } from '@/pages/PasswordReset';
import { PasswordRestorePage } from '@/pages/PasswordRestore';
import { SignInPage } from '@/pages/SignIn';
import { WebLinksPage } from '@/pages/WebLinks';
import { Features } from '@/features/featureFlags';
import { ROUTES } from '@/shared/constants';
import { AuthGuard } from '../ui/AuthGuard/AuthGuard';
import { Baseline } from '../ui/Baseline/Baseline';
import { FeatureGuard } from '../ui/FeatureGuard/FeatureGuard';
import { GlobalErrorBoundary } from '../ui/GlobalErrorBoundary/GlobalErrorBoundary';
import { GuestGuard } from '../ui/GuestGuard/GuestGuard';

const HomePageNext = lazy(() =>
  import('@/pages/Home').then(({ HomePageNext }) => ({
    default: HomePageNext,
  })),
);
const HomePage = lazy(() =>
  import('@/pages/Home').then(({ HomePage }) => ({ default: HomePage })),
);
const CohortMarketingPerformancePageNext = lazy(() =>
  import('@/pages/CohortMarketingPerformance').then(
    ({ CohortMarketingPerformancePageNext }) => ({
      default: CohortMarketingPerformancePageNext,
    }),
  ),
);
const CohortMarketingPerformancePage = lazy(() =>
  import('@/pages/CohortMarketingPerformance').then(
    ({ CohortMarketingPreformancePage }) => ({
      default: CohortMarketingPreformancePage,
    }),
  ),
);
const CalendarInsightsPageNext = lazy(() =>
  import('@/pages/CalendarInsights').then(({ CalendarInsightsPageNext }) => ({
    default: CalendarInsightsPageNext,
  })),
);
const CalendarInsightsPage = lazy(() =>
  import('@/pages/CalendarInsights').then(({ CalendarInsightsPage }) => ({
    default: CalendarInsightsPage,
  })),
);

export const appRouter = (
  createBrowserRouterFunction: typeof createBrowserRouter,
) => {
  return createBrowserRouterFunction([
    {
      path: ROUTES.home,
      element: <Baseline />,
      errorElement: <GlobalErrorBoundary />,
      children: [
        {
          element: <AuthGuard />,
          children: [
            {
              index: true,
              element: <HomePage />,
            },
            {
              path: ROUTES.cohortMarketingPerformance,
              element: <CohortMarketingPerformancePage />,
              children: [
                {
                  path: 'share/:id',
                  element: <CohortMarketingPerformanceImportSharedViewDialog />,
                },
              ],
            },
            {
              path: ROUTES.calendarInsights,
              element: <CalendarInsightsPage />,
              children: [
                {
                  path: 'share/:id',
                  element: <CalendarInsightsImportSharedViewDialog />,
                },
              ],
            },
            {
              path: ROUTES.chartsView,
              lazy: () => {
                return import('@/pages/ChartsView').then(
                  ({ ChartsViewPage }) => {
                    return {
                      Component: ChartsViewPage,
                    };
                  },
                );
              },
              children: [
                {
                  path: 'share/:id',
                  element: <ChartsViewImportSharedViewDialog />,
                },
              ],
            },
            { path: ROUTES.passwordRestore, element: <PasswordRestorePage /> },
            {
              path: ROUTES.datasources,
              children: [
                { index: true, element: <DatasourcesPage /> },
                { path: ':datasourceId', element: <DatasourcePage /> },
              ],
            },
            {
              path: ROUTES.webLinks,
              element: <WebLinksPage />,
            },
            {
              path: ROUTES.redesign,
              element: <FeatureGuard feature={Features.redesign} />,
              children: [
                {
                  index: true,
                  element: <HomePageNext />,
                },
                {
                  path: ROUTES.cohortMarketingPerformance.slice(1),
                  element: <CohortMarketingPerformancePageNext />,
                  children: [
                    {
                      path: 'share/:id',
                      element: (
                        <CohortMarketingPerformanceImportSharedViewDialog />
                      ),
                    },
                  ],
                },
                {
                  path: ROUTES.calendarInsights.slice(1),
                  element: <CalendarInsightsPageNext />,
                  children: [
                    {
                      path: 'share/:id',
                      element: <CalendarInsightsImportSharedViewDialog />,
                    },
                  ],
                },
                {
                  path: '*',
                  element: <Navigate to={`${ROUTES.redesign}/`} />,
                },
              ],
            },
            { path: '*', element: <Navigate to={ROUTES.home} /> },
          ],
        },
        {
          element: <GuestGuard />,
          children: [
            { path: ROUTES.signin, element: <SignInPage /> },
            { path: ROUTES.passwordReset, element: <PasswordResetPage /> },
          ],
        },
      ],
    },
  ]);
};
