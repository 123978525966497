import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function CohortMarketingPerformanceIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.5 8.5V22.5H1.5V15.624V8.5H8.5ZM8.5 8.5V1.5H15.5M8.5 8.5H22.5V1.5H15.5M1.5 15.5H15.5V1.5"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
