import { Analytics } from '@/shared/lib';
import type { SortingItem, SortOptions } from '@/shared/types';
import { SortTypes } from '@/shared/types';
import { getColumnSorting, SortButton, HeaderCell } from '@/shared/ui';
import { useTableHeaderPropsContext } from '../TableHeaderPropsContext/TableHeaderPropsContext';

interface Props {
  field: string;
  name: string;
  description?: string;
}

export function MetricTableHeader({ field, name, description }: Props) {
  const { sorting, updateSorting, onMetricDelete } =
    useTableHeaderPropsContext();
  const sortingItem = getColumnSorting(sorting, field);

  const handleSimpleSortingUpdate =
    (name: string) => (newSortOption?: SortOptions) => {
      const sortingItem: SortingItem | undefined = newSortOption
        ? {
            type: SortTypes.SIMPLE,
            name,
            value: newSortOption,
          }
        : undefined;

      updateSorting(name, sortingItem);
      Analytics.sendFeatureUsage('data_table', 'simple_sorting');
    };
  const handleDelete = () => {
    onMetricDelete(field);
    Analytics.sendFeatureUsage('data_table', 'delete_column');
  };

  return (
    <HeaderCell
      title={name}
      description={description}
      sortButton={
        <SortButton
          order={sortingItem?.index}
          value={sortingItem?.value}
          onChange={handleSimpleSortingUpdate(field)}
        />
      }
      onDelete={handleDelete}
    />
  );
}
