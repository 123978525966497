import {
  addOrUpdateIntegration,
  startIntegrationUpdate,
  stopIntegrationUpdate,
} from '@/entities/datasources';
import type { AppThunk } from '@/shared/model';
import * as DatasourcesAPI from '../api/datasourcesAPI';
import type { ActiveStatusRecord } from './types';

interface SubmitMetaAdsReportingIntegrationParams {
  code: string;
  clientId: string;
  redirectURI: string;
}

export const submitMetaAdsReportingIntegration =
  (params: SubmitMetaAdsReportingIntegrationParams): AppThunk<Promise<void>> =>
  (dispatch) => {
    return DatasourcesAPI.submitMetaAdsReportingIntegration(params).then(
      (integration) => {
        dispatch(addOrUpdateIntegration(integration));
      },
    );
  };

interface SubmitGoogleAdsReportingIntegrationParams {
  clientId: string;
  code: string;
  redirectURI: string;
}

export const submitGoogleAdsReportingIntegration =
  (
    params: SubmitGoogleAdsReportingIntegrationParams,
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    return DatasourcesAPI.submitGoogleAdsReportingIntegration(params).then(
      (integration) => {
        dispatch(addOrUpdateIntegration(integration));
      },
    );
  };

interface SubmitTapJoyReportingIntegrationParams {
  accountName: string;
  apiKey: string;
}

export const submitTapJoyReportingIntegration =
  (params: SubmitTapJoyReportingIntegrationParams): AppThunk<Promise<void>> =>
  (dispatch) => {
    return DatasourcesAPI.submitTapJoyReportingIntegration(params).then(
      (integration) => {
        dispatch(addOrUpdateIntegration(integration));
      },
    );
  };

interface SubmitPinterestReportingIntegrationParams {
  code: string;
  clientId: string;
  redirectURI: string;
}

export const submitPinterestReportingIntegration =
  (
    params: SubmitPinterestReportingIntegrationParams,
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    return DatasourcesAPI.submitPinterestReportingIntegration(params).then(
      (integration) => {
        dispatch(addOrUpdateIntegration(integration));
      },
    );
  };

interface SubmitAppLovinMaxReportingIntegrationParams {
  accountName: string;
  reportKey: string;
}

export const submitAppLovinMaxReportingIntegration =
  (
    params: SubmitAppLovinMaxReportingIntegrationParams,
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    return DatasourcesAPI.submitAppLovinMaxReportingIntegration(params).then(
      (integration) => {
        dispatch(addOrUpdateIntegration(integration));
      },
    );
  };

interface SubmitMintegralReportingIntegrationParams {
  accountName: string;
  apiKey: string;
  accessKey: string;
}

export const submitMintegralReportingIntegration =
  (
    params: SubmitMintegralReportingIntegrationParams,
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    return DatasourcesAPI.submitMintegralReportingIntegration(params).then(
      (integration) => {
        dispatch(addOrUpdateIntegration(integration));
      },
    );
  };

interface SubmitGoogleAnalyticsReportingIntegrationParams {
  bucket: string;
  subscriptionId: string;
}

export const submitGoogleAnalyticsReportingIntegration =
  (
    params: SubmitGoogleAnalyticsReportingIntegrationParams,
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    return DatasourcesAPI.submitGoogleAnalyticsReportingIntegration(
      params,
    ).then((integration) => {
      dispatch(addOrUpdateIntegration(integration));
    });
  };

interface SubmitAppsflyerReportingIntegrationParams {
  accountName: string;
  token: string;
}

export const submitAppsflyerReportingIntegration =
  (
    params: SubmitAppsflyerReportingIntegrationParams,
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    return DatasourcesAPI.submitAppsflyerReportingIntegration(params).then(
      (integration) => {
        dispatch(addOrUpdateIntegration(integration));
      },
    );
  };

interface SubmitLiftoffReportingIntegrationParams {
  accountName: string;
  apiKey: string;
  apiSecret: string;
}

export const submitLiftoffReportingIntegration =
  (params: SubmitLiftoffReportingIntegrationParams): AppThunk<Promise<void>> =>
  (dispatch) => {
    return DatasourcesAPI.submitLiftoffReportingIntegration(params).then(
      (integration) => {
        dispatch(addOrUpdateIntegration(integration));
      },
    );
  };

interface SubmitXAdsReportingIntegrationParams {
  apiKey: string;
  apiKeySecret: string;
  accessToken: string;
  accessTokenSecret: string;
}

export const submitXAdsReportingIntegration =
  (params: SubmitXAdsReportingIntegrationParams): AppThunk<Promise<void>> =>
  (dispatch) => {
    return DatasourcesAPI.submitXAdsReportingIntegration(params).then(
      (integration) => {
        dispatch(addOrUpdateIntegration(integration));
      },
    );
  };

interface SubmitIronSourceReportingIntegrationParams {
  accountName: string;
  secretKey: string;
  refreshToken: string;
}

export const submitIronSourceIntegration =
  (
    params: SubmitIronSourceReportingIntegrationParams,
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    return DatasourcesAPI.submitIronSourceIntegration(params).then(
      (integration) => {
        dispatch(addOrUpdateIntegration(integration));
      },
    );
  };

interface SubmitMolocoAdsReportingIntegrationParams {
  accountName: string;
  adAccounts: string;
  apiKey: string;
}

export const submitMolocoAdsReportingIntegration =
  (
    params: SubmitMolocoAdsReportingIntegrationParams,
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    return DatasourcesAPI.submitMolocoAdsReportingIntegration(params).then(
      (integration) => {
        dispatch(addOrUpdateIntegration(integration));
      },
    );
  };

interface SubmitUnityAdsReportingIntegrationParams {
  accountName: string;
  keyId: string;
  secretKey: string;
}

export const submitUnityAdsReportingIntegration =
  (params: SubmitUnityAdsReportingIntegrationParams): AppThunk<Promise<void>> =>
  (dispatch) => {
    return DatasourcesAPI.submitUnityAdsReportingIntegration(params).then(
      (integration) => {
        dispatch(addOrUpdateIntegration(integration));
      },
    );
  };

interface SubmitPayPalReportingIntegrationParams {
  webhookId: string;
  clientId: string;
  clientSecret: string;
}

export const submitPayPalReportingIntegration =
  (params: SubmitPayPalReportingIntegrationParams): AppThunk<Promise<void>> =>
  (dispatch) => {
    return DatasourcesAPI.submitPayPalReportingIntegration(params).then(
      (integration) => {
        dispatch(addOrUpdateIntegration(integration));
      },
    );
  };

interface SubmitStripeReportingIntegrationParams {
  accountId: string;
  secretKey: string;
  signingSecret: string;
}

export const submitStripeReportingIntegration =
  (params: SubmitStripeReportingIntegrationParams): AppThunk<Promise<void>> =>
  (dispatch) => {
    return DatasourcesAPI.submitStripeReportingIntegration(params).then(
      (integration) => {
        dispatch(addOrUpdateIntegration(integration));
      },
    );
  };

interface SubmitAppStoreConnectReportingIntegrationParams {
  name: string;
  keyId: string;
  issuerId: string;
  vendorId: string;
  p8Key: string;
}

export const submitAppStoreConnectReportingIntegration =
  (
    params: SubmitAppStoreConnectReportingIntegrationParams,
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    return DatasourcesAPI.submitAppStoreConnectReportingIntegration(
      params,
    ).then((integration) => {
      dispatch(addOrUpdateIntegration(integration));
    });
  };

interface SubmitGooglePlayConsoleReportingIntegrationParams {
  name: string;
  cloudStorageURI: string;
  serviceCredentials: string;
}

export const submitGooglePlayConsoleReportingIntegration =
  (
    params: SubmitGooglePlayConsoleReportingIntegrationParams,
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    return DatasourcesAPI.submitGooglePlayConsoleReportingIntegration(
      params,
    ).then((integration) => {
      dispatch(addOrUpdateIntegration(integration));
    });
  };

interface SubmitAppLovinAdsReportingIntegrationParams {
  accountName: string;
  reportKey: string;
}

export const submitAppLovinAdsReportingIntegration =
  (
    params: SubmitAppLovinAdsReportingIntegrationParams,
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    return DatasourcesAPI.submitAppLovinAdsReportingIntegration(params).then(
      (integration) => {
        dispatch(addOrUpdateIntegration(integration));
      },
    );
  };

interface SubmitAppleSearchAdsReportingIntegrationParams {
  campaignGroupId: string;
  teamId: string;
  keyId: string;
  clientId: string;
}

export const submitAppleSearchAdsReportingIntegration =
  (
    params: SubmitAppleSearchAdsReportingIntegrationParams,
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    return DatasourcesAPI.submitAppleSearchAdsReportingIntegration(params).then(
      (integration) => {
        dispatch(addOrUpdateIntegration(integration));
      },
    );
  };

interface SubmitSnapchatAdsReportingIntegrationParams {
  code: string;
  clientId: string;
  redirectURI: string;
}

export const submitSnapchatAdsReportingIntegration =
  (
    params: SubmitSnapchatAdsReportingIntegrationParams,
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    return DatasourcesAPI.submitSnapchatAdsReportingIntegration(params).then(
      (integration) => {
        dispatch(addOrUpdateIntegration(integration));
      },
    );
  };

interface SubmitTikTokForBusinessReportingIntegrationParams {
  authCode: string;
  code: string;
  appId: string;
}

export const submitTikTokForBusinessReportingIntegration =
  (
    params: SubmitTikTokForBusinessReportingIntegrationParams,
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    return DatasourcesAPI.submitTikTokForBusinessReportingIntegration(
      params,
    ).then((integration) => {
      dispatch(addOrUpdateIntegration(integration));
    });
  };

interface UpdateGoogleAdsReportingIntegrationAccountListActiveRecordParams {
  id: string;
  activeStatusRecord: ActiveStatusRecord;
}

export const updateGoogleAdsReportingIntegrationAccountListActiveRecord =
  (
    params: UpdateGoogleAdsReportingIntegrationAccountListActiveRecordParams,
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(startIntegrationUpdate(params.id));

    return DatasourcesAPI.updateGoogleAdsReportingIntegrationAccountsActiveStatusRecord(
      params,
    )
      .then((integration) => {
        dispatch(addOrUpdateIntegration(integration));
      })
      .catch((error) => {
        dispatch(stopIntegrationUpdate(params.id));

        throw error;
      });
  };

interface UpdateMetaAdsReportingIntegrationAdAccountsListParams {
  id: string;
  activeStatusRecord: ActiveStatusRecord;
}

export const updateMetaAdsReportingIntegrationAdAccountsActiveStatusRecord =
  (
    params: UpdateMetaAdsReportingIntegrationAdAccountsListParams,
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(startIntegrationUpdate(params.id));

    return DatasourcesAPI.updateMetaAdsReportingIntegrationAdAccountsActiveStatusRecord(
      params,
    )
      .then((integration) => {
        dispatch(addOrUpdateIntegration(integration));
      })
      .catch((error) => {
        dispatch(stopIntegrationUpdate(params.id));

        throw error;
      });
  };
