import { Box } from '@mui/material';
import { lazy, Suspense, useCallback, useMemo, useState } from 'react';
import {
  selectMetaIsLoading,
  selectMetaIsFailed,
  selectMetaIsLoaded,
  selectMetaAllDimensions,
  selectMetaConfig,
  selectMetaAllMetrics,
  selectIsTreeDataGridView,
  selectProbabilisticAttribution,
  selectFilters,
  selectConfigMetrics,
  selectConfigDimensions,
  selectConfigSorting,
  selectConfigRowHeight,
  selectConfigMetricsColoring,
  selectConfigColumnsWidth,
  selectCohortDateScale,
  selectCohortDateRange,
  selectRevenueDateScale,
  selectRevenueDateRange,
  selectConfigDataGridViewType,
  getStringFilterParams,
  getNumberFilterParams,
  updateColumns,
  updateConfigMetricsColoring,
  updateFilters,
  getMetricsMinMax,
  selectAdvaceSortableOptions,
  updateConfigColumnsWidth,
  reorderMetrics,
  reorderDimensions,
  getTotalData,
  getTreeData,
  getPage,
  updateSorting,
  deleteConfigDimension,
  deleteConfigMetric,
  QuickAccessFiltersAndSettings,
} from '@/widgets/calendarInsights';
import {
  DimensionTableHeaderNext,
  MetricTableHeaderNext,
  TableHeaderPropsProvider,
} from '@/widgets/DataTable';
import {
  FilterAndSettingsTriggerButton,
  FiltersAndSettingsPanel,
} from '@/widgets/FiltersAndSettings';
import type { DataGroup } from '@/widgets/TreeDataGrid';
import { AddColumnsNext } from '@/features/AddColumns';
import { ColumnsList } from '@/features/ColumnsList';
import type { MetricColoring } from '@/features/DataGridColoring';
import { AddThreshold, ThresholdsList } from '@/features/DataGridColoring';
import type { Filters } from '@/features/filters';
import {
  AddFilter,
  FiltersList,
  FiltersParamsProvider,
  FiltersProvider,
} from '@/features/filters';
import { useDataFetcher, useMetricsStabilizedValue } from '@/shared/hooks';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import type { SortingItem } from '@/shared/types';
import { DataGridApiRefProvider, FatalError, Loader, Panel } from '@/shared/ui';
import { ViewPanelToolbar } from '../ViewPanelToolbar/ViewPanelToolbar';

const DataTable = lazy(() =>
  import('@/widgets/DataTable').then(({ DataTable }) => {
    return { default: DataTable };
  }),
);
const TreeDataGrid = lazy(() =>
  import('@/widgets/TreeDataGrid').then(({ TreeDataGrid }) => {
    return { default: TreeDataGrid };
  }),
);

// Workaround for the issue with interactive element (add button) in the interactive element (dropdown)
// to handle use case when one dropdown open, and user click on another dropdown, the first one should be closed
const IGNORE_CLICK_CLASS_SELECTOR = 'add-button-ignore-click';

enum SettingGroups {
  COLUMNS = 'Columns',
  FILTERS = 'Filters',
  THRESHOLDS = 'Thresholds',
}

interface Props {
  refresher: number;
}

export function ViewPanel({ refresher }: Props) {
  const dispatch = useAppDispatch();
  const isColumnsMetaLoading = useAppSelector(selectMetaIsLoading);
  const isColumnsMetaFailed = useAppSelector(selectMetaIsFailed);
  const isColumnsMetaLoaded = useAppSelector(selectMetaIsLoaded);
  const isTreeDataGridView = useAppSelector(selectIsTreeDataGridView);
  const metaColumnsConfig = useAppSelector(selectMetaConfig);
  const allDimensions = useAppSelector(selectMetaAllDimensions);
  const allMetrics = useAppSelector(selectMetaAllMetrics);
  const metrics = useAppSelector(selectConfigMetrics);
  const dimensions = useAppSelector(selectConfigDimensions);
  const sorting = useAppSelector(selectConfigSorting);
  const rowHeight = useAppSelector(selectConfigRowHeight);
  const metricsColoring = useAppSelector(selectConfigMetricsColoring);
  const columnsWidth = useAppSelector(selectConfigColumnsWidth);
  const cohortDateScale = useAppSelector(selectCohortDateScale);
  const cohortDateRange = useAppSelector(selectCohortDateRange);
  const revenueDateScale = useAppSelector(selectRevenueDateScale);
  const revenueDateRange = useAppSelector(selectRevenueDateRange);
  const filters = useAppSelector(selectFilters);
  const probabilisticAttribution = useAppSelector(
    selectProbabilisticAttribution,
  );
  const dataGridViewType = useAppSelector(selectConfigDataGridViewType);
  const advacedSortableOptions = useAppSelector(selectAdvaceSortableOptions);
  const numberFilterParamsDependencies = useMemo(() => {
    return [
      dataGridViewType,
      dimensions,
      filters,
      allMetrics,
      allDimensions,
      cohortDateRange,
      cohortDateScale,
      revenueDateRange,
      revenueDateScale,
      probabilisticAttribution,
    ];
  }, [
    dataGridViewType,
    dimensions,
    filters,
    allMetrics,
    allDimensions,
    cohortDateRange,
    cohortDateScale,
    revenueDateRange,
    revenueDateScale,
    probabilisticAttribution,
  ]);
  const stringFilterParamsDependencies = useMemo(() => {
    return [
      filters,
      allMetrics,
      allDimensions,
      cohortDateRange,
      revenueDateRange,
    ];
  }, [filters, allMetrics, allDimensions, cohortDateRange, revenueDateRange]);
  const stableMetricsValue = useMetricsStabilizedValue(metrics);
  const handleGetMetricsMinMax = useCallback(
    () => {
      return dispatch(
        getMetricsMinMax({
          dimensions,
          metrics,
          filters,
          cohortDateRange,
          cohortDateScale,
          revenueDateRange,
          revenueDateScale,
          probabilisticAttribution,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      isTreeDataGridView,
      dimensions,
      stableMetricsValue,
      filters,
      cohortDateRange,
      cohortDateScale,
      revenueDateRange,
      revenueDateScale,
      probabilisticAttribution,
      refresher,
    ],
  );
  const { data: minMax } = useDataFetcher(handleGetMetricsMinMax);
  const [expandedGroup, setExpandedGroup] = useState<string>('');
  const [filtersAndSettingsPanelOpen, setFiltersAndSettingsPanelOpen] =
    useState(true);

  const handleReorderDimensions = (info: {
    id: string;
    oldIndex: number;
    newIndex: number;
  }) => {
    dispatch(reorderDimensions(info));
  };
  const handleReorderMetrics = (info: {
    id: string;
    oldIndex: number;
    newIndex: number;
  }) => {
    dispatch(reorderMetrics(info));
  };
  const handleUpdateColumnsWidth = useCallback(
    (columnName: string, newWidth?: number) => {
      dispatch(
        updateConfigColumnsWidth([
          {
            name: columnName,
            width: newWidth,
          },
        ]),
      );
    },
    [dispatch],
  );
  const handleGetTotalData = useCallback(
    () => {
      return dispatch(
        getTotalData({
          dimensions,
          metrics,
          cohortDateRange,
          revenueDateRange,
          filters,
          probabilisticAttribution,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      dimensions,
      stableMetricsValue,
      cohortDateRange,
      revenueDateRange,
      filters,
      probabilisticAttribution,
      refresher,
    ],
  );
  const handleGetRowsPage = useCallback(
    (offset?: number) => {
      return dispatch(
        getPage({
          dimensions,
          cohortDateScale,
          cohortDateRange,
          revenueDateScale,
          revenueDateRange,
          sorting,
          probabilisticAttribution,
          filters,
          offset,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      dimensions,
      stableMetricsValue,
      cohortDateScale,
      cohortDateRange,
      revenueDateScale,
      revenueDateRange,
      sorting,
      probabilisticAttribution,
      filters,
      refresher,
    ],
  );
  const handleGetTreeDataGridData = useCallback(
    (dataGroups: DataGroup[]) => {
      return dispatch(getTreeData(dataGroups));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      dimensions,
      stableMetricsValue,
      cohortDateScale,
      cohortDateRange,
      revenueDateScale,
      revenueDateRange,
      sorting,
      filters,
      probabilisticAttribution,
      refresher,
    ],
  );

  if (isColumnsMetaFailed) {
    return (
      <Panel
        sx={{
          p: 2,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <FatalError />
      </Panel>
    );
  }

  const handleGetNumberFilterParams = (id: string) => {
    return dispatch(
      getNumberFilterParams({
        filterName: id,
        dimensions,
        filters,
        allMetrics,
        allDimensions,
        cohortDateRange,
        cohortDateScale,
        revenueDateRange,
        revenueDateScale,
        probabilisticAttribution,
      }),
    );
  };
  const handleGetStringFilterParams = (id: string) => {
    return dispatch(
      getStringFilterParams({
        filterName: id,
        filters,
        allMetrics,
        allDimensions,
        cohortDateRange,
        revenueDateRange,
      }),
    );
  };
  const handleColumnsUpdate = (newColumns: {
    dimensions: string[];
    metrics: string[];
  }) => {
    dispatch(updateColumns(newColumns));
  };
  const handleActiveFiltersChange = (newFilters: Filters) => {
    dispatch(updateFilters(newFilters));
  };
  const handleColoredMetricsChange = (newMetricsColoring: MetricColoring[]) => {
    dispatch(updateConfigMetricsColoring(newMetricsColoring));
  };
  const handleConfigPanelOpen = () => {
    setFiltersAndSettingsPanelOpen(true);
  };
  const handleConfigPanelClose = () => {
    setFiltersAndSettingsPanelOpen(false);
  };
  const handleUpdateSorting = (column: string, newItem?: SortingItem) => {
    dispatch(updateSorting({ column, item: newItem }));
  };
  const handleDeleteDimension = (id: string) => {
    dispatch(deleteConfigDimension(id));
  };
  const handleDeleteMetric = (id: string) => {
    dispatch(deleteConfigMetric(id));
  };
  const handleDeleteFilter = (id: string) => {
    dispatch(updateFilters(filters.filter((f) => f.id !== id)));
  };

  return (
    <FiltersParamsProvider
      allDimensions={allDimensions}
      allMetrics={allMetrics}
      columnsConfig={metaColumnsConfig}
      numberFilterParamsDeps={numberFilterParamsDependencies}
      stringFilterParamsDeps={stringFilterParamsDependencies}
      getNumberFilterParams={handleGetNumberFilterParams}
      getStringFilterParams={handleGetStringFilterParams}
    >
      <FiltersAndSettingsPanel
        ignoreClickClassSelector={IGNORE_CLICK_CLASS_SELECTOR}
        expandedGroup={expandedGroup}
        onExpandedGroupChange={setExpandedGroup}
        quickAccessSlot={<QuickAccessFiltersAndSettings />}
        settingGroups={[
          {
            name: SettingGroups.COLUMNS,
            count: dimensions.length + metrics.length,
            addButton: (
              <AddColumnsNext
                className={IGNORE_CLICK_CLASS_SELECTOR}
                selectedDimensions={dimensions}
                selectedMetrics={metrics}
                isColumnsConfigLoaded={isColumnsMetaLoaded}
                allMetrics={allMetrics}
                allDimensions={allDimensions}
                columnsConfig={metaColumnsConfig}
                updateColumns={(newColumns) => {
                  handleColumnsUpdate(newColumns);
                  setExpandedGroup(SettingGroups.COLUMNS);
                }}
              />
            ),
            content: (
              <ColumnsList
                configLoaded={isColumnsMetaLoaded}
                columnsConfig={metaColumnsConfig}
                dimensions={dimensions}
                metrics={metrics}
                updateColumns={handleColumnsUpdate}
              />
            ),
          },
          {
            name: SettingGroups.FILTERS,
            count: filters.length,
            addButton: (
              <>
                {isColumnsMetaLoaded && (
                  <FiltersProvider
                    activeFilters={filters}
                    onChange={(newFilters) => {
                      handleActiveFiltersChange(newFilters);
                      setExpandedGroup(SettingGroups.FILTERS);
                    }}
                  >
                    <AddFilter className={IGNORE_CLICK_CLASS_SELECTOR} />
                  </FiltersProvider>
                )}
              </>
            ),
            content: (
              <FiltersProvider
                activeFilters={filters}
                onChange={handleActiveFiltersChange}
              >
                <FiltersList onItemDelete={handleDeleteFilter} />
              </FiltersProvider>
            ),
          },
          {
            name: SettingGroups.THRESHOLDS,
            count: metricsColoring.length,
            addButton: (
              <AddThreshold
                className={IGNORE_CLICK_CLASS_SELECTOR}
                activeMetrics={metrics}
                minMax={minMax}
                metricsColoring={metricsColoring}
                columnsConfig={metaColumnsConfig}
                isColumnsConfigLoaded={isColumnsMetaLoaded}
                onColoredMetricsChange={(newMetricsColoring) => {
                  handleColoredMetricsChange(newMetricsColoring);
                  setExpandedGroup(SettingGroups.THRESHOLDS);
                }}
              />
            ),
            content: (
              <ThresholdsList
                metricsColoring={metricsColoring}
                columnsConfig={metaColumnsConfig}
                minMax={minMax}
                onMetricsColoringChange={handleColoredMetricsChange}
              />
            ),
          },
        ]}
        open={filtersAndSettingsPanelOpen}
        onClose={handleConfigPanelClose}
      />
      <Panel
        sx={{
          p: 2,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <DataGridApiRefProvider>
          <FiltersProvider
            activeFilters={filters}
            onChange={handleActiveFiltersChange}
          >
            <TableHeaderPropsProvider
              columnsConfig={metaColumnsConfig}
              advancedSortableOptions={advacedSortableOptions}
              minMax={minMax}
              sorting={sorting}
              filters={filters}
              metricsColoring={metricsColoring}
              updateMetricsColoring={handleColoredMetricsChange}
              updateFilters={handleActiveFiltersChange}
              updateSorting={handleUpdateSorting}
              onDimensionDelete={handleDeleteDimension}
              onMetricDelete={handleDeleteMetric}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                {!filtersAndSettingsPanelOpen && (
                  <FilterAndSettingsTriggerButton
                    onClick={handleConfigPanelOpen}
                  />
                )}
                <ViewPanelToolbar />
              </Box>
              {isColumnsMetaLoaded && (
                <Suspense fallback={<Loader active={!isColumnsMetaLoading} />}>
                  {isTreeDataGridView ? (
                    <TreeDataGrid
                      columnsWidth={columnsWidth}
                      columnsConfig={metaColumnsConfig}
                      dimensions={dimensions}
                      metrics={metrics}
                      rowHeight={rowHeight}
                      minMax={minMax}
                      metricsColoring={metricsColoring}
                      reorderMetrics={handleReorderMetrics}
                      getData={handleGetTreeDataGridData}
                      getTotal={handleGetTotalData}
                      updateColumnsWidth={handleUpdateColumnsWidth}
                      MetricHeaderComponent={MetricTableHeaderNext}
                    />
                  ) : (
                    isColumnsMetaLoaded && (
                      <DataTable
                        columnsWidth={columnsWidth}
                        columnsConfig={metaColumnsConfig}
                        dimensions={dimensions}
                        metrics={metrics}
                        rowHeight={rowHeight}
                        minMax={minMax}
                        metricsColoring={metricsColoring}
                        getRowsPage={handleGetRowsPage}
                        getTotal={handleGetTotalData}
                        reorderDimensions={handleReorderDimensions}
                        reorderMetrics={handleReorderMetrics}
                        updateColumnsWidth={handleUpdateColumnsWidth}
                        DimensionHeaderComponent={DimensionTableHeaderNext}
                        MetricHeaderComponent={MetricTableHeaderNext}
                      />
                    )
                  )}
                </Suspense>
              )}
            </TableHeaderPropsProvider>
          </FiltersProvider>
        </DataGridApiRefProvider>
      </Panel>
    </FiltersParamsProvider>
  );
}
