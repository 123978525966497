import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useViewInitializationStateMachineContext } from '@/shared/ui';
import type { SavedViewRecord } from '../../model/types';

interface Params {
  viewsLoaded: boolean;
  metaLoaded: boolean;
  applyView: (view: SavedViewRecord) => void;
  applyVendorDefault: () => void;
  views: SavedViewRecord[];
}

export const useViewInitialization = ({
  applyView,
  applyVendorDefault,
  views,
  viewsLoaded,
  metaLoaded,
}: Params) => {
  const executed = useRef(false);
  const { canApplyDefaultView } = useViewInitializationStateMachineContext();
  const [searchParams] = useSearchParams();

  useEffect(
    () => {
      if (!viewsLoaded || !metaLoaded || executed.current) {
        return;
      }

      const savedViewId = searchParams.get('savedView');
      const defaultView = views.find((view) => {
        return view.savedView.isDefault;
      });

      if (savedViewId) {
        const view = views.find((view) => {
          return view.uuid === savedViewId;
        });

        if (view) {
          applyView(view);
        }
      } else if (defaultView && canApplyDefaultView) {
        applyView(defaultView);
      } else {
        applyVendorDefault();
      }

      executed.current = true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewsLoaded, metaLoaded, views],
  );
};
