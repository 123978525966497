import { useState } from 'react';
import type { DownloadData } from './types';

interface Params {
  getAllDataRows: () => Promise<DownloadData>;
  downloadFile: (response: DownloadData) => void;
}

export function useDownloadData({ getAllDataRows, downloadFile }: Params) {
  const [loading, setLoading] = useState(false);

  return {
    loading,
    download: () => {
      setLoading(true);
      getAllDataRows().then((respose) => {
        downloadFile(respose);
        setLoading(false);
      });
    },
  };
}
