import { Analytics } from '@/shared/lib';
import { Label, ToggleButtonGroup } from '@/shared/ui';
import type { ColorLevel } from '../../model/types';
import { PRESET_COLORS } from '../ColorPicker/presetColors';

const options = [
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: '6',
    value: 6,
  },
  {
    label: '7',
    value: 7,
  },
];

interface Props {
  value: ColorLevel[];
  onChange: (v: ColorLevel[]) => void;
}

export function LevelsNumberSelectNext({ value, onChange }: Props) {
  const handleLevelsNumberChange = (newLevelsNumber: number) => {
    if (newLevelsNumber === value.length) {
      return;
    }

    if (value.length < newLevelsNumber) {
      const newValue = [
        ...value,
        ...Array(newLevelsNumber - value.length)
          .fill(0)
          .map(() => {
            return {
              color: PRESET_COLORS[0],
              value: value.at(-1)?.value ?? 0,
            };
          }),
      ];

      onChange(remapToConsequentGradientColors(newValue));
    } else {
      onChange(
        remapToConsequentGradientColors(value.slice(0, newLevelsNumber)),
      );
    }
    Analytics.sendFeatureUsage('metric_coloring', 'change_levels_count', {
      count: newLevelsNumber,
    });
  };

  return (
    <Label text="Number of levels: " sx={{ fontWeight: 400 }}>
      <ToggleButtonGroup
        fullWidth
        options={options}
        value={value.length}
        onChange={handleLevelsNumberChange}
      />
    </Label>
  );
}

const remapToConsequentGradientColors = (levels: ColorLevel[]) => {
  return levels.map((level, ind) => {
    return {
      ...level,
      color:
        PRESET_COLORS[
          Math.floor((ind + 1) * ((PRESET_COLORS.length - 1) / levels.length))
        ],
    };
  });
};
