import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function CohortChartIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3 3V21H21"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3343 8.33333V17H7.00098V12.7434V8.33333H11.3343ZM11.3343 8.33333V4H15.6676M11.3343 8.33333H20.001V4H15.6676M7.00098 12.6667H15.6676V4"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
