import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button as SharedButton } from '@/shared/ui';
import type { FilterConfig, FilterValue } from '../../model/types';
import { FilterItem } from '../FilterItem/FilterItem';
import { useFiltersContext } from '../FiltersContext/FiltersContext';

const Button = styled(SharedButton)(({ theme }) => ({
  padding: `${theme.spacing(1.25)} ${theme.spacing(2)}`,
  lineHeight: '1.125rem',
  borderRadius: theme.shape.borderRadius * 2,
}));

interface Props {
  config?: FilterConfig;
  onDelete: (id: string) => void;
}

export function ActiveFilter({ config, onDelete }: Props) {
  const { filtersMap, updateFilters, submitFilters, resetToInitialFilters } =
    useFiltersContext();

  if (!config) {
    return null;
  }

  const handleChange = (newValue?: FilterValue) => {
    updateFilters(config.id, newValue);
  };
  const handleDelete = () => {
    onDelete(config.id);
  };

  return (
    <FilterItem
      slotProps={{
        root: {
          component: 'li',
        },
      }}
      value={filtersMap[config.id]}
      filterConfig={config}
      actions={
        <Box sx={{ flexGrow: 1, display: 'flex', gap: 1 }}>
          <Button onClick={submitFilters}>Apply</Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={resetToInitialFilters}
          >
            Cancel
          </Button>
        </Box>
      }
      onChange={handleChange}
      onDelete={handleDelete}
    />
  );
}
