import { useCallback, useEffect, useRef, useState } from 'react';

type GetPageFetcher<T> = (
  offset?: number,
) => Promise<{ data: T[]; hasNextPage: boolean }>;

interface Params<T> {
  getPage: GetPageFetcher<T>;
}

export const usePagedDataFetcher = <T>({ getPage }: Params<T>) => {
  const [loading, setLoading] = useState(true);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [data, setData] = useState<T[]>([]);
  const latestFetcher = useRef<GetPageFetcher<T>>();
  const getDataPage = useCallback(
    (oldData: T[]) => {
      getPage(oldData.length)
        .then((response) => {
          if (getPage !== latestFetcher.current) {
            return;
          }

          setData([...oldData, ...response.data]);
          setHasNextPage(response.hasNextPage);
        })
        .finally(() => {
          if (getPage !== latestFetcher.current) {
            return;
          }

          setLoading(false);
        });
    },
    [getPage],
  );
  const getNextPage = useCallback(() => {
    if (!hasNextPage) {
      return;
    }

    setLoading(true);
    getDataPage(data);
  }, [data, hasNextPage, getDataPage]);

  useEffect(
    () => {
      setData([]);
      setLoading(true);
      setHasNextPage(true);
      latestFetcher.current = getPage;
      getDataPage([]);
    },
    // Need to remove getNextPage from dependencies since it depends on data to avoid infinite update
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getPage],
  );

  return {
    loading,
    data,
    getNextPage,
  };
};
