import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { DataType, FormatType } from '@/shared/hooks';
import { Analytics } from '@/shared/lib';
import type { ColorLevel } from '../../model/types';
import { ColorLevelEditor } from '../ColorLevelEditor/ColorLevelEditor';
import { PRESET_COLORS } from '../ColorPicker/presetColors';
import { LevelsNumberSelect } from '../LevelsNumberSelect/LevelsNumberSelect';

const List = styled('ul')(({ theme }) => ({
  padding: 0,
  margin: 0,
  listStyle: 'none',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

interface Props {
  type: DataType;
  formatType: FormatType;
  value: ColorLevel[];
  onChange: (v: ColorLevel[]) => void;
}

export function LevelsList({ type, formatType, value, onChange }: Props) {
  const handleLevelChange =
    (levelIndex: number) => (updatedLevel: ColorLevel) => {
      const newValue = [...value.slice(0, levelIndex)];

      if (levelIndex > 0 && updatedLevel.value < newValue.at(-1)?.value!) {
        newValue.push({
          ...updatedLevel,
          value: newValue.at(-1)?.value!,
        });
      } else {
        newValue.push(updatedLevel);
      }

      for (let i = levelIndex + 1; i < value.length; i++) {
        const prevLevelValue = newValue.at(-1)?.value!;

        if (value[i].value < prevLevelValue) {
          newValue.push({
            ...value[i],
            value: prevLevelValue,
          });
        } else {
          newValue.push(value[i]);
        }
      }

      onChange(newValue);
    };
  const handleLevelsNumberChange = (newLevelsNumber: number) => {
    if (newLevelsNumber === value.length) {
      return;
    }

    if (value.length < newLevelsNumber) {
      const newValue = [
        ...value,
        ...Array(newLevelsNumber - value.length)
          .fill(0)
          .map(() => {
            return {
              color: PRESET_COLORS[0],
              value: value.at(-1)?.value ?? 0,
            };
          }),
      ];

      onChange(remapToConsequentGradientColors(newValue));
    } else {
      onChange(
        remapToConsequentGradientColors(value.slice(0, newLevelsNumber)),
      );
    }
    Analytics.sendFeatureUsage('metric_coloring', 'change_levels_count', {
      count: newLevelsNumber,
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
      <LevelsNumberSelect
        value={value.length}
        onChange={handleLevelsNumberChange}
      />
      <List>
        {value.map((level, index) => {
          const isLastLevel = index === value.length - 1;

          return (
            <ColorLevelEditor
              key={`${level.value}-${level.color}-${index}`}
              type={type}
              formatType={formatType}
              previousLevelValue={
                index === 0 ? -Infinity : value.at(index - 1)?.value!
              }
              isLastLevel={isLastLevel}
              value={level}
              onChange={handleLevelChange(index)}
            />
          );
        })}
      </List>
    </Box>
  );
}

const remapToConsequentGradientColors = (levels: ColorLevel[]) => {
  return levels.map((level, ind) => {
    return {
      ...level,
      color:
        PRESET_COLORS[
          Math.floor((ind + 1) * ((PRESET_COLORS.length - 1) / levels.length))
        ],
    };
  });
};
