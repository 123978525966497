import {
  ToggleButton,
  ToggleButtonGroup as MUIToggleButtonGroup,
  toggleButtonGroupClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode, MouseEvent } from 'react';

const StyledToggleButtonGroup = styled(MUIToggleButtonGroup)(() => ({
  [`& .${toggleButtonGroupClasses.middleButton}, & .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: 0,
    },
}));
const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  textTransform: 'none',

  '&.Mui-selected': {
    color: '#063FFE',
    backgroundColor: '#EBEFFF',
  },

  '&.Mui-disabled': {
    pointerEvents: 'auto',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

interface Props {
  fullWidth?: boolean;
  options: {
    label: ReactNode;
    value: any;
    title?: string;
    disabled?: boolean;
  }[];
  value?: any;
  onChange: (value: any) => void;
}

export function ToggleButtonGroup({
  fullWidth,
  options,
  value,
  onChange,
}: Props) {
  const handleChange = (event: MouseEvent<HTMLElement>, newValue?: any) => {
    event.stopPropagation();

    if (!newValue) {
      return;
    }

    onChange(newValue);
  };

  return (
    <StyledToggleButtonGroup
      fullWidth={fullWidth}
      exclusive
      size="small"
      value={value}
      onChange={handleChange}
    >
      {options.map((option) => (
        <StyledToggleButton
          key={option.value}
          value={option.value}
          title={option.title}
          disabled={option.disabled}
        >
          {option.label}
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
}
