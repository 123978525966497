import { useMemo } from 'react';
import { isAllValuesSelected } from '../../model/isAllValuesSelected';
import type { Filters } from '../../model/types';
import { useFiltersContext } from '../FiltersContext/FiltersContext';
import { useFiltersParamsContext } from '../FiltersParamsContext/FiltersParamsContext';

export const useActiveFiltersCount = (
  activeFilters: Filters,
): {
  dimensionFiltersCount: number;
  metricFiltersCount: number;
} => {
  const { dimensionConfigs, metricConfigs, paramsData } =
    useFiltersParamsContext();
  const { filtersMap } = useFiltersContext();
  const dimensionFiltersCount = useMemo(() => {
    const dimensionIds = new Set(dimensionConfigs.map((config) => config.id));

    return activeFilters.filter((filter) => {
      if (!dimensionIds.has(filter.id)) {
        return false;
      }

      return (
        !!filtersMap[filter.id] &&
        !isAllValuesSelected(
          paramsData[filter.id]?.type!,
          filtersMap[filter.id],
          paramsData[filter.id]?.params,
        )
      );
    }).length;
  }, [activeFilters, dimensionConfigs, filtersMap, paramsData]);
  const metricFiltersCount = useMemo(() => {
    const metricIds = new Set(metricConfigs.map((config) => config.id));

    return activeFilters.filter((filter) => {
      if (!metricIds.has(filter.id)) {
        return false;
      }

      return (
        !!filtersMap[filter.id] &&
        !isAllValuesSelected(
          paramsData[filter.id]?.type!,
          filtersMap[filter.id],
          paramsData[filter.id]?.params,
        )
      );
    }).length;
  }, [activeFilters, metricConfigs, filtersMap, paramsData]);

  return {
    dimensionFiltersCount,
    metricFiltersCount,
  };
};
