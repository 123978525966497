import type { CSSProperties, FC } from 'react';
import { useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';

interface Props<T, K> {
  options: T[];
  ItemRenderer: FC<
    K & {
      style: CSSProperties;
      value: T;
      setHeight: (height: number) => void;
    }
  >;
  itemProps: K;
  getDefaultOptionHeight: (option: T) => number;
}

export function VirtualizedList<T, K>({
  options,
  ItemRenderer,
  itemProps,
  getDefaultOptionHeight,
}: Props<T, K>) {
  const listRef = useRef<VariableSizeList>(null);
  const optionHeights = useRef<Record<number, number>>({});

  const handleSetOptionHeight = (index: number, height: number) => {
    const wrapperOffset = 8 + 8 + 6; // paddingTop + paddingBottom + marginBottom

    listRef.current?.resetAfterIndex(0);
    optionHeights.current = {
      ...optionHeights.current,
      [index]: height + wrapperOffset,
    };
  };
  const getOptionHeight = (index: number) => {
    if (!optionHeights.current[index]) {
      return getDefaultOptionHeight(options[index]);
    }

    return optionHeights.current[index];
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <VariableSizeList
          ref={listRef}
          height={height}
          width={width}
          itemCount={options.length}
          itemSize={getOptionHeight}
        >
          {({ style, index }) => {
            const option = options[index];

            return (
              <ItemRenderer
                key={index}
                style={style}
                value={option}
                setHeight={(height) => handleSetOptionHeight(index, height)}
                {...itemProps}
              />
            );
          }}
        </VariableSizeList>
      )}
    </AutoSizer>
  );
}
