import { Box, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMemo } from 'react';
import type { FilterValue, NumberFilterValue } from '@/features/filters';
import {
  isMetricFilter,
  useFiltersContext,
  useFiltersParamsContext,
  NumberFilter,
} from '@/features/filters';
import type { MetricDataFormatType } from '@/entities/columnsConfig';
import { toFormatType } from '@/entities/columnsConfig';
import type { DataType } from '@/shared/hooks';
import { isLoading } from '@/shared/lib';
import { Button as SharedButton, Loader } from '@/shared/ui';

const Button = styled(SharedButton)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  lineHeight: '1.125rem',
  borderRadius: theme.shape.borderRadius * 2,
}));

interface Props {
  id: string;
  onClose: () => void;
}

export function AddFilter({ id, onClose }: Props) {
  const { filtersMap, updateFilters, submitFilters, resetToInitialFilters } =
    useFiltersContext();
  const { paramsData, metricConfigs } = useFiltersParamsContext();
  const filterConfig = useMemo(
    () => metricConfigs.find((config) => config.id === id),
    [metricConfigs, id],
  );

  if (!filterConfig) {
    return null;
  }

  if (!isMetricFilter(filterConfig.type)) {
    return <p>Unknown filter type: {filterConfig.type}</p>;
  }

  const loading =
    !paramsData[filterConfig.id] ||
    isLoading(paramsData[filterConfig.id].status);

  const handleChange = (id: string) => (value?: FilterValue) => {
    updateFilters(id, value);
  };

  const handleApply = () => {
    submitFilters();
    onClose();
  };
  const handleCancel = () => {
    resetToInitialFilters();
    onClose();
  };

  return (
    <Box sx={{ width: 304 }}>
      <NumberFilter
        sx={{
          p: 2,
          gap: 2,
        }}
        id={filterConfig.id}
        type={filterConfig.type as DataType}
        formatType={toFormatType(filterConfig.dataType as MetricDataFormatType)}
        value={filtersMap[id] as NumberFilterValue}
        onChange={handleChange(id)}
      />
      <Divider />
      <Box sx={{ p: 2, display: 'flex', gap: 2 }}>
        <Button onClick={handleApply}>Apply</Button>
        <Button color="secondary" variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
      </Box>
      <Loader active={loading} />
    </Box>
  );
}
