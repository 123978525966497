import { Box } from '@mui/material';
import { LifetimeSelectNext } from '@/features/LifetimeSelect';
import { ProbabilisticAttributionSwitcher } from '@/features/ProbabilisticAttribution';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import type {
  DateScale,
  Lifetime,
  SerializedDateRangeValueObject,
} from '@/shared/types';
import { DateScaleSelectNext, DateRangeSelectNext } from '@/shared/ui';
import {
  selectDateScale,
  selectDateRange,
  selectLifetime,
  selectProbabilisticAttribution,
  updateProbabilisticAttribution,
  updateDateScale,
  updateDateRange,
  updateLifetime,
} from '../../model/slice';

export function QuickAccessFiltersAndSettings() {
  const dispatch = useAppDispatch();
  const dateScale = useAppSelector(selectDateScale);
  const dateRange = useAppSelector(selectDateRange);
  const lifetime = useAppSelector(selectLifetime);
  const probabilisticAttribution = useAppSelector(
    selectProbabilisticAttribution,
  );

  const handleDateScaleChange = (newScale: DateScale) => {
    dispatch(updateDateScale(newScale));
  };
  const handleDateRangeChange = (
    newDateRange: SerializedDateRangeValueObject,
  ) => {
    dispatch(updateDateRange(newDateRange));
  };
  const handleLifetimeChange = (newLifetime: Lifetime) => {
    dispatch(updateLifetime(newLifetime));
  };
  const handleProbabilisticAttributionChange = (checked: boolean) => {
    dispatch(updateProbabilisticAttribution(checked));
  };

  return (
    <Box
      sx={{ px: 2, pb: 2, display: 'flex', flexDirection: 'column', gap: 2 }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <DateScaleSelectNext
            value={dateScale}
            onChange={handleDateScaleChange}
          />
          <LifetimeSelectNext
            value={lifetime}
            onChange={handleLifetimeChange}
          />
        </Box>
        <DateRangeSelectNext
          value={dateRange}
          onChange={handleDateRangeChange}
        />
      </Box>
      <ProbabilisticAttributionSwitcher
        value={probabilisticAttribution}
        onChange={handleProbabilisticAttributionChange}
      />
    </Box>
  );
}
