import { Box } from '@mui/material';
import { useMemo } from 'react';
import type { FilterConfig } from '../../model/types';
import { GroupItem } from './GroupItem';

export interface Group {
  name: string;
  configs: FilterConfig[];
  order: number;
}

interface Props {
  filterConfigs: FilterConfig[];
}

export function AddFilterList({ filterConfigs }: Props) {
  const groupedFilters: Group[] = useMemo(() => {
    const groupsMap = new Map<
      string,
      { items: FilterConfig[]; order: number }
    >();

    filterConfigs.forEach((filter) => {
      const group = groupsMap.get(filter.group) || {
        order: Infinity,
        items: [],
      };

      group.items.push(filter);
      group.order = Math.min(filter.order, group.order);

      groupsMap.set(filter.group, group);
    });

    const result: { name: string; configs: FilterConfig[]; order: number }[] =
      [];

    groupsMap.forEach((value, key) => {
      result.push({
        name: key,
        configs: value.items,
        order: value.order,
      });
    });

    return result.sort((aGroup, bGroup) => {
      return aGroup.order - bGroup.order;
    });
  }, [filterConfigs]);

  return (
    <Box
      component="ul"
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'auto',
        padding: 0,
        margin: 0,

        '& ul': {
          padding: 0,
        },
      }}
    >
      {groupedFilters.map((group) => {
        return <GroupItem key={group.name} value={group} />;
      })}
    </Box>
  );
}
