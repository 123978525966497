export const APP_STORE_CONNECT_REPORTING_INTEGRATION_ID =
  '8d7aafe0-46e9-4333-84c1-c993afba170d';
export const APPLE_SEARCH_ADS_REPORTING_INTEGRATION_ID =
  'b76bdf58-356f-49b8-b42d-a35a05ae3fb0';
export const APPLOVIN_ADS_REPORTING_INTEGRATION_ID =
  'bb9e71f1-3d96-4558-877b-054153b34a24';
export const APPLOVIN_MAX_REPORTING_INTEGRATION_ID =
  '911207e9-c915-449b-82a6-d999641b2af2';
export const APPSFLYER_REPORTING_INTEGRATION_ID =
  '43f82348-67ce-4f27-9a0c-a77ed28e0748';
export const GOOGLE_ADS_REPORTING_INTEGRATION_ID =
  '8042f2a8-a766-4997-a553-2e2f674d6272';
export const GOOGLE_ANALYTICS_REPORTING_INTEGRATION_ID =
  'b70b3d3e-c70c-49af-8e04-1b0b73a25f51';
export const GOOGLE_PLAY_CONSOLE_REPORTING_INTEGRATION_ID =
  '18f89e52-2a81-416f-9608-cc5c48751e83';
export const IRON_SOURCE_REPORTING_INTEGRATION_ID =
  '34bef7b3-9ff3-4f19-a653-84914dba1f34';
export const LIFTOFF_REPORTING_INTEGRATION_ID =
  '53169348-3468-4c2a-b67c-fed4afc739d5';
export const MOLOCO_ADS_REPORTING_INTEGRATION_ID =
  'd9fa8143-bb25-4b36-a2d9-123d868a131a';
export const META_ADS_REPORTING_INTEGRATION_ID =
  'a9e1e1d1-f8ad-449e-a47a-31209d3fab66';
export const MINTEGRAL_REPORTING_INTEGRATION_ID =
  '6bc60ff5-9b6c-40cc-bd23-4aac767b614e';
export const PAY_PAL_REPORTING_INTEGRATION_ID =
  '14ae0faa-0d7b-4edd-b29e-3ae82db8c1f5';
export const SNAPCHAT_ADS_REPORTING_INTEGRATION_ID =
  'd9697ac2-dbb9-44bf-9f4f-18a0c7df4560';
export const STRIPE_REPORTING_INTEGRATION_ID =
  '897b714c-4902-42e3-ac67-5451b2f808ef';
export const TAPJOY_REPORTING_INTEGRATION_ID =
  'fbdfb514-4374-44f1-ba4d-af0724827b09';
export const TIK_TOK_FOR_BUSINESS_REPORTING_INTEGRATION_ID =
  'c2caa39c-2f43-4693-a49c-b6902c94859f';
export const UNITY_ADS_REPORTING_INTEGRATION_ID =
  '11f5d130-5f80-4890-a073-fdc13cdd62b2';
export const X_ADS_REPORTING_INTEGRATION_ID =
  'c19f4688-b05d-423a-b229-e32b75e197c6';
export const PINTEREST_REPORTING_INTEGRATION_ID =
  'd97a5ed0-0c4c-43fc-b923-c57362f1d806';
