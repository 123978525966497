import { buttonClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button as SharedButton } from '@/shared/ui';
import { useSavePresetContext } from '../SavePresetContext/SavePresetContext';

const Button = styled(SharedButton)(({ theme }) => ({
  color: '#7E828F',
  border: '1px solid #BBBEC8',
  borderRadius: theme.shape.borderRadius * 2,
  padding: `${theme.spacing(0.875)} ${theme.spacing(1.375)}`,

  '&:hover': {
    color: '#666666',
    backgroundColor: '#6666660a',
    borderColor: '#666666',
  },
}));

export function SavePresetButton() {
  const { openSavePresetDialog } = useSavePresetContext();

  return (
    <Button
      sx={{
        color: '#7E828F',
        border: '1px solid #BBBEC8',
        borderRadius: 2,
        lineHeight: '1.375rem',
        py: 0.875,
        px: 1.375,
        width: 128,

        '&:hover': {
          color: '#666666',
          backgroundColor: '#6666660a',
          borderColor: '#666666',
        },

        [`& .${buttonClasses.startIcon}`]: {
          marginRight: 0.5,
        },
      }}
      title="Refresh view data"
      color="secondary"
      variant="outlined"
      startIcon={
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17 21V14C17 13.7348 16.8946 13.4804 16.7071 13.2929C16.5196 13.1054 16.2652 13 16 13H8C7.73478 13 7.48043 13.1054 7.29289 13.2929C7.10536 13.4804 7 13.7348 7 14V21M7 3V7C7 7.26522 7.10536 7.51957 7.29289 7.70711C7.48043 7.89464 7.73478 8 8 8H15M15.2 3C15.7275 3.00751 16.2307 3.22317 16.6 3.6L20.4 7.4C20.7768 7.76926 20.9925 8.27246 21 8.8V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H15.2Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      onClick={openSavePresetDialog}
    >
      Save Preset
    </Button>
  );
}
