import { Box } from '@mui/material';
import {
  updateConfigColumnsWidth,
  updateConfigRowHeight,
  updateDataGridViewType,
  selectIsTreeDataGridView,
  selectConfigRowHeight,
  selectConfigDataGridViewType,
} from '@/widgets/calendarInsights';
import { DataGridAutosizeNext } from '@/features/DataGridAutosize';
import { DataGridTypeSwitcherNext } from '@/features/DataGridTypeSwitcher';
import type { DataGridViewType } from '@/features/DataGridTypeSwitcher';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { DensitySwitcherNext } from '@/shared/ui';

export function ViewPanelToolbar() {
  const dispatch = useAppDispatch();
  const rowHeight = useAppSelector(selectConfigRowHeight);
  const isTreeDataGridView = useAppSelector(selectIsTreeDataGridView);
  const dataGridViewType = useAppSelector(selectConfigDataGridViewType);

  const handleRowHeightChange = (size: number) => {
    dispatch(updateConfigRowHeight(size));
  };
  const handleColumnsResize = (updates: { name: string; width: number }[]) => {
    dispatch(updateConfigColumnsWidth(updates));
  };
  const handleDataGridViewTypeChange = (newViewType: DataGridViewType) => {
    dispatch(updateDataGridViewType(newViewType));
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 2,
        height: 40,
      }}
    >
      <DataGridTypeSwitcherNext
        value={dataGridViewType}
        onChange={handleDataGridViewTypeChange}
      />
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
        <DensitySwitcherNext
          value={rowHeight}
          onChange={handleRowHeightChange}
        />
        {!isTreeDataGridView && (
          <DataGridAutosizeNext onColumnsResize={handleColumnsResize} />
        )}
      </Box>
    </Box>
  );
}
