import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ROW_DETAILS_HEIGHT } from '@/shared/ui';
import { useDataTableContext } from '../DataTableContext/DataTableContext';

type ImageWrapperProps = BoxProps & { expanded: boolean };
const ImageWrapper = styled(
  ({ expanded, ...otherProps }: ImageWrapperProps) => {
    return <Box {...otherProps} />;
  },
)(({ theme, expanded }) => ({
  cursor: 'zoom-in',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0.25),
  height: '100%',
  aspectRatio: '1 / 1',

  ...(expanded && {
    cursor: 'zoom-out',
    position: 'absolute',
    top: '100%',
    width: '100%',
    height: ROW_DETAILS_HEIGHT,
    padding: theme.spacing(1),
  }),
}));
const Image = styled('img')(() => ({
  height: '100%',
  width: '100%',
  aspectRatio: '1 / 1',
  objectFit: 'contain',
}));

interface Props {
  label: string;
  rowId: number;
  url: string;
}

export function ImageMediaCell({ label, rowId, url }: Props) {
  const { detailPanelExpandedRowIds, toggleRowDetails } = useDataTableContext();
  const imageExananded = detailPanelExpandedRowIds?.[0] === rowId;

  const handleImageExpand = () => {
    toggleRowDetails(rowId);
  };

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        gap: 0.5,
        alignItems: 'center',
        position: 'relative',
      }}
    >
      {url && (
        <ImageWrapper expanded={imageExananded} onClick={handleImageExpand}>
          <Image src={url} />
        </ImageWrapper>
      )}
      <Box
        title={label}
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {label}
      </Box>
    </Box>
  );
}
