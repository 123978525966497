import { Backdrop, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import {
  Link as RouterLink,
  Navigate,
  Outlet,
  useLocation,
} from 'react-router-dom';
import { Page, Title } from '@/features/auth';
import {
  selectIsAuthorized,
  selectIsSessionLoading,
  selectIsSessionRequested,
  setupApp,
} from '@/entities/session';
import { ROUTES } from '@/shared/constants';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { Spinner } from '@/shared/ui';

const Link = styled(RouterLink)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.text.primary,
}));

export function AuthGuard() {
  const dispath = useAppDispatch();
  const isSessionRequested = useAppSelector(selectIsSessionRequested);
  const isSessionLoading = useAppSelector(selectIsSessionLoading);
  const isAuthorized = useAppSelector(selectIsAuthorized);
  const location = useLocation();

  useEffect(() => {
    if (isSessionRequested) {
      return;
    }

    dispath(setupApp());
  }, [isSessionRequested, dispath]);

  if (isSessionLoading || !isSessionRequested) {
    return (
      <Backdrop open>
        <Spinner />
      </Backdrop>
    );
  } else if (!isAuthorized) {
    if (isExpiredOTP(location.hash)) {
      return (
        <Page>
          <Title>Link expired</Title>
          <Typography>The link you clicked has expired.</Typography>
          <Typography>
            Please{' '}
            <Link sx={{ color: 'primary.main' }} to={ROUTES.passwordReset}>
              request a new one
            </Link>
            .
          </Typography>
        </Page>
      );
    }

    return <Navigate to={ROUTES.signin} />;
  }

  return <Outlet />;
}

const isExpiredOTP = (hash: string) => {
  const parsedHash = new URLSearchParams(hash.substring(1));

  return (
    parsedHash.get('error') === 'access_denied' &&
    parsedHash.get('error_code') === 'otp_expired'
  );
};
