import { TextField } from '@mui/material';
import type { ChangeEvent } from 'react';
import { forwardRef } from 'react';
import type { NumericFormatProps } from 'react-number-format';
import { NumericFormat } from 'react-number-format';
import type { InputComponentProps } from '../../model/types';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  min: number;
  max: number;
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        fixedDecimalScale
        decimalScale={2}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        valueIsNumericString
        suffix="%"
      />
    );
  },
);

export function PercentageInput({
  textFieldProps,
  allowEmpty,
  placeholder,
  disabled,
  label,
  step,
  min,
  max,
  value,
  onChange,
  onBlur,
}: InputComponentProps) {
  const {
    InputProps,
    inputProps,
    onChange: onChangeTextField,
    ...otherProps
  } = textFieldProps ?? {};

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (allowEmpty && event.target.value === '') {
      onChange?.(undefined);

      return;
    }

    const numberValue = +event.target.value;

    onChangeTextField?.(event);
    onChange?.(isNaN(numberValue) ? 0 : numberValue / 100);
  };

  return (
    <TextField
      size="small"
      disabled={disabled}
      placeholder={placeholder}
      label={label}
      InputProps={{
        inputComponent: NumericFormatCustom as any,
        ...InputProps,
      }}
      variant="outlined"
      inputProps={{
        step,
        min,
        max,
        ...inputProps,
      }}
      value={value * 100}
      onBlur={onBlur}
      onChange={handleChange}
      {...otherProps}
    />
  );
}
