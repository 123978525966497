import CheckIcon from '@mui/icons-material/Check';
import { IconButton as MUIIconButton, SvgIcon, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';

const IconButton = styled(MUIIconButton)(({ theme }) => ({
  borderRadius: 4,
  backgroundColor: theme.palette.grey[200],
}));

interface Props {
  disabled?: boolean;
  copyContent: string;
}

export function CopyButton({ disabled, copyContent }: Props) {
  const [successfullyCopied, setSuccessfullyCopied] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (timeoutId != null) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  const handleCopy = () => {
    navigator.clipboard.writeText(copyContent);

    setSuccessfullyCopied(true);
    if (timeoutId != null) {
      clearTimeout(timeoutId);
    }
    setTimeoutId(
      setTimeout(() => {
        setSuccessfullyCopied(false);
      }, 2000),
    );
  };

  return (
    <Tooltip title={getTooltipText(!!disabled, successfullyCopied)}>
      <div>
        <IconButton disabled={disabled} size="small" onClick={handleCopy}>
          {successfullyCopied ? (
            <CheckIcon fontSize="small" color="success" />
          ) : (
            <SvgIcon fontSize="small">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 16C2.9 16 2 15.1 2 14V4C2 2.9 2.9 2 4 2H14C15.1 2 16 2.9 16 4M10 8H20C21.1046 8 22 8.89543 22 10V20C22 21.1046 21.1046 22 20 22H10C8.89543 22 8 21.1046 8 20V10C8 8.89543 8.89543 8 10 8Z"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SvgIcon>
          )}
        </IconButton>
      </div>
    </Tooltip>
  );
}

const getTooltipText = (
  disabled: boolean,
  successfullyCopied: boolean,
): string => {
  if (disabled) {
    return '';
  }

  return successfullyCopied ? 'Copied!' : 'Copy url to clipboard';
};
