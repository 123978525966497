import { NumberFilterIntervalType } from '../ui/NumberFilter/NumberFilter';
import type { Value as NumberFilterValue } from '../ui/NumberFilter/NumberFilter';
import { StringFilterSelectionType } from '../ui/StringFilterSelectionTypeSwitcher/StringFilterSelectionTypeSwitcher';
import { isMediaFilter, isNumberFilter, isStringFilter } from './predicates';
import type {
  FilterParams,
  FilterType,
  FilterValue,
  MediaFilterParams,
  NumberFilterParams,
  StringFilterParams,
} from './types';

export const isAllValuesSelected = (
  type: FilterType,
  value?: FilterValue,
  params?: FilterParams,
): boolean => {
  if (!params || !value) {
    return false;
  }

  if (isStringFilter(type)) {
    return (
      (value.type === StringFilterSelectionType.INCLUDE &&
        value.options.length ===
          (params as StringFilterParams).options.length) ||
      (value.type === StringFilterSelectionType.EXCLUDE &&
        value.options.length === 0)
    );
  } else if (isNumberFilter(type)) {
    const { max, min } = params as NumberFilterParams;

    return isNumberFilterAllValuesSelected(
      value as NumberFilterValue,
      min,
      max,
    );
  } else if (isMediaFilter(type)) {
    return (
      (value.type === StringFilterSelectionType.INCLUDE &&
        value.options.length ===
          (params as MediaFilterParams).options.length) ||
      (value.type === StringFilterSelectionType.EXCLUDE &&
        value.options.length === 0)
    );
  }

  return false;
};

export const isNumberFilterAllValuesSelected = (
  value?: NumberFilterValue,
  min?: number,
  max?: number,
): boolean => {
  return (
    value === undefined ||
    (value.type === NumberFilterIntervalType.CLOSED &&
      value.value[0] === min &&
      value.value[1] === max)
  );
};
