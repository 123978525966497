import { DataStatus } from '@/shared/lib';
import { createAppSlice } from '@/shared/model';
import * as WebLinksAPI from '../api/webLinksAPI';
import type { WebLink, WebLinkType } from './types';

interface State {
  webLinks: WebLink[];
  status: DataStatus;
}

const initialState: State = {
  webLinks: [],
  status: DataStatus.idle,
};

export const webLinksSlice = createAppSlice({
  name: 'webLinks',
  initialState,
  reducers: (create) => ({
    setupWebLinks: create.asyncThunk(
      (_: void) => {
        return WebLinksAPI.getWebLinks();
      },
      {
        pending: (state) => {
          state.status = DataStatus.loading;
        },
        fulfilled: (state, action) => {
          state.webLinks = action.payload;
          state.status = DataStatus.finished;
        },
        rejected: (state) => {
          state.status = DataStatus.error;
        },
      },
    ),
    addWebLink: create.asyncThunk<
      {
        url: string;
        type: WebLinkType;
        productId: string;
        productName: string;
      },
      WebLink
    >(
      (payload) => {
        return WebLinksAPI.addWebLink(payload);
      },
      {
        fulfilled: (state, action) => {
          state.webLinks.push(action.payload);
        },
      },
    ),
    deleteWebLink: create.asyncThunk<WebLink['id']>(
      (id) => {
        return WebLinksAPI.deleteWebLink(id);
      },
      {
        fulfilled: (state, action) => {
          state.webLinks = state.webLinks.filter(
            (webLink) => webLink.id !== action.meta.arg,
          );
        },
      },
    ),
  }),
  selectors: {
    selectWebLinks: (state) => state.webLinks,
    selectIsLoading: (state) => state.status === DataStatus.loading,
    selectIsFailed: (state) => state.status === DataStatus.error,
  },
});

export const { setupWebLinks, addWebLink, deleteWebLink } =
  webLinksSlice.actions;
export const { selectWebLinks, selectIsLoading, selectIsFailed } =
  webLinksSlice.selectors;
