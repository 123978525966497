import { Box, Divider, IconButton, Typography } from '@mui/material';
import { useMemo } from 'react';
import type { ColumnsConfig } from '@/entities/columnsConfig';
import { Analytics } from '@/shared/lib';
import type { MinMaxData } from '@/shared/ui';
import { TrashIcon } from '@/shared/ui';
import { getMetricConfigMap } from '../../libs/getMetricConfigMap';
import type { MetricColoring } from '../../model/types';
import { MetricColoringEditorNext } from '../MetricColoringEditorNext/MetricColoringEditorNext';
import { MinMaxFillingAlert } from '../MinMaxFillingAlert/MinMaxFillingAlert';

interface Props {
  columnsConfig: ColumnsConfig;
  minMax?: MinMaxData;
  metricsColoring: MetricColoring[];
  onMetricsColoringChange: (v: MetricColoring[]) => void;
}

export function ThresholdsList({
  columnsConfig,
  minMax,
  metricsColoring,
  onMetricsColoringChange,
}: Props) {
  const metricConfigMap = useMemo(() => {
    return getMetricConfigMap(columnsConfig, metricsColoring);
  }, [metricsColoring, columnsConfig]);

  const handleMetricColoringUpdate = (
    updatedMetricColoring: MetricColoring,
  ) => {
    onMetricsColoringChange(
      metricsColoring.map((metricColoring) => {
        if (metricColoring.metric === updatedMetricColoring.metric) {
          return updatedMetricColoring;
        }

        return metricColoring;
      }),
    );
  };
  const handleMetricColoringDelete = (metric: string) => () => {
    onMetricsColoringChange(
      metricsColoring.filter((metricColoring) => {
        return metricColoring.metric !== metric;
      }),
    );
    Analytics.sendFeatureUsage('metric_coloring', 'delete_coloring');
  };

  return (
    <Box
      sx={{ height: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}
    >
      <Box
        sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 2 }}
      >
        {metricsColoring.map((metricColoring) => {
          return (
            <Box
              key={metricColoring.metric}
              sx={{ border: '1px solid #DDDFE7', borderRadius: 2 }}
            >
              <Box
                sx={{
                  p: 2,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="subtitle2">
                  {metricConfigMap[metricColoring.metric].name}
                </Typography>
                <IconButton
                  sx={{ p: 0 }}
                  onClick={handleMetricColoringDelete(metricColoring.metric)}
                >
                  <TrashIcon />
                </IconButton>
              </Box>
              <Divider />
              <MetricColoringEditorNext
                key={metricColoring.metric}
                metricConfig={metricConfigMap[metricColoring.metric]}
                min={minMax?.[metricColoring.metric]?.min}
                max={minMax?.[metricColoring.metric]?.max}
                value={metricColoring}
                onChange={handleMetricColoringUpdate}
              />
            </Box>
          );
        })}
      </Box>
      <MinMaxFillingAlert />
    </Box>
  );
}
