import type { SelectChangeEvent } from '@mui/material';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MUISelect,
} from '@mui/material';
import { useState } from 'react';
interface Props<T extends string> {
  options: Record<T, string>;
  label: string;
  value: T;
  onChange: (v: T) => void;
}

export function Select<T extends string>({
  options,
  label,
  value,
  onChange,
}: Props<T>) {
  const [uniqueIdPrefix] = useState(`selectLabel${Math.random()}`);

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value as T);
  };

  return (
    <FormControl fullWidth>
      <InputLabel
        id={uniqueIdPrefix}
        sx={{ transform: 'translate(10px, -9px) scale(0.75)' }}
      >
        {label}
      </InputLabel>
      <MUISelect
        labelId={uniqueIdPrefix}
        label={`${label}1`} // PATCH: MUI bug
        sx={{
          borderRadius: 2,
        }}
        MenuProps={{
          MenuListProps: {
            disablePadding: true,
          },
          slotProps: {
            paper: {
              sx: {
                borderRadius: 2,
              },
            },
          },
        }}
        value={value}
        onChange={handleChange}
      >
        {Object.entries(options).map(([value, label]) => {
          return (
            <MenuItem key={value} value={value}>
              {label as string}
            </MenuItem>
          );
        })}
      </MUISelect>
    </FormControl>
  );
}
