import type { IconButtonProps as MUIIconButtonProps } from '@mui/material';
import { IconButton as MUIIconButton, SvgIcon } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import type {
  ColumnsConfig,
  DimensionColumnDataType,
} from '@/entities/columnsConfig';
import { Analytics } from '@/shared/lib';
import { generateCSVContent } from '../../model/generateCSVContent';
import type { DownloadData } from '../../model/types';
import { useDownloadData } from '../../model/useDownloadData';

type IconButtonProps = MUIIconButtonProps & { loading: boolean };
const IconButton = styled(
  forwardRef(
    (
      { loading, ...otherProps }: IconButtonProps,
      ref: ForwardedRef<HTMLButtonElement>,
    ) => {
      return <MUIIconButton ref={ref} {...otherProps} />;
    },
  ),
)(({ theme, loading }) => ({
  color: '#7E828F',
  border: '1px solid #BBBEC8',
  borderRadius: theme.shape.borderRadius * 2,
  padding: `${theme.spacing(0.875)} ${theme.spacing(1.375)}`,

  '&:hover': {
    color: '#666666',
    backgroundColor: '#6666660a',
    borderColor: '#666666',
  },

  ...(loading && {
    borderColor: 'transparent',
    position: 'relative',
    overflow: 'hidden',

    '&:hover': {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
    },

    '&::before': {
      content: '""',
      width: '150%',
      height: '150%',
      background:
        'conic-gradient(from 90deg, rgb(255 255 255 / 0%) 70%, rgb(126, 130, 143) 100%)',
      position: 'absolute',
      top: '-25%',
      left: '-25%',
      animation: 'rotate 2s infinite linear',
      borderRadius: 8,
    },

    '&::after': {
      content: '""',
      width: '94%',
      height: '94%',
      backgroundColor: 'white',
      position: 'absolute',
      top: '3%',
      left: '3%',
      borderRadius: 6,
    },

    '@keyframes rotate': {
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  }),
}));

interface Props {
  dimensions: string[];
  metrics: string[];
  config: ColumnsConfig;
  getAllDataRows: () => Promise<DownloadData>;
}

export function DownloadDataNextButton({
  dimensions,
  metrics,
  config,
  getAllDataRows,
}: Props) {
  const { loading, download } = useDownloadData({
    getAllDataRows,
    downloadFile: (downloadData) => {
      const csvContent = generateCSVContent({
        dimensions: dimensions.map((dimension) => {
          return {
            id: dimension,
            title: config[dimension].name,
            type: config[dimension].type as DimensionColumnDataType,
          };
        }),
        metrics: metrics.map((metric) => {
          return {
            id: metric,
            title: config[metric].name,
          };
        }),
        downloadData,
      });
      const blob = new Blob([csvContent], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');

      anchor.href = url;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(url);
      Analytics.sendFeatureUsage('download_view_data', 'download');
    },
  });

  const handleClick = () => {
    if (loading) {
      return;
    }

    download();
  };

  return (
    <IconButton
      title="Download data as CSV file"
      loading={loading}
      onClick={handleClick}
    >
      <SvgIcon sx={{ zIndex: 1 }}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15M7 10L12 15M12 15L17 10M12 15V3"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </SvgIcon>
    </IconButton>
  );
}
