import type { TypographyProps } from '@mui/material';
import { Box, Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMemo } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import type { ColumnsConfig } from '@/entities/columnsConfig';
import { ColumnItem } from '../ColumnItem/ColumnItem';
import { DraggableList } from '../DraggableList/DraggableList';

const Header = styled((props: TypographyProps) => {
  return <Typography {...props} />;
})(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingBottom: theme.spacing(1),
  fontWeight: 600,
  fontSize: '0.875rem',
  lineHeight: '1rem',
  letterSpacing: 0,
  color: '#7E828F',
  backgroundColor: 'white',
  position: 'sticky',
  top: 0,
  zIndex: 1,
}));

interface ColumnInfo {
  id: string;
  name: string;
  description: string;
}

interface Props {
  configLoaded: boolean;
  columnsConfig: ColumnsConfig;
  dimensions: string[];
  metrics: string[];
  updateColumns: (newColumns: {
    dimensions: string[];
    metrics: string[];
  }) => void;
}

export function ColumnsList({
  configLoaded,
  columnsConfig,
  dimensions,
  metrics,
  updateColumns,
}: Props) {
  const dimensionsColumns: ColumnInfo[] = useMemo(() => {
    return dimensions.map((dimension) => {
      return {
        id: dimension,
        name: columnsConfig[dimension]?.name || '',
        description: columnsConfig[dimension]?.description || '',
      };
    });
  }, [columnsConfig, dimensions]);
  const metricsColumns: ColumnInfo[] = useMemo(() => {
    return metrics.map((metric) => {
      return {
        id: metric,
        name: columnsConfig[metric]?.name || '',
        description: columnsConfig[metric]?.description || '',
      };
    });
  }, [columnsConfig, metrics]);

  const handleDimensionsChange = (newDimensions: ColumnInfo[]) => {
    updateColumns({
      dimensions: newDimensions.map((dimension) => {
        return dimension.id;
      }),
      metrics,
    });
  };
  const handleMetricsChange = (newMetrics: ColumnInfo[]) => {
    updateColumns({
      dimensions,
      metrics: newMetrics.map((metric) => {
        return metric.id;
      }),
    });
  };
  const handleDimensionDelete = (id: string) => {
    handleDimensionsChange(
      dimensionsColumns.filter((dimension) => dimension.id !== id),
    );
  };
  const handleMetricDelete = (id: string) => {
    handleMetricsChange(metricsColumns.filter((metric) => metric.id !== id));
  };

  if (!configLoaded) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Header>Dimensions</Header>
        <Skeleton variant="rectangular" height={50} />
        <Header>Metrics</Header>
        <Skeleton variant="rectangular" height={100} />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <DndProvider backend={HTML5Backend}>
        <Header>Dimensions</Header>
        <DraggableList
          sx={{ pl: 3, mb: 1 }}
          items={dimensionsColumns}
          renderItem={(item) => {
            return <ColumnItem {...item} onDelete={handleDimensionDelete} />;
          }}
          onChange={handleDimensionsChange}
        />
        <Header>Metrics</Header>
        <DraggableList
          sx={{ pl: 3, mb: 1 }}
          items={metricsColumns}
          renderItem={(item) => {
            return <ColumnItem {...item} onDelete={handleMetricDelete} />;
          }}
          onChange={handleMetricsChange}
        />
      </DndProvider>
    </Box>
  );
}
