import { Analytics } from '@/shared/lib';
import type { SortOptions, SortingItem } from '@/shared/types';
import { SortTypes } from '@/shared/types';
import {
  AdvancedSortButton,
  getColumnSorting,
  SortButton,
  HeaderCell,
} from '@/shared/ui';
import type { ReducedSortingItem } from '@/shared/ui';
import { useTableHeaderPropsContext } from '../TableHeaderPropsContext/TableHeaderPropsContext';

interface Props {
  field: string;
  name: string;
  description?: string;
}

export function DimensionTableHeader({ field, name, description }: Props) {
  const { advancedSortableOptions, sorting, updateSorting, onDimensionDelete } =
    useTableHeaderPropsContext();
  const sortingItem = getColumnSorting(sorting, field);

  const handleAdvancedSortingUpdate =
    (name: string) => (newSortingItem?: ReducedSortingItem) => {
      const sortingItem = newSortingItem
        ? { ...newSortingItem, name }
        : undefined;

      updateSorting(name, sortingItem);
      Analytics.sendFeatureUsage('data_table', 'advanced_sorting');
    };
  const handleSimpleSortingUpdate =
    (name: string) => (newSortOption?: SortOptions) => {
      const sortingItem: SortingItem | undefined = newSortOption
        ? {
            type: SortTypes.SIMPLE,
            name,
            value: newSortOption,
          }
        : undefined;

      updateSorting(name, sortingItem);
      Analytics.sendFeatureUsage('data_table', 'simple_sorting');
    };
  const handleDelete = () => {
    onDimensionDelete(field);
    Analytics.sendFeatureUsage('data_table', 'delete_column');
  };

  return (
    <HeaderCell
      title={name}
      description={description}
      sortButton={
        advancedSortableOptions.length > 0 ? (
          <AdvancedSortButton
            advancedFieldOptions={advancedSortableOptions}
            order={sortingItem?.index}
            advancedField={
              sortingItem?.type === SortTypes.ADVANCED
                ? sortingItem.field
                : undefined
            }
            value={sortingItem?.value}
            onChange={handleAdvancedSortingUpdate(field)}
          />
        ) : (
          <SortButton
            order={sortingItem?.index}
            value={sortingItem?.value}
            onChange={handleSimpleSortingUpdate(field)}
          />
        )
      }
      onDelete={handleDelete}
    />
  );
}
