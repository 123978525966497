import { styled } from '@mui/material/styles';
import type { DataType, FormatType } from '@/shared/hooks';
import type { ColorLevel } from '../../model/types';
import { ColorLevelEditorNext } from '../ColorLevelEditorNext/ColorLevelEditorNext';

const List = styled('ul')(({ theme }) => ({
  padding: 0,
  margin: 0,
  listStyle: 'none',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
}));

interface Props {
  type: DataType;
  formatType: FormatType;
  value: ColorLevel[];
  onChange: (v: ColorLevel[]) => void;
}

export function LevelsListNext({ type, formatType, value, onChange }: Props) {
  const handleLevelChange =
    (levelIndex: number) => (updatedLevel: ColorLevel) => {
      const newValue = [...value.slice(0, levelIndex)];

      if (levelIndex > 0 && updatedLevel.value < newValue.at(-1)?.value!) {
        newValue.push({
          ...updatedLevel,
          value: newValue.at(-1)?.value!,
        });
      } else {
        newValue.push(updatedLevel);
      }

      for (let i = levelIndex + 1; i < value.length; i++) {
        const prevLevelValue = newValue.at(-1)?.value!;

        if (value[i].value < prevLevelValue) {
          newValue.push({
            ...value[i],
            value: prevLevelValue,
          });
        } else {
          newValue.push(value[i]);
        }
      }

      onChange(newValue);
    };

  return (
    <List>
      {value.map((level, index) => {
        const isLastLevel = index === value.length - 1;

        return (
          <ColorLevelEditorNext
            key={`${level.value}-${level.color}-${index}`}
            type={type}
            formatType={formatType}
            previousLevelValue={
              index === 0 ? -Infinity : value.at(index - 1)?.value!
            }
            isLastLevel={isLastLevel}
            value={level}
            onChange={handleLevelChange(index)}
          />
        );
      })}
    </List>
  );
}
