import { Box } from '@mui/material';
import type {
  GridAlignment,
  GridColDef,
  GridColTypeDef,
  GridColumnGroupingModel,
  GridColumnHeaderParams,
} from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import type { FC } from 'react';
import type { MetricColoring } from '@/features/DataGridColoring';
import type {
  ColumnsConfig,
  MetricDataFormatType,
  MetricColumnDataType,
} from '@/entities/columnsConfig';
import {
  DimensionColumnDataType,
  toFormatType,
} from '@/entities/columnsConfig';
import { selectCurrency } from '@/entities/session';
import { DEFAULT_COLUMN_WIDTH } from '@/shared/constants';
import { parseMediaColumnValue } from '@/shared/data';
import { useNumberFormatter } from '@/shared/hooks';
import { formatDate } from '@/shared/lib';
import { useAppSelector } from '@/shared/model';
import type { MinMaxData } from '@/shared/ui';
import {
  DIMENSION_MIN_WIDTH,
  METRIC_MIN_WIDTH,
  getMetricTypeProps,
} from '@/shared/ui';
import { ImageMediaCell } from '../ImageMediaCell/ImageMediaCell';
interface DataTableProps {
  columns: GridColDef[];
  columnGroupingModel: GridColumnGroupingModel;
}

interface Params {
  columnsConfig: ColumnsConfig;
  columnsWidth: Record<string, number | undefined>;
  dimensions: string[];
  metrics: string[];
  minMax?: MinMaxData;
  metricsColoring: MetricColoring[];
  DimensionHeaderComponent: FC<{
    field: string;
    name: string;
    description?: string;
  }>;
  MetricHeaderComponent: FC<{
    field: string;
    name: string;
    description?: string;
  }>;
}

export const useDataTableProps = ({
  columnsConfig,
  columnsWidth,
  dimensions,
  metrics,
  minMax,
  metricsColoring,
  DimensionHeaderComponent,
  MetricHeaderComponent,
}: Params): DataTableProps => {
  const userCurrency = useAppSelector(selectCurrency);
  const formattersMap = useNumberFormatter(userCurrency);
  const metricsColoringMap = useMemo(() => {
    return Object.fromEntries(
      metricsColoring.map((metricColoring) => {
        return [metricColoring.metric, metricColoring];
      }),
    );
  }, [metricsColoring]);
  const columns: GridColDef[] = useMemo(
    () => {
      return [
        ...dimensions.map((dimension, ind) => {
          const { name, description, type } = columnsConfig[dimension];

          return {
            ...getDimensionTypeProps(type as DimensionColumnDataType, ind),
            headerName: name,
            description: description,
            field: dimension,
            minWidth: DIMENSION_MIN_WIDTH,
            width: columnsWidth[dimension] || DEFAULT_COLUMN_WIDTH,
            headerClassName: 'dimensionHeader',
            renderHeader: ({ colDef }: GridColumnHeaderParams) => {
              if (!colDef) {
                return null;
              }

              return (
                <DimensionHeaderComponent
                  field={colDef.field}
                  name={colDef.headerName ?? colDef.field}
                  description={colDef.description}
                />
              );
            },
          };
        }),
        ...metrics.map((metric) => {
          const { name, description, type, dataType } = columnsConfig[metric];

          return {
            ...getMetricTypeProps(
              formattersMap,
              type as MetricColumnDataType,
              toFormatType(dataType as MetricDataFormatType),
              minMax,
              metricsColoringMap[metric]?.levels,
            ),
            headerName: name,
            description: description,
            field: metric,
            minWidth: METRIC_MIN_WIDTH,
            width: columnsWidth[metric] || DEFAULT_COLUMN_WIDTH,
            align: 'right' as GridAlignment,
            headerClassName: 'metricHeader',
            cellClassName: 'metricCell',
            renderHeader: ({ colDef }: GridColumnHeaderParams) => {
              if (!colDef) {
                return null;
              }

              return (
                <MetricHeaderComponent
                  field={colDef.field}
                  name={colDef.headerName ?? colDef.field}
                  description={colDef.description}
                />
              );
            },
          };
        }),
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dimensions,
      metrics,
      columnsConfig,
      minMax,
      metricsColoringMap,
      columnsWidth,
      formattersMap,
      DimensionHeaderComponent,
      MetricHeaderComponent,
    ],
  );
  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: 'Dimensions',
      children: dimensions.map((dimension) => {
        return {
          field: dimension,
        };
      }),
      renderHeaderGroup: () => null,
      headerClassName: 'columnGroup',
    },
    {
      groupId: 'Metrics',
      children: metrics.map((metric) => {
        return {
          field: metric,
        };
      }),
      renderHeaderGroup: () => null,
      headerClassName: 'columnGroup',
    },
  ];

  return {
    columnGroupingModel,
    columns,
  };
};

const DIMENSION_CELL_RENDERERS: Record<
  DimensionColumnDataType,
  NonNullable<GridColTypeDef['renderCell']>
> = {
  [DimensionColumnDataType.DATE]: (params) => {
    const { value } = params;

    return formatDate(value);
  },
  [DimensionColumnDataType.TEXT]: (params) => {
    return (
      <Box
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {params.value}
      </Box>
    );
  },
  [DimensionColumnDataType.URL]: (params) => {
    const { value } = params;

    try {
      const url = new URL(value);

      return (
        <a href={url.toString()} target="_blank" rel="noreferrer">
          {value}
        </a>
      );
    } catch {
      return value;
    }
  },
  [DimensionColumnDataType.MEDIA]: (params) => {
    const { value, row } = params;

    if (!value) {
      return;
    }

    if (row.isTotal) {
      return value;
    }

    const { type, value: mediaName, url } = parseMediaColumnValue(value);

    if (type === 'image') {
      return <ImageMediaCell label={mediaName} rowId={row.id} url={url} />;
    }

    return mediaName;
  },
};

const getDimensionTypeProps = (
  type: DimensionColumnDataType,
  order: number,
): GridColTypeDef => {
  return {
    type: 'string',
    cellClassName: 'dimensionCell',
    renderCell: (params) => {
      if (params.row.isTotal && order === 0) {
        return 'Total';
      }

      if (type === DimensionColumnDataType.MEDIA) {
        return DIMENSION_CELL_RENDERERS[type](params);
      }

      return (
        <Box
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {DIMENSION_CELL_RENDERERS[type](params)}
        </Box>
      );
    },
  };
};
