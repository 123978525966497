import { Box, Popover } from '@mui/material';
import { useMemo, useRef, useState } from 'react';
import { useFiltersParamsContext } from '@/features/filters';
import { useFiltersContext } from '@/features/filters';
import { Analytics } from '@/shared/lib';
import type { SortingItem } from '@/shared/types';
import { SortOptions, SortTypes } from '@/shared/types';
import { getColumnSorting, HeaderCellNext, SortButton } from '@/shared/ui';
import { FilterIconButton } from '../FilterIconButton/FilterIconButton';
import { useTableHeaderPropsContext } from '../TableHeaderPropsContext/TableHeaderPropsContext';
import { AddFilter } from './AddFilter';
import { AddTreshold } from './AddTreshold';

enum ActionType {
  addFilter = 'addFilter',
  clearFilter = 'clearFilter',
  addThreshold = 'addThreshold',
  clearThreshold = 'clearThreshold',
  sort = 'sort',
  sortAscending = 'sortAscending',
  sortDescending = 'sortDescending',
  delete = 'delete',
}

interface Props {
  field: string;
  name: string;
  description?: string;
}

export function MetricTableHeaderNext({ field, name, description }: Props) {
  const menuRef = useRef<HTMLButtonElement>(null);
  const [activeAction, setActiveAction] = useState<ActionType | null>(null);
  const {
    metricsColoring,
    filters,
    sorting,
    updateSorting,
    updateFilters,
    updateMetricsColoring,
    onMetricDelete,
  } = useTableHeaderPropsContext();
  const { metricConfigs } = useFiltersParamsContext();
  const { filtersMap } = useFiltersContext();
  const sortingItem = getColumnSorting(sorting, field);
  const menuOptions = useMemo(() => {
    const isFilterAllowed = metricConfigs.find((config) => config.id === field);
    const filter = filtersMap[field];
    const metricColoring = metricsColoring.find(
      (coloring) => coloring.metric === field,
    );

    return [
      ...(isFilterAllowed
        ? [
            {
              label: filter ? 'Edit Filter' : 'Add Filter',
              value: ActionType.addFilter,
            },
          ]
        : []),
      ...(filter
        ? [
            {
              label: 'Clear Filter',
              value: ActionType.clearFilter,
            },
          ]
        : []),
      {
        label: metricColoring ? 'Edit Threshold' : 'Add Threshold',
        value: ActionType.addThreshold,
      },
      ...(metricColoring
        ? [
            {
              label: 'Clear Threshold',
              value: ActionType.clearThreshold,
            },
          ]
        : []),
      {
        label: 'Sort',
        value: ActionType.sort,
        selected: sortingItem?.type === SortTypes.SIMPLE,
        divider: true,
        nestedOptions: [
          {
            label: 'Ascending',
            value: ActionType.sortAscending,
            selected:
              sortingItem?.type === SortTypes.SIMPLE &&
              sortingItem?.value === SortOptions.ASC,
          },
          {
            label: 'Descending',
            value: ActionType.sortDescending,
            selected:
              sortingItem?.type === SortTypes.SIMPLE &&
              sortingItem?.value === SortOptions.DESC,
          },
        ],
      },
      {
        label: 'Delete',
        value: ActionType.delete,
      },
    ];
  }, [sortingItem, filtersMap, metricConfigs, field, metricsColoring]);

  const handleSimpleSortingUpdate =
    (name: string) => (newSortOption?: SortOptions) => {
      const sortingItem: SortingItem | undefined = newSortOption
        ? {
            type: SortTypes.SIMPLE,
            name,
            value: newSortOption,
          }
        : undefined;

      updateSorting(name, sortingItem);
      Analytics.sendFeatureUsage('data_table', 'simple_sorting');
    };
  const handleActionClose = () => {
    setActiveAction(null);
  };
  const handleAddFilter = () => {
    setActiveAction(ActionType.addFilter);
  };
  const handleClearFilter = () => {
    updateFilters(filters.filter((filter) => filter.id !== field));
  };
  const handleClearThreshold = () => {
    updateMetricsColoring(
      metricsColoring.filter((coloring) => coloring.metric !== field),
    );
  };
  const handleMenuOptionClick = (optionValue: string) => {
    if (optionValue === ActionType.addFilter) {
      handleAddFilter();
    } else if (optionValue === ActionType.clearFilter) {
      handleClearFilter();
    } else if (optionValue === ActionType.addThreshold) {
      setActiveAction(ActionType.addThreshold);
    } else if (optionValue === ActionType.clearThreshold) {
      handleClearThreshold();
    } else if (optionValue === ActionType.sortAscending) {
      handleSimpleSortingUpdate(field)(SortOptions.ASC);
    } else if (optionValue === ActionType.sortDescending) {
      handleSimpleSortingUpdate(field)(SortOptions.DESC);
    } else if (optionValue === ActionType.delete) {
      onMetricDelete(field);
    }
  };

  return (
    <>
      <HeaderCellNext
        field={field}
        title={name}
        ref={menuRef}
        description={description}
        quickActions={(hovered) => {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              {(hovered || !!sortingItem) && (
                <SortButton
                  order={sortingItem?.index}
                  value={sortingItem?.value}
                  onChange={handleSimpleSortingUpdate(field)}
                />
              )}
              {filtersMap[field] && (
                <FilterIconButton onClick={handleAddFilter} />
              )}
            </Box>
          );
        }}
        menuOptions={menuOptions}
        onMenuOptionClick={handleMenuOptionClick}
      />
      <Popover
        open={activeAction !== null}
        anchorEl={menuRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: 2,
            },
          },
        }}
        onClose={handleActionClose}
      >
        {activeAction === ActionType.addFilter && (
          <AddFilter id={field} onClose={handleActionClose} />
        )}
        {activeAction === ActionType.addThreshold && <AddTreshold id={field} />}
      </Popover>
    </>
  );
}
