import CheckedIcon from '@mui/icons-material/CheckBoxRounded';
import type { CheckboxProps } from '@mui/material';
import { Checkbox as MUICheckbox } from '@mui/material';
import { styled } from '@mui/material/styles';

const UncheckedIcon = styled('div')(({ theme }) => ({
  borderRadius: 2,
  border: `1px solid currentColor`,
  width: 15,
  height: 15,
  margin: theme.spacing(0.25),
  marginRight: 3,
}));

export const Checkbox = styled((props: CheckboxProps) => {
  return (
    <MUICheckbox
      size="small"
      icon={<UncheckedIcon />}
      checkedIcon={<CheckedIcon color="primary" />}
      {...props}
    />
  );
})(({ theme }) => ({
  padding: 0,
  marginLeft: 0,

  '&:hover': {
    color: theme.palette.primary.light,
  },
}));
