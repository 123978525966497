import InvalidContentIcon from '@mui/icons-material/BrokenImage';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/DoneRounded';
import ErrorIcon from '@mui/icons-material/ErrorRounded';
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material';
import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from '@/shared/ui';
import { useGetSharedViewQuery } from '../../api/shareAPI';

interface Props {
  isViewValid: (view: unknown) => boolean;
  applyView: (view: unknown) => void;
  onViewError: () => void;
}

export function ImportSharedView({
  isViewValid,
  applyView,
  onViewError,
}: Props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, isError, isSuccess } = useGetSharedViewQuery(id!);

  useEffect(() => {
    if (!isSuccess || !data) {
      return;
    }

    if (isViewValid(data.snapshot)) {
      applyView(data.snapshot);
    } else {
      onViewError();
    }
  }, [data, isSuccess, isViewValid, applyView, onViewError]);

  const handleCloseDialog = () => {
    navigate('..');
  };

  let content: ReactNode;

  if (isError) {
    content = (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ErrorIcon sx={{ width: 48, height: 48 }} color="error" />
        <Typography color="error">
          Unable to get shared view. Please try again later
        </Typography>
      </Box>
    );
  } else if (isSuccess) {
    if (!data || !isViewValid(data.snapshot)) {
      content = (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <InvalidContentIcon sx={{ width: 48, height: 48 }} color="error" />
          <Typography color="error" align="center">
            The view has invalid content. Please ask the person who shared it to
            create a new one
          </Typography>
        </Box>
      );
    } else {
      content = (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <DoneIcon sx={{ width: 48, height: 48 }} color="success" />
          <Typography align="center">The view successfully imported</Typography>
        </Box>
      );
    }
  }

  return (
    <Dialog open onClose={handleCloseDialog}>
      <IconButton
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          zIndex: 1,
        }}
        aria-label="close"
        onClick={handleCloseDialog}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ position: 'relative', minHeight: 100, width: 450 }}>
        {content}
        <Loader active={isLoading} />
      </DialogContent>
    </Dialog>
  );
}
