import { Box } from '@mui/material';
import { ProbabilisticAttributionSwitcher } from '@/features/ProbabilisticAttribution';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import type { DateScale, SerializedDateRangeValueObject } from '@/shared/types';
import { DateScaleSelectNext, DateRangeSelectNext } from '@/shared/ui';
import {
  selectCohortDateRange,
  selectCohortDateScale,
  selectProbabilisticAttribution,
  selectRevenueDateRange,
  selectRevenueDateScale,
  updateCohortDateRange,
  updateCohortDateScale,
  updateProbabilisticAttribution,
  updateRevenueDateRange,
  updateRevenueDateScale,
} from '../../model/slice';

const DATE_FORMAT = 'dd.MM.yy';

export function QuickAccessFiltersAndSettings() {
  const dispatch = useAppDispatch();
  const cohortDateScale = useAppSelector(selectCohortDateScale);
  const cohortDateRange = useAppSelector(selectCohortDateRange);
  const revenueDateScale = useAppSelector(selectRevenueDateScale);
  const revenueDateRange = useAppSelector(selectRevenueDateRange);
  const probabilisticAttribution = useAppSelector(
    selectProbabilisticAttribution,
  );

  const handleCohortDateScaleChange = (newScale: DateScale) => {
    dispatch(updateCohortDateScale(newScale));
  };
  const handleCohortDateRangeChange = (
    newDateRange: SerializedDateRangeValueObject,
  ) => {
    dispatch(updateCohortDateRange(newDateRange));
  };
  const handleRevenueDateScaleChange = (newScale: DateScale) => {
    dispatch(updateRevenueDateScale(newScale));
  };
  const handleRevenueDateRangeChange = (
    newDateRange: SerializedDateRangeValueObject,
  ) => {
    dispatch(updateRevenueDateRange(newDateRange));
  };
  const handleProbabilisticAttributionChange = (checked: boolean) => {
    dispatch(updateProbabilisticAttribution(checked));
  };
  return (
    <Box
      sx={{ px: 2, pb: 2, display: 'flex', flexDirection: 'column', gap: 2 }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box sx={{ width: 106 }}>
            <DateScaleSelectNext
              label="Cohort Scale"
              value={cohortDateScale}
              onChange={handleCohortDateScaleChange}
            />
          </Box>
          <DateRangeSelectNext
            label="Cohort Period"
            dateFormat={DATE_FORMAT}
            value={cohortDateRange}
            onChange={handleCohortDateRangeChange}
          />
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box sx={{ width: 106 }}>
            <DateScaleSelectNext
              label="Calendar Scale"
              value={revenueDateScale}
              onChange={handleRevenueDateScaleChange}
            />
          </Box>
          <DateRangeSelectNext
            label="Calendar Period"
            dateFormat={DATE_FORMAT}
            value={revenueDateRange}
            onChange={handleRevenueDateRangeChange}
          />
        </Box>
      </Box>
      <ProbabilisticAttributionSwitcher
        value={probabilisticAttribution}
        onChange={handleProbabilisticAttributionChange}
      />
    </Box>
  );
}
