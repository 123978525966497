import type { MinMaxData } from '@/shared/ui';
import type { MetricColoring } from '../model/types';
import { PRESET_COLORS } from '../ui/ColorPicker/presetColors';

export const getDefaultMetricColoring = (
  metric: string,
  minMax?: MinMaxData,
): MetricColoring => {
  const metricMinMax = minMax?.[metric] || { min: 0, max: 1 };
  const min = metricMinMax.min ?? 0;
  const max = metricMinMax.max ?? 1;

  return {
    metric: metric,
    levels: [
      { value: max - (max - min) / 2, color: PRESET_COLORS[1] },
      { value: max, color: PRESET_COLORS.at(-1)! },
    ],
  };
};
