import { z } from 'zod';
import { Analytics } from '@/shared/lib';

const legacyStringOrMediaFilterValue = z.array(z.string());
const legacyNumberFilterValue = z.tuple([z.number(), z.number()]);

export const legacyFiltersValueAdapter = (
  filters: { id: string; value: unknown }[],
): { id: string; value: unknown }[] => {
  return filters.map((filter) => {
    if (legacyStringOrMediaFilterValue.safeParse(filter.value).success) {
      Analytics.sendFeatureUsage(
        'legacy_filters_value_adapter',
        'legacy_string_or_media_filter',
      );

      return {
        id: filter.id,
        value: {
          type: 'include',
          options: filter.value,
        },
      };
    }

    if (legacyNumberFilterValue.safeParse(filter.value).success) {
      Analytics.sendFeatureUsage(
        'legacy_filters_value_adapter',
        'legacy_number_filter',
      );

      return {
        id: filter.id,
        value: {
          type: 'closed',
          value: filter.value,
        },
      };
    }

    return filter;
  });
};
