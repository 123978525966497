import type { GridColTypeDef } from '@mui/x-data-grid-pro';
import type { FormatType, useNumberFormatter } from '@/shared/hooks';
import type { ColorLevel } from '../ui/ColorCell/ColorCell';
import { ColorCell } from '../ui/ColorCell/ColorCell';
import { ProgressCell } from '../ui/ProgressCell/ProgressCell';
import type { MinMaxData } from './types';

enum MetricColumnDataType {
  NUMBER = 'number',
  PERCENTAGE = 'percentage',
  CURRENCY = 'currency',
}

export const getMetricTypeProps = (
  formattersMap: ReturnType<typeof useNumberFormatter>,
  type: MetricColumnDataType,
  formatType: FormatType,
  metricMinMax?: MinMaxData,
  colorLevels?: ColorLevel[],
): GridColTypeDef => {
  switch (type) {
    case MetricColumnDataType.PERCENTAGE:
    case MetricColumnDataType.CURRENCY:
    case MetricColumnDataType.NUMBER:
      return {
        type: 'number',
        renderCell: (params) => {
          const { field, value, row } = params;
          const { min, max } = metricMinMax?.[field] || {};

          if (value == null) {
            return;
          }

          const preparedValue = formattersMap[type][formatType](value);

          if (row.isTotal) {
            return preparedValue;
          }

          if (colorLevels) {
            return (
              <ColorCell value={value} colorLevels={colorLevels}>
                {preparedValue}
              </ColorCell>
            );
          }

          return (
            <ProgressCell min={min} max={max} value={value}>
              {preparedValue}
            </ProgressCell>
          );
        },
      };
    default:
      return {
        type: 'string',
      };
  }
};
